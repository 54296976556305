'use client';

import * as React from 'react';

import type { AuthSession } from '@creator-portal/common/types';
import type { ReactNode } from 'react';

export interface AuthSessionProviderProps {
  children: ReactNode;
  value?: AuthSession;
}

export const AuthSessionContext = React.createContext<AuthSession | undefined>?.(undefined);

export function AuthSessionProvider({ value, children }: AuthSessionProviderProps): JSX.Element {
  if (!AuthSessionContext) {
    throw new Error('React Context is unavailable in React Server Components');
  }

  return <AuthSessionContext.Provider value={value}>{children}</AuthSessionContext.Provider>;
}
