import { Creator } from '@epic-ecp/types';

export * from './payout.types';

export type Status = 'INIT' | 'PENDING' | 'REJECTED' | 'ACTIVE' | 'DISABLED' | 'DELETED';
export type AlertEndpoint = 'alerts' | 'announcements';

export interface AlertCta {
  message: string;
  link: string;
  isExternal: boolean;
}

export interface AlertPopupProps {
  title: string;
}
export interface CreatorAlert {
  type: string;
  key?: number;
  severity: string;
  messages: string[];
  closeable: boolean;
  cta?: AlertCta;
  popup: boolean;
  popupProps?: AlertPopupProps;
}
export interface AlertResponse {
  data: CreatorAlert[];
  success: boolean;
}
export interface CreatorAlerts {
  alerts?: CreatorAlert[];
  announcements?: CreatorAlert[];
}

export enum CMSPageKey {
  /** MEDIA CREATOR */
  PROGRAMS = 'landing',
  PROGRAMS_MEDIA = 'landing/media',
  PROGRAMS_ISLAND = 'landing/island',
  SETTINGS = 'settings',
  SWITCHES = 'switches',
  NEWS = 'news',
}

export type CmsPage = Record<string, any>;

export enum AgreementType {
  PUBLISHING = 'PUBLISHING',
  COMMUNITY = 'COMMUNITY',
  PAYMENT_CONSENT = 'PAYMENT_CONSENT',
  PUBLISHING_PARTNER_IP = 'PUBLISHING_PARTNER_IP',
  IP_PARTNER = 'IP_PARTNER',
}

export interface Agreement {
  title: string;
  body: string;
  key: string;
  locale: string;
  url?: string;
  revision: number;
  version: number;
  agreementType: AgreementType;
  accepted: boolean;
  lastModifiedTimestamp?: string;
}

export enum SettingKeys {
  /**
   * Global Creator Ecosystem
   */
  EMAIL_CREATORECO = 'email:creatoreco',
  EMAIL_CREATORECO_CREATIONTOOLS = 'email:creatoreco:creationtools',
  EMAIL_CREATORECO_CREATIONECO = 'email:creatoreco:creationeco',
}

export interface EmailSettingsUpdateDTO {
  settingKey: SettingKeys;
  settingValue: boolean;
}

export interface EmailSettingsResponse {
  success: boolean;
  data: {
    email?: boolean;
    [SettingKeys.EMAIL_CREATORECO_CREATIONECO]?: boolean;
    [SettingKeys.EMAIL_CREATORECO_CREATIONTOOLS]?: boolean;
  };
}

export interface CreatorCodeAvailabilityResponse {
  slug: string;
  available: boolean;
  promptCRCC: boolean;
}

export interface CreateCreatorCodeRequest {
  agreementAccepted: boolean;
  guidelinesAccepted: boolean;
  ageConfirmed: boolean;
  eulas: {
    key: string;
    version: number;
    revision: number;
    locale: string;
  }[];
  locale: string;
  solveToken: string;
  creatorCode: string;
  optionalAgreementsToAccept?: { IP_PARTNER: number };
}

export interface AcceptPaymentConsent {
  eula: { key: string; version: number; revision: number; locale: string };
  profileType: 'individual' | 'singlememberllc' | 'soleproprietor' | 'otherentity';
}
export interface AcceptPaymentConsentResponse {
  data: { programType: string; consent: boolean };
  success: boolean;
}

// TODO: create or update types for Creator model instead of PartialCreator
export type PartialCreator = Partial<
  Pick<Creator, 'id' | 'slug' | 'status' | 'lastModifiedAt' | 'rvn' | 'createdAt' | 'statusEffectiveDate' | 'statusReason'>
>;

export interface SignupRequirementsMet {
  emailVerified: boolean;
  tfaEnabled: boolean;
}

export interface RetakeAvailableResponse {
  syncAvailable: boolean;
  retakeAvailable: boolean;
}

export interface IslandAgreementsRequest {
  agreementType: string;
  releaseOrder: number;
  decision: 'ACCEPT' | 'DECLINE';
}
