import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) =>
  createStyles({
    footerContent: {
      textAlign: 'center',
      fontSize: `${theme.typography.pxToRem(12)} !important`,
      fontWeight: 500,
      color: theme.palette.shade2,
      marginTop: `${theme.spacing(1)} !important`,
      width: '100% !important',
    },
    description: {
      textAlign: 'center',
      fontSize: `${theme.typography.pxToRem(16)} !important`,
      color: theme.palette.shade0,
      padding: theme.spacing(0, 1.5),
      lineHeight: theme.typography.pxToRem(20),
    },
    textFieldBlock: {
      padding: theme.spacing(6, 5, 1),
      [theme.breakpoints.down(520)]: {
        paddingLeft: theme.spacing(3.5),
        paddingRight: theme.spacing(3.5),
      },
      [theme.breakpoints.down(430)]: {
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(2.5),
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
  }),
);

export default useStyles;
