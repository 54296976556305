const SEARCH_PARAMS_DELIMITER = '?';

export const makeNavPath = (
  path: string,
  originalUrl: string = '/',
  options: {
    keepExistingSearchParams?: boolean | string[];
    searchParams?: Record<string, string>;
  } = {},
): string => {
  const [basePath, pathSearchParams] = path.split(SEARCH_PARAMS_DELIMITER);
  const [_, originalUrlSearchParams] = originalUrl.split(SEARCH_PARAMS_DELIMITER);

  const newSearchParams = new URLSearchParams(pathSearchParams);
  const existingSearchParams = new URLSearchParams(originalUrlSearchParams);

  const { keepExistingSearchParams = true, searchParams } = options;

  if (keepExistingSearchParams) {
    existingSearchParams.forEach((value, key) => {
      if (keepExistingSearchParams === true || keepExistingSearchParams.includes(key)) {
        newSearchParams.set(key, value);
      }
    });
  }

  if (searchParams) {
    Object.entries(searchParams).forEach(([key, value]) => {
      newSearchParams.set(key, value);
    });
  }

  const stringifiedNewSearchParams = newSearchParams.toString();

  return stringifiedNewSearchParams ? `${basePath}?${stringifiedNewSearchParams}` : basePath;
};
