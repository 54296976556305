const lightButtonStyle = {
  background: '#FFFFFF',
  '&:hover': {
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.07)), #FFFFFF',
  },
  '&:active': {
    background: '#FFFFFF',
  },
};

export const alertOverrides = {
  MuiAlert: {
    styleOverrides: {
      root: {
        borderRadius: 8,
        boxShadow: 'none',
        alignItems: 'center',
        '& .MuiPaper-root': {
          '& .MuiAlert-message': {
            fontWeight: 600,
            fontSize: 13,
          },
        },
        '@media screen and (max-width: 416px)': {
          flexWrap: 'wrap',
        },
      },
      filledSuccess: {
        background: 'linear-gradient(91.54deg, #0B873A -43.98%, #11CF59 168.13%)',
        color: '#FFFFFF',
        '& .MuiAlert-icon .MuiSvgIcon-root': {
          color: '#11CF59',
        },
        '& .MuiAlert-action .MuiSvgIcon-root': {
          color: '#FFFFFF',
        },
        '& .MuiAlert-action .MuiButton-root': {
          ...lightButtonStyle,
          color: '#0B873A',
        },
      },
      filledError: {
        background: 'linear-gradient(91.53deg, #DE3341 -5.24%, #EF646F 103.3%)',
        color: '#FFFFFF',
        '& .MuiAlert-icon .MuiSvgIcon-root': {
          color: '#FFFFFF',
        },
        '& .MuiAlert-action .MuiSvgIcon-root': {
          color: '#FFFFFF',
        },
        '& .MuiAlert-action .MuiButton-root': {
          ...lightButtonStyle,
          color: '#DE3341',
        },
      },
      filledWarning: {
        background: 'linear-gradient(91.52deg, #FFA640 -5.24%, #FAC181 117.06%)',
        color: '#000000',
        '& .MuiAlert-icon .MuiSvgIcon-root': {
          color: '#FFFFFF',
        },
        '& .MuiAlert-action .MuiButton-root': {
          ...lightButtonStyle,
          color: '#FFA640',
        },
      },
      filledInfo: {
        border: '1px solid rgba(20, 20, 20, 0.16)',
        color: 'rgba(20, 20, 20, 0.72)',
        background: '#EDF1F4',
        opacity: 0.9,
        '& .MuiAlert-icon .MuiSvgIcon-root': {
          color: '#257ED3',
        },
      },
      message: {
        flex: 1,
        '& > p': {
          fontWeight: 600,
          fontSize: 13,
          lineHeight: '20px',
        },
        '@media screen and (max-width: 416px)': {
          minWidth: 200,
        },
      },
      action: {
        '& .MuiSvgIcon-root': {
          fontSize: 19,
        },
      },
    },
  },
};
