export const DEFAULT_LOCALE = 'en-US';

export const localeList = [
  { displayName: 'English', code: 'en-US' },
  { displayName: 'ESPAÑOL (SA)', code: 'es-MX' },
  { displayName: 'العربية', code: 'ar' },
  { displayName: 'DEUTSCH', code: 'de' },
  { displayName: 'ESPAÑOL', code: 'es-ES' },
  { displayName: 'FRANÇAIS', code: 'fr' },
  { displayName: 'ITALIANO', code: 'it' },
  { displayName: '日本語', code: 'ja' },
  { displayName: '한국어', code: 'ko' },
  { displayName: 'POLSKI', code: 'pl' },
  { displayName: 'PORTUGUÊS', code: 'pt-BR' },
  { displayName: 'РУССКИЙ', code: 'ru' },
  { displayName: 'TÜRKÇE', code: 'tr' },
];

const supportedLocales = Object.values(localeList).map((l) => l.code);

export const toEpicLocale = (code: string): string => {
  const supportedLocaleIndex = supportedLocales.findIndex((l) => l.toLowerCase() === code.toLowerCase());
  if (supportedLocaleIndex !== -1) return supportedLocales[supportedLocaleIndex]; // make sure we use the code in the format we and other Epic services expect (some are case-sensitive)

  switch (code.toLowerCase()) {
    case 'en':
      return 'en-US';
    case 'ar-dz':
    case 'ar-bh':
    case 'ar-eg':
    case 'ar-iq':
    case 'ar-jo':
    case 'ar-kw':
    case 'ar-lb':
    case 'ar-ly':
    case 'ar-ma':
    case 'ar-om':
    case 'ar-qa':
    case 'ar-sa':
    case 'ar-sy':
    case 'ar-tn':
    case 'ar-ae':
    case 'ar-ye':
      return 'ar';
    case 'de-at':
    case 'de-li':
    case 'de-lu':
    case 'de-ch':
      return 'de';
    case 'es':
      return 'es-ES';
    case 'fr-fr':
      return 'fr';
    case 'it-it':
      return 'it';
    case 'ja-jp':
      return 'ja';
    case 'ko-kr':
      return 'ko';
    case 'pl-pl':
      return 'pl';
    case 'pt':
      return 'pt-BR';
    case 'ru-ru':
      return 'ru';
    case 'tr-cy':
    case 'tr-tr':
      return 'tr';
    default:
      return DEFAULT_LOCALE;
  }
};
export const toDocLocale = (lang?: string | string[]): string => {
  const docLang = Array.isArray(lang) ? lang[0] : lang;

  switch (docLang) {
    // 'ar' Arabic is not available on the documentation portal
    case 'en':
      return 'en-us';
    case 'de':
      return 'de-de';
    case 'es-ES':
      return 'es-es';
    case 'es-MX':
      return 'es-mx';
    case 'fr':
      return 'fr-fr';
    case 'it':
      return 'it-it';
    case 'ja':
      return 'ja-jp';
    case 'ko':
      return 'ko-kr';
    case 'pl':
      return 'pl-pl';
    case 'pt-BR':
      return 'pt-br';
    case 'ru':
      return 'ru-ru';
    case 'tr':
      return 'tr-tr';
    default:
      return 'en-us';
  }
};
