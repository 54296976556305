import { useCallback, useRef, useState } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { clsx } from 'clsx';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';

import { AuthAction, canAccess } from '@creator-portal/common/auth';

import useStyles from '@/components/common/navigation/nav-link.styles';

import { useAuthSession } from '@/hooks/useAuthSession';

import type { Dispatch, SetStateAction } from 'react';

export interface NavLink {
  access?: string;
  icon: JSX.Element;
  key: string;
  route: string;
  label: string;
  tooltip?: string;
  isExternal?: boolean;
  params?: Record<string, string | undefined>;
  messageIfDisabled?: string;
  hidden?: boolean;
}

const isJoinTeamManagePage = (path: string, route: string): boolean => path.includes('Join_Team') && route.endsWith('/teams');
const isNestedRouter = (path: string, route: string): boolean => path.includes(route) && path.includes(`${route}/`);
const isPlaytesterTab = (path: string, route: string): boolean => path.endsWith('/playtesters') && route.endsWith('/teams');
const isReportCopiedContentPage = (path: string, route: string): boolean =>
  path.endsWith('/report-copied-content') && route.endsWith('/support');

export const NavLinkItem = ({
  link,
  onClick,
  setNewExternalRoute,
}: {
  link: NavLink;
  setNewExternalRoute: Dispatch<SetStateAction<string | undefined>>;
  onClick?: () => void;
}): JSX.Element => {
  const classes = useStyles();
  const user = useAuthSession();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [tooltip, setTooltip] = useState<undefined | string>(undefined);
  const textElement = useRef();

  const setVisibleTooltip = useCallback(() => {
    if (!textElement.current) return;
    const el = (textElement.current as HTMLElement).querySelector('span') as HTMLElement;
    setTooltip(el.offsetWidth < el.scrollWidth ? label : undefined);
  }, [textElement.current]);

  const { access, icon, isExternal, label, key, route, messageIfDisabled, params, hidden } = link;
  const isReportCopiedContent = isReportCopiedContentPage(pathname ?? '', route);
  const isSelected =
    pathname?.includes(route) ||
    isJoinTeamManagePage(pathname ?? '', route) ||
    isPlaytesterTab(pathname ?? '', route) ||
    isReportCopiedContent;
  const isLinkActive = isNestedRouter(pathname ?? '', route);
  const isAccessDeniedByUser = access && !canAccess(user, access, AuthAction.READ);
  const isPageDisabled = !!messageIfDisabled;

  if (isAccessDeniedByUser || hidden) return <></>;

  const iconAndTextOfTheLink = (
    <>
      <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
      <ListItemText className={classes.navLeftItem} title={tooltip} onMouseEnter={setVisibleTooltip} ref={textElement}>
        {label}
      </ListItemText>
    </>
  );

  // TODO (App Router): Wat does this do? Migration required
  // useEffect(() => {
  //   if (!onClick) return;

  //   router.events.on('routeChangeComplete', onClick);
  //   return router.events.off('routeChangeComplete', onClick);
  // }, [router]);

  let linkComponent = (
    <Link href={{ pathname: route, query: { ...params, lang: searchParams?.get('lang') } }} passHref prefetch={false} legacyBehavior>
      <ListItem
        button
        key={key}
        component="a"
        className={clsx(
          classes.navLeftOption,
          { [classes.navLeftButton]: isSelected },
          { [classes.navLeftButtonEventsAuto]: isReportCopiedContent },
          { [classes.navLeftOptionHover]: !isSelected },
          { [classes.navLeftActiveLink]: isLinkActive },
          'navigation-link-container',
        )}
        selected={isSelected}
      >
        {iconAndTextOfTheLink}
      </ListItem>
    </Link>
  );

  // EXTERNAL LINKS, OPEN IN NEW TAB AND USE NATIVE <A> TAGS
  if (isExternal) {
    linkComponent = (
      <a
        href={route}
        rel="noopener noreferrer"
        className={clsx(classes.navLeftLink, 'navigation-link-container')}
        onClick={(e) => {
          e.preventDefault();
          setNewExternalRoute(route);
        }}
      >
        <ListItem button key="analytics" className={clsx(classes.navLeftOption, classes.navLeftOptionHover)}>
          {iconAndTextOfTheLink}
        </ListItem>
      </a>
    );
  }

  return (
    <div
      data-testid="navigation-link-item"
      role={label}
      className={clsx(classes.navLeftOptionWrapper, { [classes.navLeftOptionDisabled]: isPageDisabled })}
    >
      {linkComponent}
    </div>
  );
};
