import { useTranslation } from 'next-i18next';
import { useParams } from 'next/navigation';

import { ClaimsToken } from '@creator-portal/common/auth';
import { PERMISSION } from '@creator-portal/common/permissions/constants';
import { CreatorPrivileges, CreatorProgramStatusResponse, TeamMembershipRole, TeamSearchResult } from '@creator-portal/common/types';

import { CREATOR_RULES_PATH, PUBLISH_BRANDS_ACCESS_ROLE } from '@/config/common/constants';
import { toDocLocale } from '@/config/common/locale.config';

import { isProjectSubNavEnabled } from '@/services/projects/projects-service';

import NavIconBuilder from '@/components/common/icons/navigation/navIconBuilder';
import { NavSection } from '@/components/common/navigation/nav-section';

import { usePublicRuntimeConfig } from '@/hooks/use-public-runtime-config';

import { TeamsUrl } from '../teams/teams-service';
import { getIsTeamPersonal } from '../teams/utils';

export interface PageDisableRestrictions {
  isNotOwner?: boolean;
  isEnrolled?: boolean;
  isPersonalTeam?: boolean;
  isCabinedMode?: boolean;
  isOver18?: boolean;
  isActive?: boolean;
  canEditSettings?: boolean;
  isBanned?: boolean;
  allowBanned?: boolean;
}

export const getMessageIfPageDisabled = (pageTitle: string, pageDisableRestrictions: PageDisableRestrictions): string | undefined => {
  const { t } = useTranslation();

  const { isOver18, isCabinedMode, isPersonalTeam, isEnrolled, isActive, isNotOwner, canEditSettings, isBanned, allowBanned } =
    pageDisableRestrictions;

  if (canEditSettings && !isNotOwner) {
    return '';
  }

  if (isOver18 === false) return t('navbar.disabledPage.cabinedModeRestriction.tooltip');
  if (isCabinedMode === true) return t('navbar.disabledPage.cabinedModeRestriction.tooltip');
  if (isEnrolled === false && !isBanned) return t('navbar.disabledPage.enrollRestriction.tooltip', { pageTitle });
  if (isActive === false && !isBanned) return t('navbar.disabledPage.activeRestriction.tooltip');
  if (isBanned === true && !allowBanned) return t('navbar.disabledPage.bannedRestriction.tooltip');
  else if (isBanned === true && allowBanned) return '';
  if (isPersonalTeam === true) return t('navbar.disabledPage.personalTeamRestriction.tooltip');
  if (isNotOwner === true) return t('navbar.disabledPage.ownerRestriction.tooltip', { pageTitle });
  if (canEditSettings === false) return t('epic.ecp.hyperwallet.error.api.unavailable');

  return undefined;
};

export const getActiveTeam = (teams?: TeamSearchResult[], teamId?: string | string[]): TeamSearchResult | undefined => {
  const activeTeam = teams?.find((team) => team.teamId === teamId);

  return activeTeam;
};

export const getActiveTeamMemberRole = (activeTeam: TeamSearchResult | undefined): TeamMembershipRole | undefined => {
  const teamMemberRole = activeTeam ? activeTeam.membership?.cpRole : 'owner';

  return teamMemberRole;
};

export const getActiveTeamData = (teams?: TeamSearchResult[], teamId?: string | string[]) => {
  const { t } = useTranslation();

  const activeTeam = getActiveTeam(teams, teamId);
  const activeTeamName = activeTeam ? activeTeam.name : t('user-card.team.my-projects');
  const teamMemberRole = getActiveTeamMemberRole(activeTeam);

  return { activeTeamName, teamMemberRole };
};

export const getNavBarLabel = (creatorPrivileges: CreatorPrivileges | undefined, isPersonalTeam: boolean, isNotOwner: boolean) => {
  const { t } = useTranslation();

  const currentCreatorCode = creatorPrivileges?.slugValue;
  const defaultProfileNavbarLabel = t('navbar.profile');

  return (isPersonalTeam || !isNotOwner) && currentCreatorCode ? currentCreatorCode : defaultProfileNavbarLabel;
};

interface OptionsForNavigation {
  lang: string | string[] | undefined;
  teamId: string | string[] | undefined;
  isJoinMemberPage: boolean | undefined;
  isNavigationVisible: boolean;
  isCabinedMode: boolean;
  creatorPrivileges: CreatorPrivileges | undefined;
  creatorProgramStatus: CreatorProgramStatusResponse | undefined;
  isOver18: boolean;
}
export const BANNED_CREATOR_PROFILE_REDIRECT = 'https://www.epicgames.com/account/eula-history';

export const prepareNavigationData = (teamMemberRole: TeamMembershipRole | undefined, options: OptionsForNavigation) => {
  const { isJoinMemberPage, lang, teamId } = options;

  const docLang = toDocLocale(lang);
  const isPersonalTeam = getIsTeamPersonal(teamId);
  const encodedTeamId = isJoinMemberPage ? 'personal' : encodeURIComponent((teamId as string) || 'personal');

  const brandFeaturesNavSection: NavSection = createBrandFeaturesNavSection(teamMemberRole, isPersonalTeam, encodedTeamId, options);
  const teamFeaturesNavSection: NavSection = createTeamFeaturesNavSection(isPersonalTeam, encodedTeamId, options);
  const resourcesNavSection: NavSection = createResourcesNavSection(teamId, docLang);

  return [brandFeaturesNavSection, teamFeaturesNavSection, resourcesNavSection];
};

const createBrandFeaturesNavSection = (
  teamMemberRole: TeamMembershipRole | undefined,
  isPersonalTeam: boolean,
  encodedTeamId: string,
  options: OptionsForNavigation,
) => {
  const { t, i18n } = useTranslation();
  const { isNavigationVisible, creatorPrivileges, isOver18, creatorProgramStatus } = options;

  const isEnrolled = creatorProgramStatus?.enrolled;
  const isActive = creatorProgramStatus?.active;
  const isBanned = creatorProgramStatus?.banned;
  const isNotOwner = teamMemberRole !== PUBLISH_BRANDS_ACCESS_ROLE;
  const canEditSettings = creatorProgramStatus?.canEditSettings;

  return {
    isVisible: isNavigationVisible,
    cssClass: 'brandLinks',
    dataTestId: 'brand-links-navigation-block',
    links: [
      {
        icon: <NavIconBuilder name="manage-profile" />,
        key: 'profile',
        label: getNavBarLabel(creatorPrivileges, isPersonalTeam, isNotOwner),
        tooltip: isBanned ? t('navbar.disabledPage.bannedRestriction.tooltip') : undefined,
        route: isBanned ? BANNED_CREATOR_PROFILE_REDIRECT : `/${encodedTeamId}/profile`,
        messageIfDisabled: getMessageIfPageDisabled(t('navbar.profile'), {
          isNotOwner,
          isOver18,
          isEnrolled,
          isActive,
          canEditSettings,
          isBanned,
          allowBanned: true,
        }),
      },
      {
        isExternal: true,
        icon: <NavIconBuilder name="monetization" />,
        key: 'monetization',
        label: t('navbar.monetization'),
        // TODO: make link to reflect the environment
        route: isEnrolled
          ? `https://www.epicgames.com/affiliate/${i18n.language}/fortnite-games/rewards`
          : TeamsUrl.getMonetizationUrl(encodedTeamId),
        messageIfDisabled: getMessageIfPageDisabled(t('navbar.monetization'), { isNotOwner, isBanned }),
      },
      {
        isExternal: true,
        icon: <NavIconBuilder name="analytics" />,
        key: 'analytics',
        label: t('navbar.analytics'),
        // TODO: make link to reflect the environment
        route: `https://www.epicgames.com/affiliate/${i18n.language}/fortnite-games/insights`,
        messageIfDisabled: getMessageIfPageDisabled(t('navbar.analytics'), { isNotOwner, isEnrolled, isBanned }),
      },
    ],
  };
};

const createTeamFeaturesNavSection = (isPersonalTeam: boolean, encodedTeamId: string, options: OptionsForNavigation): NavSection => {
  const { t } = useTranslation();
  const { isNavigationVisible, isCabinedMode, creatorProgramStatus } = options;
  const isBanned = creatorProgramStatus?.banned;

  return {
    isVisible: isNavigationVisible,
    cssClass: 'teamFeaturesLinks',
    dataTestId: 'team-features-links-navigation-block',
    links: [
      {
        icon: <NavIconBuilder name="projects" />,
        key: 'projects',
        label: t('navbar.projects'),
        route: `/${encodedTeamId}/projects`,
        messageIfDisabled: getMessageIfPageDisabled(t('navbar.projects'), { isCabinedMode }),
      },
      {
        icon: <NavIconBuilder name="media-upload" />,
        key: 'media',
        label: t('navbar.media-upload'),
        access: PERMISSION.MEDIA_PAGE,
        route: `/${encodedTeamId}/media`,
        messageIfDisabled: getMessageIfPageDisabled(t('navbar.media-upload'), { isCabinedMode }),
      },
      {
        icon: <NavIconBuilder name="teams" />,
        key: 'teams',
        label: t('navbar.teams'),
        route: `/${encodedTeamId}/teams`,
        messageIfDisabled: getMessageIfPageDisabled(t('navbar.teams'), { isPersonalTeam, isCabinedMode }),
      },
    ],
  };
};

const createResourcesNavSection = (teamId: string | string[] | undefined, docLang: string): NavSection => {
  const { EPIC_FORTNITE_URL } = usePublicRuntimeConfig();
  const { t, i18n } = useTranslation();

  return {
    isVisible: true,
    header: t('navbar.resources'),
    cssClass: 'resourcesLinks',
    dataTestId: 'resources-links-navigation-block',
    links: [
      {
        icon: <NavIconBuilder name="news" />,
        key: 'news',
        label: t('navbar.news'),
        route: '/news',
        params: {
          team: teamId?.toString(),
        },
      },
      {
        isExternal: true,
        icon: <NavIconBuilder name="documentation" />,
        key: 'documentation',
        label: t('navbar.documentation'),
        // TODO: make link to reflect the environment
        // The new site will be going live in March 2023 and until then anyone who does not have access will be redirected to the old site.
        // route: `https://www.epicgames.com/fortnite/${i18n.language}/creative/docs`,
        route: `https://dev.epicgames.com/documentation/${docLang}/fortnite-creative/`,
      },
      {
        isExternal: true,
        icon: <NavIconBuilder name="uefn" />,
        key: 'UEFN',
        label: t('navbar.uefn'),
        route: 'https://dev.epicgames.com/community/fortnite/getting-started/uefn',
      },
      {
        icon: <NavIconBuilder name="support" />,
        key: 'support',
        label: t('navbar.support'),
        // route: `https://creative.fortnite.com/s/creative-support?language=${i18n.language}`,
        route: '/support',
        params: {
          team: teamId?.toString(),
        },
      },
      {
        isExternal: true,
        icon: <NavIconBuilder name="dev-community" />,
        key: 'community',
        label: t('navbar.community'),
        // TODO: make link to reflect the environment
        route: 'https://dev.epicgames.com/community/fortnite',
      },
      {
        isExternal: true,
        icon: <NavIconBuilder name="rules" />,
        key: 'rules',
        label: t('navbar.rules'),
        // TODO: make link to reflect the environment
        route: EPIC_FORTNITE_URL + CREATOR_RULES_PATH,
      },
    ],
  };
};

export const isMainNavigationCollapsed = (session: ClaimsToken | undefined, params: ReturnType<typeof useParams>): boolean =>
  isProjectSubNavEnabled(session) && !!params?.['team'] && !!params?.['projectId'];
