import { PROFILE_IMG_TYPES } from '@creator-portal/common/media/constants';

import { getMediaUrl } from '@/config/media/functionalConstants';

import * as Xhr from '@/util/xhr';

import type { UploadingInfoType } from '@/pages/[team]/media';
import type { Dispatch, SetStateAction } from 'react';

type ContentInfoType = {
  contentLength: number;
  contentType: string;
};

interface FilesContentInfo {
  primaryFileName?: string;
  files: {
    [key: string]: ContentInfoType;
  };
}
export interface MediaEntryForUploadData {
  files: {
    [key: string]: {
      urls: Array<string>;
    };
  };
  mediaId: string;
  statusCode?: string;
}
interface Etags {
  files: {
    [key: string]: {
      etags: Array<string>;
    };
  };
}

let uploadXhrRequest: XMLHttpRequest;
const xhr = Xhr.getInstance();

export const uploadFile = async (
  url: string,
  // eslint-disable-next-line @typescript-eslint/ban-types
  setUploadingInfo: Dispatch<SetStateAction<UploadingInfoType>> | false,
  isPrimaryFile: boolean,
  file: File | undefined,
): Promise<{ response: XMLHttpRequest; etag: string | null }> => {
  const blob = file ? new Blob([file]) : undefined;
  const uploadStartTime = new Date().getTime();
  uploadXhrRequest = new XMLHttpRequest();

  return await new Promise((resolve) => {
    uploadXhrRequest.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable && isPrimaryFile) {
        // progressEvent.loaded in bytes
        const uploadingPercentage: number = Math.round((event.loaded * 100) / event.total);
        // duration in seconds
        const duration = (new Date().getTime() - uploadStartTime) / 1000;
        // uploadSpeed in bytes/sec
        const uploadSpeed = event.loaded / duration;
        // uploadTimeRemaining in sec
        const uploadTimeRemaining = (100 * duration) / uploadingPercentage - duration;
        if (setUploadingInfo) {
          setUploadingInfo(
            (prevState: UploadingInfoType): UploadingInfoType => ({
              ...prevState,
              uploadingPercentage,
              uploadSpeed,
              uploadTimeRemaining,
            }),
          );
        }
      }
    });
    uploadXhrRequest.addEventListener('loadend', () => {
      resolve({
        response: uploadXhrRequest,
        etag: uploadXhrRequest.getResponseHeader('etag'),
      });
    });
    uploadXhrRequest.open('PUT', url);
    uploadXhrRequest.setRequestHeader('Content-Type', 'application/octet-stream');
    uploadXhrRequest.send(blob);
  });
};

export const cancelFileUpload = (cb?: () => void): void => {
  const timerId = setInterval(() => {
    if (uploadXhrRequest) {
      uploadXhrRequest.abort();
      cb && cb();
      clearInterval(timerId);
    }
  }, 500);
};

export const removeMedia = async (mediaId: string, imageType?: PROFILE_IMG_TYPES): Promise<null> => {
  let url = getMediaUrl(mediaId);
  if (imageType) {
    const params = new URLSearchParams();
    params.append('imageType', imageType);
    url += `?${params.toString()}`;
  }

  const response = await xhr.fetchJson(url, { method: 'DELETE' });

  Xhr.throwOnFailure(response);

  return null;
};

export const getUrlsForUpload = async (url: string, filesContentInfo: FilesContentInfo): Promise<MediaEntryForUploadData> => {
  const response = await xhr.fetchJson<MediaEntryForUploadData>(url, {
    method: 'POST',
    body: JSON.stringify(filesContentInfo),
  });
  Xhr.throwOnFailure(response);

  return response.data;
};

export const finalizeMediaEntry = async (url: string, etags: Etags): Promise<void> => {
  await xhr.fetchJson(url, {
    method: 'POST',
    body: JSON.stringify(etags),
  });
};
