import { useTheme } from '@mui/material';
import { useTranslation } from 'next-i18next';

import { DEFAULT_PAGE_URL } from '@creator-portal/common/publishing/constants';

import { localeList } from '@/config/common/locale.config';

import { usePublicRuntimeConfig } from '@/hooks/use-public-runtime-config';
import { useColorMode } from '@/hooks/useColorMode';

import type { Locale } from '@wf-components/hygraph-content';
import type { NavCustomAccountLink } from '@wf-components/navigation-consumer';

export interface LinksTranslation {
  account: string;
  redeemCode: string;
  vBucks: string;
  linkPsn: string;
  linkXbl: string;
  linkSwitch: string;
}

interface NavigationLocale {
  id: string;
  label: string;
  langCode: string;
  locale: Locale;
}

export const headerConfig = () => {
  const { EPIC_FORTNITE_URL, EPIC_URL } = usePublicRuntimeConfig();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const colorMode = useColorMode();

  const locale = i18n.resolvedLanguage as Locale;

  const localeConfig: NavigationLocale[] = localeList.map(({ displayName, code }) => ({
    id: 'code',
    label: displayName,
    langCode: code,
    locale,
  }));

  const propertyLogo = {
    id: 'postparty-propertyLogo',
    hrefTemplate: `/`,
    owner: 'postparty',
    relativePath: DEFAULT_PAGE_URL,
    locale,
    logo: {
      id: 'create-fn-logo',
      url: 'https://cdn2.unrealengine.com/creator-portal-37fb4f392969.svg?resize=1&w=300&quality=high',
    },
    logoAlt: 'Postparty',
  };

  const logoWidth = 157;

  const isDarkMode = theme.palette.mode === 'dark';

  const customDarkLink: NavCustomAccountLink = {
    id: 'custom-dark-link',
    key: 'custom-dark-link',
    owner: 'colorMode',
    label: t('user.theme.dark'),
    hrefTemplate: '#',
    locale: locale,
    labelStyle: `
		display: inline-flex;
		padding: 5px 10px 5px 10px;
		border-radius: 17px;
		background-color: #000000;`,
    labelBeforeStyle: `
      width: 14px;
      height: 14px;
      content: "";
      display: block;
      box-shadow: -4.5px 0 0 0 #D3D4D7;
      margin-top: 1px;
      margin-left: 5px;
      border-radius: 50%;`,
  };

  const customLightLink: NavCustomAccountLink = {
    id: 'custom-light-link',
    key: 'custom-light-link',
    owner: 'colorMode',
    label: t('user.theme.light'),
    hrefTemplate: '#',
    locale: locale,
    labelStyle: `
		display: inline-flex;
		padding: 5px 10px 5px 10px;
		border-radius: 17px;
		background-color: #000000;`,
    labelAfterStyle: `
      width: 14px;
      height: 14px;
      content: "";
      display: block;
      margin-top: 1px;
      margin-left: 7px;
      border-radius: 10px;
      background-color: #CACED6;
    `,
  };

  const translations = {
    account: t('user.account'),
    redeemCode: t('user.redeem-code'),
    vBucks: t('user.v-bucks'),
    linkXbl: t('user.link-xbl'),
    linkPsn: t('user.link-psn'),
    linkSwitch: t('user.link-switch'),
  };

  const dropdownLinks = [
    {
      hrefTemplate: `${EPIC_URL}/account/personal?productName=fortnite`,
      id: 'accountInfo',
      key: 'accountInfo',
      label: translations.account,
      locale,
    },
    {
      hrefTemplate: `${EPIC_FORTNITE_URL}/redeem`,
      id: 'redeem',
      key: 'redeem',
      label: translations.redeemCode,
      locale,
    },
    {
      hrefTemplate: `${EPIC_FORTNITE_URL}/vbuckscard`,
      id: 'redeemvbucks',
      key: 'redeemvbucks',
      label: translations.vBucks,
      locale,
    },
    {
      hrefTemplate: `${EPIC_FORTNITE_URL}/link?authType=psn`,
      id: 'linkPsn',
      key: 'linkPsn',
      label: translations.linkPsn,
      locale,
    },
    {
      hrefTemplate: `${EPIC_FORTNITE_URL}/link?authType=nintendo`,
      id: 'linkNintendo',
      key: 'linkNintendo',
      label: translations.linkSwitch,
      locale,
    },
    {
      hrefTemplate: `${EPIC_FORTNITE_URL}/link?authType=xbl`,
      id: 'linkXbl',
      key: 'linkXbl',
      label: translations.linkXbl,
      locale,
    },
  ];

  const config = {
    customAccountLinks: [isDarkMode ? customDarkLink : customLightLink],
    hideSearch: true,
    locale: locale,
    localeConfig,
    propertyLogo,
    logoWidth,
    dropdownLinks,
    mainLinks: [],
    colorMode,
  };

  return config;
};
