export const EPIC_LOCALE_COOKIE_NAME = 'EPIC_LOCALE_COOKIE';

export function getCookieValue(cookieName: string, cookies: string): string | undefined {
  return cookies
    .split('; ')
    .find((cookie) => cookie.startsWith(cookieName))
    ?.split('=')[1];
}

export function setCookieValue(cookieName: string, value: string): void {
  document.cookie = `${cookieName}=${value}`;
}
