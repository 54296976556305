import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) =>
  createStyles({
    errorDivider: {
      width: 32,
      height: 3,
      borderRadius: 2,
      margin: '16px auto',
      backgroundColor: theme.palette.divider,
    },
    description: {
      textAlign: 'center',
      color: theme.palette.shade0,
      fontSize: `${theme.typography.pxToRem(16)}!important`,
      lineHeight: `${theme.typography.pxToRem(20)}!important`,
      wordBreak: 'break-word',
    },
    errorCode: {
      textAlign: 'center',
      color: theme.palette.shade0,
      fontSize: `${theme.typography.pxToRem(12)}!important`,
      lineHeight: `${theme.typography.pxToRem(16)}!important`,
      marginTop: `${theme.spacing(4)}!important`,
    },
  }),
);

export default useStyles;
