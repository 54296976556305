import { LINKCODE_AND_VERSION_REGEX } from './constants';

export * from './constants';
export * from './types';

export type LinkCode = {
  code: string;
  version?: number;
};

export const getLinkCodeAndVersion = (code: string): LinkCode | undefined => {
  const validLinkCode = LINKCODE_AND_VERSION_REGEX.exec(code);

  if (!validLinkCode) return;

  return {
    code: validLinkCode[1],
    version: validLinkCode[3] !== undefined ? parseInt(validLinkCode[3]) : 1,
  };
};

export const stringifyLink = (link: LinkCode): string => `${link.code}v${link.version}`;
