import { useMemo } from 'react';
import { EpicGamesFooter } from 'epic-games-footer/dist/client';
import { useTranslation } from 'next-i18next';

import { footerConfig, theme } from '@/config/common/footer.config';

import useStyles from './main.footer.styles';

export default function MainFooterComponent(): JSX.Element {
  // Possible translations for t(`footer.legalTags.title.${item.countryCodes}`)
  // t("footer.legalTags.title.US,CA")
  // t("footer.legalTags.title.EUROPE")
  const styles = useStyles();
  const { t, i18n } = useTranslation();
  const year = new Date().getFullYear();
  const footerLinks = [
    {
      href: 'https://www.fortnite.com/home',
      title: t('footer.links.home'),
      target: '_blank',
    },
    {
      href: 'https://www.fortnite.com/battle-pass',
      title: t('footer.links.battle-pass'),
      target: '_blank',
    },
    {
      href: 'https://www.fortnite.com/watch-fortnite',
      title: t('footer.links.watch-fortnite'),
      target: '_blank',
    },
    {
      href: 'https://www.fortnite.com/buy-now/battle-royale',
      title: t('footer.links.battle-royale'),
      target: '_blank',
    },
    {
      href: 'https://www.fortnite.com/news',
      title: t('footer.links.news'),
      target: '_blank',
    },
    {
      href: 'https://www.fortnite.com/faq',
      title: t('footer.links.faq'),
      target: '_blank',
    },
    {
      href: 'https://www.fortnite.com/eula',
      title: t('footer.links.eula'),
      target: '_blank',
    },
    {
      href: 'https://www.fortnite.com/competitive',
      title: t('footer.links.competitive'),
      target: '_blank',
    },
    {
      href: 'https://www.fortnite.com/vbuckscard',
      title: t('footer.links.vbuckscard'),
      target: '_blank',
    },
    {
      href: 'https://www.fortnite.com/help-center',
      title: t('footer.links.help-center'),
      target: '_blank',
    },
    {
      href: 'https://www.fortnite.com/safety-and-security',
      title: t('footer.links.safety-and-security'),
      target: '_blank',
    },
    {
      href: 'https://www.epicgames.com/site/community-rules',
      title: t('footer.links.community-rules'),
      target: '_blank',
    },
  ];

  const legalTags = useMemo(() => {
    const language = i18n.language;
    if (language === 'en-US') {
      return footerConfig.legalTags
        .filter((item) => item.countryCodes === 'US,CA')
        .map((item) => ({ ...item, title: t(`footer.legalTags.title.${item.countryCodes}`) }));
    } else if (
      language === 'pl' ||
      language === 'fr' ||
      language === 'it' ||
      language === 'pt-BR' ||
      language === 'es-MX' ||
      language === 'es-ES'
    ) {
      return footerConfig.legalTags
        .filter((item) => item.countryCodes === 'EUROPE')
        .map((item) => ({ ...item, title: t(`footer.legalTags.title.${item.countryCodes}`) }));
    }

    return [];
  }, [i18n.language]);

  return (
    <>
      <EpicGamesFooter
        theme={theme}
        epicPropertyClasses={styles.mainFooter}
        socialLinks={footerConfig.socialLinks}
        copyrights={[t('footer.copyright', { year })]}
        legalTags={legalTags}
        newsLetterConfigs={{
          titleMsg: t('footer.newsletter.titleMsg'),
          btnTxt: t('footer.newsletter.btnTxt'),
          successMsg: t('footer.newsletter.successMsg'),
          ...footerConfig.newsLetterConfigs,
        }}
        newLineCopyrights={footerConfig.newLineCopyrights}
        epicPropertyName={footerConfig.epicPropertyName}
        logos={footerConfig.logos}
        // @ts-ignore:next-line
        callToAction={footerConfig.callToAction}
        messages={{
          tos: t('footer.message.tos'),
          privacy: t('footer.message.privacy'),
          backToTop: t('footer.message.back-to-top'),
        }}
        links={footerLinks}
        hideDefaultTermsPolicy={footerConfig.hideDefaultTermsPolicy}
        _locale={i18n.language}
        enableNewsLetter={footerConfig.enableNewsLetter}
      />
    </>
  );
}
