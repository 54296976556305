import { MarketingOptIn } from '../vk';
import { DISCOVERY_INTENT } from './constants';

export type PutLinkDiscoveryResult = { linkCode: string; discoverable: boolean };

export type PostLinkMediaUploadRequest = {
  /** optionally describes a preview image that should be applied to the live link upon successful moderation */
  image?: LinkMediaDescriptor;

  /** optionally describes a trailer video that should be applied to the live link upon successful moderation. */
  trailer?: LinkMediaDescriptor;

  /** optionally describes a square image that should be applied to the live link upon successful moderation */
  squareImage?: LinkMediaDescriptor;

  /** optionally describes a lobby background that should be applied to the live link upon successful moderation */
  lobbyBackground?: LinkMediaDescriptor;
};

export type PostLinkMediaUploadResponse = {
  /** token received from Quicksilver for the media being uploaded.  */
  mediaSubmissionToken: string;

  /** uniquely identifies the media being uploaded.  */
  mediaSubmissionId: string;

  /** correlationId used to initialize media upload.  Apply this value to `x-epic-correlation-id` header for the rest of the publish workflow. */
  correlationId: string;

  /** response after media upload multiple */
  filesToUploadUrls: { [key: string]: { url: string } | undefined };
};

export type PostPublishLinkRequest = {
  mediaSubmissionToken: string | null;
  mediaSubmissionFileEtags: Record<string, { etags: string[] }>;

  /** identifies media has been uploaded and should be applied to the live link upon successful moderation. */
  mediaSubmissionId: string | null;

  /** title to apply to the live link upon successful moderation.  */
  title: string;

  /** changes that was made from previous release.  */
  changes?: string;

  /** tagline to apply to the live link upon successful moderation.  */
  tagline: string;

  /** description tags to apply to the live link upon successful moderation. */
  tags: string[];

  /** introduction strings to apply to the live link upon successful moderation. */
  introduction: string[];

  /** true if the build being submitted should be automatically activated upon successful moderation. */
  autoActivate: boolean;

  /** discoveryIntent the field is used to set it to a public link discoveryIntent ('PUBLIC' | 'PRIVATE' | 'DISABLED') */
  discoveryIntent: DISCOVERY_INTENT;

  /** Attribute list */
  attributions: Attribution[];

  /** true if Square Thumbnail picture is included in submission (new or existing) */
  hasSquareThumbnail: boolean;

  /** true if Trailer video is included in submission (new or existing) */
  hasTrailer: boolean;

  /** true if Lobby background is included in submission (new or existing) */
  hasLobbyBackground: boolean;

  /** translation config */
  machineTranslationPreferences?: MachineTranslationPreferencesType | null;

  // Set of flags which indicate what to do for marketing team
  marketing_opt_in?: MarketingOptIn;
};

export type LinkMediaDescriptor = {
  /** media content type */
  contentType: string;
  name?: string;
  contentLength: number;
};

export type LinkMediaSubmissionValidationError = {
  errors: { property: string; expectation: string[] }[];
};

export type LinkMatchMakingMeta = {
  playerCount: number;
  name: string;
  playlists: string[];
};

export type ModulePublicApi = {
  moduleId: string;
  version: number;
  assets: string[];
};

export enum ModulePublicApiErrors {
  NONE = 0,
  DELETED_ASSETS = 1 << 1,
}

export enum ModulePublicApiState {
  UNMODIFIED,
  ADDED,
  DELETED,
}

export type ModulePublicAssetState = {
  moduleId: string;
  version: number;
  assetPath: string;
  state: ModulePublicApiState;
};

export type PublicApiCompatibility = {
  errors: ModulePublicApiErrors;
  assets?: ModulePublicAssetState[];
};

export interface LinkBuildStatus {
  type: string;
  experimental?: boolean;
  failedModuleSafetyStatus?: boolean;
  moderationSource?: string;
  contentSize?: LinkBuildStatusBudget;
  profiling?: LinkBuildStatusBudget;
}

export type LinkBuildStatusBudget = {
  isValid: boolean;
  isWithinPublicBudget: boolean;
  usage: number;
  budget: number;
};

/** A content attribution (such as a CC license). */
export interface Attribution {
  /** The title of the material localized in its original locale. */
  title: string;
  /** The URL to the original source. **/
  source_url?: string | null;
  /** The author of the material. */
  author: string | null;
  /** The URL to the author. */
  author_url?: string | null;
  /** The license type. */
  license: string;
  /** The URL to the license deed. */
  license_url?: string | null;
}

export type MachineTranslationPreferencesType = {
  title: boolean;
  introduction: boolean;
  tagline: boolean;
};

export interface ShortenedLinkCodeData {
  linkCode: string;
  title?: string;
  imageUrl?: string | null;
  squareImageUrl?: string | null;
}
