import * as Xhr from '@/util/xhr';

export namespace ModerationUrl {
  export const releaseViolations = (locale: string, releaseId: string) =>
    `/api/moderation/v1/violations/release/${encodeURIComponent(releaseId)}?locale=${locale}` as const;
  export const linkViolations = (locale: string, linkCode: string, version: number) =>
    `/api/moderation/v1/violations/link/${encodeURIComponent(linkCode)}/${encodeURIComponent(version)}?locale=${locale}` as const;
  export const moduleViolations = (locale: string, moduleId: string, version: number) =>
    `/api/moderation/v1/violations/module/${encodeURIComponent(moduleId)}/${encodeURIComponent(version)}?locale=${locale}` as const;

  export const screenText = () => `/api/moderation/v1/screen/text` as const;

  export const cancelModeration = (releaseId: string) => `/api/moderation/v1/cancel/${encodeURIComponent(releaseId)}` as const;
}

export const cancelModerationForRelease = async (releaseId: string) => {
  const xhr = Xhr.getInstance();
  const response = await xhr.fetchJson(ModerationUrl.cancelModeration(releaseId), { method: 'DELETE' });
  Xhr.throwOnFailure(response);

  return;
};
