// palette values for light mode
import colors from './colorVariables';

export const lightColors = {
  // OUTDATED COLORS that need to be replaced with the new ones
  shade0: '#000000',
  shade1: '#484D55',
  shade2: '#525860',
  shade3: '#8C94A0',
  shade4: '#CACED6',
  shade5: '#E8EAED',
  shade6: '#F5F7F7',
  shade7: '#ffffff',
  blue: '#0E71D0',
  blue1: '#4A83E0',
  purple: '#7C4BC2',
  pink: '#CA539A',

  // NEW COLORS

  // text token variables
  textPrimary: colors.dark100, // Default text color. Use on main page headers and form Input texts.

  textSecondary: colors.gray90, // Use on secondary page headers (News), and inactive (but enabled) navigation texts. For placeholder text and content with a lower emphasis. Use on paragraph texts, project card details, labels, and page numbers.

  textAccent: colors.blue80, // For high-emphasis text links, button texts, and active elements.

  textContrastWhite: colors.light100, // For content placed over any accent color. Use on primary buttons and similar components. The color adapts without switching modes.

  textContrastBlack: colors.dark100, // The color adapts without switching modes.

  textDisabled: colors.gray40, // For all text on disabled components.
  textSuccess: colors.green80, // For content related to a success status.
  textProcessing: colors.orange100, // For content related to a processing status.
  textWarning: colors.orange100, // For content related to a warning status.
  textError: colors.red50, // For content related to a error status.
  // icon token variables
  iconPrimary: colors.gray50, // Default icon color. Use on system-level icons.
  iconSecondary: colors.gray70, // For icons that match content with lower emphasis. Use on inactive Nav Icons.
  iconAccent: colors.blue80, // For content related to an information status.
  iconContrastWhite: colors.light100, // For content placed over any accent color. Use on status chips.
  iconContrastBlack: colors.dark100, // The color adapts without switching modes.
  iconDisabled: colors.gray40, // For all icons on disabled components.
  iconSuccess: colors.green80, // For content related to a success status.
  iconProcessing: colors.orange100, // For content related to a processing status.
  iconWarning: colors.orange100, // For content related to a warning status.
  iconError: colors.red50, // For content related to an error status.
  // Fill token variables
  bgPrimary: colors.light100, // Used as container base-layer. In modals, will work as input component base.
  bgSecondary: colors.gray10, // Used as container base-layer +1. In modals, will work background.
  bgTertiary: colors.gray20, // Used as container base-layer +2. In modals/setup pages, will work as background for actions.
  statusAccent: colors.blue70, // Use for UI components that aren’t icons. E.g., date picker.
  statusListed: colors.blue70, // Use for publishing status chips.
  statusUnlisted: colors.purple70, // Use for publishing status chips (unlisted).
  statusUnpublished: colors.gray110, // Use for publishing status chips (unpublished).
  statusDisabled: colors.red50, // Use for publishing status chips (disabled).
  statusHidden: colors.brown50, // Use for publishing status chips.
  statusSuccess: colors.green80, // Use for publishing status chips.
  tooltip: colors.gray20, // Elevated grey to use in the different tooltips to guide the user.
  contrastBlack: colors.dark100, // The color adapts without switching modes.
  contrastWhite: colors.light100, // The color adapts without switching modes.
  // Gradient
  button01: colors.blue60, // Used for main Creator Portal CTAs.
  button02: colors.blue80, // Used for main Creator Portal CTAs.
  icon01: colors.blue50, // Used for more prominent Creator Portal navigation icons.
  icon02: colors.blue40, // Used for more prominent Creator Portal navigation icons.
  info01: colors.blue80, // Used in info banners.
  info02: colors.blue30, // Used in info banners.
  success01: colors.green80, // Used in success confirmation snackbars.
  success02: colors.green40, // Used in success confirmation snackbars.
  error01: colors.red50, // Used in error snackbars and error dialogs.
  error02: colors.red50, // Used in error snackbars and error dialogs.
  warning01: colors.orange60, // Used in warning dialogs.
  warning02: colors.orange30, // Warning dialogs.
  bgLinear01: colors.gradientLightLin01, // Part of the creator portal background asset.
  bgLinear02: colors.gradientLightLin02, // Part of the creator portal background asset.
  bgLinear03: colors.gradientLightLin03, // Part of the creator portal background asset.
  bgLinear04: colors.gradientLightLin04, // Part of the creator portal background asset.
  bgLinear05: colors.gradientLightLin01, // Part of the creator portal background asset.
  bgRadial01: colors.gradientLightRad01, // Part of the creator portal background asset.
  bgRadial02: colors.gradientLightRad02, // Part of the creator portal background asset.
  bgTexture01: colors.gradientLightTex01, // Part of the creator portal background asset.
  bgTexture02: colors.gradientLightTex02, // Part of the creator portal background asset.
  // Border token variables. Used as border/stroke/ divider colour. Publish them to use in design projects!
  borderDefault: colors.gray40, // Base divider.
  borderHover: colors.transparentDark000, // Border hover state.
  borderPress: colors.transparentLight040, // Border press state.
  borderDisabled: colors.transparentLight040, // Border disabled state for components.
  borderActive: colors.blue70, // Border active/focus state for components.
  // Transparent token variables. Opacity primitives applied into components to help interaction. Publish them to use in design projects!
  hover: colors.transparentDark005, // Component hover state.
  press: colors.transparentLight040, // Component press state.
  disabled: colors.transparentGray40_060, // Component disabled state.
  contrastMuted: colors.transparentLight020, // ?
  coreShadow: colors.transparentDark010, // Shadow in cards and menus.
  castShadow: colors.transparentDark008, // Shadow in cards and menus.
  modalOverlay: colors.transparentDark060, // Shadow in modals and menus.
  // Analytics token variables. Exclusive colours for data vis. Publish them to use in design projects!
  analyticsBlue: colors.blue70, // Use it as base colour in charts.
  analyticsPurple: colors.purple80, // Use it as base colour in charts.
  analyticsPink: colors.pink70, // Use it as base colour in charts.
};
