import { unstable_noStore as noStore } from 'next/cache';

import { assertNonNullish } from './util/asserts';

const isGameDev = process.env.EPIC_ENV === 'gamedev';
const isProd = process.env.EPIC_ENV === 'prod';

const TALON_FLOW_ID = isGameDev ? 'sac_application_dev' : 'sac_application_prod';

const EPIC_URL = isGameDev ? 'https://epicgames-gamedev.ol.epicgames.net' : 'https://www.epicgames.com';
const EPIC_FORTNITE_URL = isGameDev ? 'https://dev.fortnite.ol.epicgames.net' : 'https://www.fortnite.com';
const EPIC_HYPER_WALLET_HOMEPAGE = isGameDev ? 'https://epicgames.uat.hyperwallet.com' : 'https://epicgames.hyperwallet.com';
const EPIC_HW_SCRIPT_URL = isProd ? 'https://pay.hyperwallet.com' : 'https://uat.hyperwallet.com';

export type PublicRuntimeConfig = Awaited<ReturnType<typeof getPublicRuntimeConfig>>;

/**
 * IMPORTANT!
 *
 * These will be exposed to the client. Do not include sensitive or secret data here.
 */
export async function getPublicRuntimeConfig() {
  noStore(); // opts out of Next.js Static Site Generation (SSG) since getPublicRuntimeConfig reads environment variables that are only available at runtime

  assertNonNullish(process.env.EPIC_ENV, 'Environment variable EPIC_ENV must be set');
  assertNonNullish(process.env.EPIC_DEPLOYMENT, 'Environment variable EPIC_DEPLOYMENT must be set');

  return {
    EPIC_ENV: process.env.EPIC_ENV,
    EPIC_DEPLOYMENT: process.env.EPIC_DEPLOYMENT,
    TALON_FLOW_ID,
    EPIC_URL,
    EPIC_FORTNITE_URL,
    EPIC_HYPER_WALLET_HOMEPAGE,
    EPIC_HW_SCRIPT_URL,
  };
}
