import { PERSONAL_TEAM_ID } from '@creator-portal/common/publishing/constants';

import type { TeamSearchResult } from '@creator-portal/common/types';

export enum Roles {
  OWNER = 'owner',
  ADMIN = 'admin',
  PUBLISHER = 'publisher',
  PLAYTESTER = 'playtester',
  COLLABORATOR = 'collaborator',
}
export enum Statuses {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
}

export const ANALYTICS_ROLES = [Roles.OWNER, Roles.ADMIN, Roles.PUBLISHER];

//TODO: confirm, that we do not translate team name, fetch from BE
export const getPersonalTeam = (personalTeamName: string, name?: string): TeamSearchResult => ({
  teamId: PERSONAL_TEAM_ID,
  name: personalTeamName,
  displayName: personalTeamName,
  publishingBrand: name || '',
  // @ts-ignore:next-line
  membership: {
    cpRole: Roles.OWNER,
  },
});
