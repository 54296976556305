import * as Sentry from '@sentry/browser';

import { AppError } from '@creator-portal/common/app-error';

const LogAsJSON = !!process.env.LOG_JSON;
const UseLogColors = !LogAsJSON && process.env.LOG_COLORS;
const SuppressAllLogs = process.env.NO_LOGS === 'true';
type LoggerFunction = (message: string, structure?: Record<string, unknown>) => void;

type LogWriter = (message?: any, ...optionalParams: any[]) => void;

export enum LogLevel {
  TRACE,
  INFO,
  DEBUG,
  DISPLAY,
  WARNING,
  ERROR,
}

function getPaddedLevelString(level: LogLevel) {
  switch (level) {
    case LogLevel.TRACE:
      return ' TRACE ';
    case LogLevel.INFO:
      return '  INFO ';
    case LogLevel.DEBUG:
      return ' DEBUG ';
    case LogLevel.DISPLAY:
      return 'DISPLAY';
    case LogLevel.WARNING:
      return 'WARNING';
    case LogLevel.ERROR:
      return ' ERROR ';
  }
}
function getSentrySeverity(level: LogLevel): Sentry.SeverityLevel {
  switch (level) {
    case LogLevel.TRACE:
    case LogLevel.DEBUG:
      return 'debug';
    case LogLevel.INFO:
    case LogLevel.DISPLAY:
      return 'info';
    case LogLevel.WARNING:
      return 'warning';
    case LogLevel.ERROR:
      return 'error';
  }
}
function getColoredType(level: LogLevel) {
  const type = getPaddedLevelString(level);
  switch (level) {
    case LogLevel.TRACE:
      return `\x1b[1;30;47m${type}\x1b[0;90m`;
    case LogLevel.INFO:
      return `\x1b[0m${type}`;
    case LogLevel.DEBUG:
      return `\x1b[90m${type}`;
    case LogLevel.DISPLAY:
      return `\x1b[0;32m${type}`;
    case LogLevel.WARNING:
      return `\x1b[30;43m${type}`;
    case LogLevel.ERROR:
      return `\x1b[30;41m${type}`;
  }
}

function zeroPad(num: number, digits: number): string {
  return num.toString().padStart(digits, '0');
}

function makeTimestamp(): string {
  const now = new Date();

  return `${now.getFullYear()}-${zeroPad(now.getMonth() + 1, 2)}-${zeroPad(now.getDate(), 2)} ${zeroPad(now.getHours(), 2)}:${zeroPad(
    now.getMinutes(),
    2,
  )}:${zeroPad(now.getSeconds(), 2)}.${zeroPad(now.getMilliseconds(), 3)}`;
}

function getLogWriter(logLevel: LogLevel): LogWriter {
  switch (logLevel) {
    case LogLevel.ERROR:
      return console.error;
    case LogLevel.WARNING:
      return console.warn;
    default:
      return console.log;
  }
}

function getLogger(level: LogLevel): LoggerFunction {
  if (SuppressAllLogs) return () => {};
  else if (LogAsJSON) {
    const typeStr = LogLevel[level];
    const write = getLogWriter(level);
    const sentrySeverity = getSentrySeverity(level);

    return (message: string, structure?: Record<string, unknown>) => {
      if (structure !== undefined) {
        delete (structure as any).level;
        delete (structure as any).message;
        write(`${JSON.stringify({ time: makeTimestamp(), level: typeStr, message, ...structure })}\n`);
      } else write(`${JSON.stringify({ time: makeTimestamp(), level: typeStr, message })}\n`);

      // manually add sentry breadcrumb since we are bypassing console.log
      Sentry.addBreadcrumb({
        category: 'log',
        message,
        level: sentrySeverity,
        data: structure,
      });
    };
  } else if (UseLogColors) {
    const coloredType = getColoredType(level);
    const write = getLogWriter(level);
    const sentrySeverity = getSentrySeverity(level);

    return (message, structure) => {
      // TODO: fix up colored output for browser.
      // write colored output
      if (structure !== undefined) write(`${coloredType} ${makeTimestamp()} ${message} | ${JSON.stringify(structure)}\x1b[0m\n`);
      else write(`${coloredType} ${makeTimestamp()} ${message}\x1b[0m\n`);

      // manually add sentry breadcrumb since we are bypassing console.log
      Sentry.addBreadcrumb({
        category: 'log',
        message,
        level: sentrySeverity,
        data: structure,
      });
    };
  } else if (level === LogLevel.ERROR) {
    const paddedType = getPaddedLevelString(level);

    return (message, structure) => {
      if (structure !== undefined) console.error(paddedType, makeTimestamp(), message, structure);
      else console.error(paddedType, makeTimestamp(), message);
    };
  } else {
    const paddedType = getPaddedLevelString(level);

    return (message, structure) => {
      if (structure !== undefined) console.log(paddedType, makeTimestamp(), message, structure);
      else console.log(paddedType, makeTimestamp(), message);
    };
  }
}

export const trace: LoggerFunction = process.env.LOG_TRACE !== undefined ? getLogger(LogLevel.TRACE) : (_: string) => {};

export const debug: LoggerFunction = getLogger(LogLevel.DEBUG);
export const info: LoggerFunction = getLogger(LogLevel.INFO);
export const display: LoggerFunction = getLogger(LogLevel.DISPLAY);
export const warn: LoggerFunction = getLogger(LogLevel.WARNING);

const errorLogger = getLogger(LogLevel.ERROR);
export const error = (error_in: Error | string, while_message: string, report = false, structure?: Record<string, unknown>): void => {
  // make sure the error includes the stack
  const error = typeof error_in === 'string' ? new Error(error_in) : new AppError(error_in);

  // log the error to STDOUT
  if (LogAsJSON) {
    if (error && error.stack) errorLogger(error.message, { ...structure, while: while_message, stack: error.stack });
    else if (error && error.message) errorLogger(error.message, { ...structure, while: while_message });
    else errorLogger(while_message, structure);
  } else {
    if (error && error.stack) errorLogger(`while ${while_message}\n${error.stack}`);
    else if (error && error.message) errorLogger(`${error.message} while ${while_message}`);
    else errorLogger(`Error while ${while_message}`);
  }

  // report to Sentry if requested
  if (report) {
    Sentry.withScope((scope: Sentry.Scope) => {
      if (error instanceof AppError) {
        scope.setExtra('error_code', error.errorCode);
        scope.setExtra('status_code', error.statusCode);
      }
      scope.setTag('while', while_message);
      if (structure) scope.setExtra('context', structure);
      (error as any)._sentryId = Sentry.captureException(error);
    });
  }
};

export const log = { trace, debug, info, display, warn, error };
