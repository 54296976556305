import { useEffect } from 'react';

import * as Xhr from '@/util/xhr';

export interface AnalyticsEventPayload {
  interactionType: string;
  eventCategory: string;
  eventAction: string;
  eventLabel?: string;
  eventValue?: number;
  portalAccountId?: string;
  data?: any;
}

const isProductionBundle = process.env.NODE_ENV === 'production';

const xhr = Xhr.getInstance();

export const ANALYTICS_URL = '/api/analytics/v1';
export const HOVER_INTERACTION_THROTTLE_MS = 5000;

export function trackInteraction(eventPayload: AnalyticsEventPayload): void {
  if (isProductionBundle)
    xhr.fetchJson(`${ANALYTICS_URL}/event`, { method: 'POST', body: JSON.stringify({ ...eventPayload }) }).catch(() => {});
}

export function trackClickInteraction(eventPayload: Omit<AnalyticsEventPayload, 'interactionType'>): void {
  trackInteraction({ ...eventPayload, interactionType: 'click' });
}

export function trackHoverInteraction(eventPayload: Omit<AnalyticsEventPayload, 'interactionType'>): void {
  trackInteraction({ ...eventPayload, interactionType: 'hover' });
}

export function trackPageView(): void {
  typeof window !== 'undefined' && window._epicTracking?.trackEvent('PageView');
}

export function useTrackPageView(): void {
  if (isProductionBundle)
    useEffect(() => {
      trackPageView();
    }, []);
}
