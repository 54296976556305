export const MAX_USERNAME_LENGTH = 50;
export const MAX_TEAMS_PER_ACCOUNT = 20;

export const LOGOUT_URL = '/auth/logout';
export const LOGIN_URL = '/auth/login';

export const PUBLISH_BRANDS_ACCESS_ROLE = 'owner';

export const ALERTS_URL = '/api/creator/v1/alerts';

export const COMPONENTS_DOMAIN = 'https://components.unrealengine.com';

export const PUBLISHING_DOCUMENTATION_URL =
  'https://dev.epicgames.com/documentation/en-us/fortnite-creative/managing-your-creator-profile-in-fortnite-creative';
export const ABOUT_RATING_URL = 'https://www.globalratings.com/about.aspx';
export const IARC_RATING_ONLINE_PRIVACY_URL = 'https://web.iarcservices.com/External/PrivacyPolicy';
export const IARC_OVERVIEW_FAQ_URL =
  'https://dev.epicgames.com/documentation/en-us/fortnite-creative/iarc-overview-and-faqs-in-fortnite-creative';
export const FORTNITE_CONTENT_GUIDELINES_PATH = '/news/fortnite-island-creator-rules';
export const MAX_EMAIL_LENGTH = 120;
export const GETTING_STARTED_UEFN = 'https://dev.epicgames.com/community/fortnite/getting-started/uefn';
export const CREATOR_RULES_PATH = '/news/fortnite-creative-creator-content-rules-and-guidelines';
export const ATTRIBUTIONS_LEARN_MORE_URL =
  'https://dev.epicgames.com/documentation/en-us/fortnite-creative/sourcing-attribution-in-fortnite-creative';

export const MONETIZATION_URL = '/news/introducing-the-creator-economy-2-0';

export const EPIC_MESSAGING_PATH = '/account/messaging';

export const ACTIVE_ISLAND_PROGRAM = {
  active: true,
  enrolled: true,
  needsToEnroll: false,
  resumeEnroll: false,
};

export const PEP_PUBLIC_URL = '/api/creator/v1/public-url';

export const FAQ_403_PAGE_PATH =
  '/help/en-US/fortnite-c5719335176219/competitive-c5719359866267/why-do-i-keep-receiving-a-403-error-message-when-trying-to-take-my-tax-interview-a5720297877019';
export const SORT_PROJECTS_BY_KEY = 'SORT_PROJECTS_BY';
export const SHOW_ARCHIVED_PROJECTS_COOKIE_KEY = 'SHOW_ARCHIVED_PROJECTS';

export const IP_PARTNER_FEE_SCHEDULE_URL = '/news/ip-partner-fee-schedule';

export const ANALYTICS_DEVICE_DOCUMENTATION =
  'https://create.fortnite.com/news/get-play-stats-with-the-analytics-device-for-fortnite-creative-and-uefn';
