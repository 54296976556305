import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { HEADER_HEIGHT } from '@/components/common/main.layout.styles';

import type { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    arrowBack: {
      marginRight: '20px',
    },
    buttonBack: {
      color: theme.palette.shade4,
      paddingLeft: theme.spacing(6.8),
      '& > span': {
        marginRight: '20px!important',
      },
      '&:hover, &:active': {
        backgroundColor: 'transparent',
      },
    },
    darkModeSwitcher: {
      display: 'flex !important',
      alignItems: 'center',
      '&:after': {
        content: '""',
        display: theme.palette.mode === 'light' ? 'block' : 'none',
        backgroundColor: theme.palette.shade2,
        height: theme.spacing(2.8),
        width: theme.spacing(2.8),
        borderRadius: theme.spacing(2),
        marginLeft: theme.spacing(0.4),
      },
      '&:before': {
        content: '""',
        display: theme.palette.mode === 'dark' ? 'block' : 'none',
        marginLeft: theme.spacing(0.4),
        height: theme.spacing(2.8),
        width: theme.spacing(2.8),
        borderRadius: '50%',
        boxShadow: `-4.5px 0 0 0 ${theme.palette.shade2}`,
        marginRight: theme.spacing(-0.8),
      },
    },
    slidingPanel: {
      background: theme.palette.shade7,
      position: 'fixed',
      top: HEADER_HEIGHT,
      right: 0,
      bottom: 0,
      width: '345px',
      transform: 'translate(100%, 0)',
      transition: '0.16s linear',
      overflowY: 'auto',
      '&.opened': {
        transform: 'translate(0, 0)',
      },
    },
    slidingPanelList: {
      paddingTop: theme.spacing(3.6),
      paddingBottom: theme.spacing(3.6),
    },
    slidingPanelItem: {
      paddingTop: theme.spacing(3.6),
      paddingBottom: theme.spacing(3.6),
    },
    slidingPanelLink: {
      color: theme.palette.shade2,
      display: 'block',
      padding: `${theme.spacing(0, 0, 0, 11.6)}!important`,
      textDecoration: 'none',
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'uppercase',
      minHeight: '20px!important',
      height: 'auto!important',
    },
  }),
);

export default useStyles;
