export const VK_ID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;

export const BLOCK_PROMOTE_BUILD_CODE_SYSMETA_KEY = 'block_promote_build_code';

export const PROJECT_KIND_ISLAND = 'Island';
export const PROJECT_KIND_LIBRARY = 'Library';

/** project meta that records whether a project is being developed in UEFN or FNC. */
export const DATA_AUTHORITY = 'dataAuthority';

/** project meta that records a project's association to an FNC island if applicable. */
export const ORIGINATING_ISLAND_ID = 'originating_island_id';
