import { ProfileIcon } from '@epic-ui/icons';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { clsx } from 'clsx';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import { canAccess } from '@creator-portal/common/auth';
import { PERMISSION } from '@creator-portal/common/permissions/constants';

import LocaleSwitcher from '@/components/common/locale-switcher.component';
import useStyles from '@/components/common/main.layout.styles';
import Button from '@/components/ui/Button';

import { useAuthSession } from '@/hooks/useAuthSession';
import { useCreatorProgramStatus } from '@/hooks/useCreatorProgramStatus';

interface NavigationBottomMobileProps {
  slideOpener: () => void;
}

export default function navigationBottomMobile({ slideOpener }: NavigationBottomMobileProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const user = useAuthSession();
  const router = useRouter();

  const creatorProgramStatus = useCreatorProgramStatus();
  const isEnrollButtonVisible =
    canAccess(user, PERMISSION.ICP_FEATURE) &&
    !router.pathname.includes('/enroll') &&
    (creatorProgramStatus?.needsToEnroll || creatorProgramStatus?.resumeEnroll);

  const handleImpersonateAccount = () => {
    void router.push('/enroll');
  };
  return (
    <Box className={classes.wrapper}>
      {isEnrollButtonVisible && (
        <div>
          <Button onClick={handleImpersonateAccount} size="medium" className={classes.button}>
            {creatorProgramStatus?.resumeEnroll ? t('epic.ecp.header.cta-btn.continue-enrollment') : t('epic.ecp.header.cta-btn.enroll')}
          </Button>
        </div>
      )}
      <div className={classes.bottomBar}>
        <List component="nav">
          <ListItem
            button
            key="User"
            component="a"
            className={clsx(classes.navLeftOption, classes.navLeftOptionHover)}
            onClick={slideOpener}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <ProfileIcon className={clsx(classes.appBarIcon, classes.listItemIcon)} />
            </ListItemIcon>
            <ListItemText className={classes.navLeftItem}>{user?.displayName}</ListItemText>
          </ListItem>
        </List>
        <Box className={classes.switcherContainer}>
          <LocaleSwitcher />
        </Box>
      </div>
    </Box>
  );
}
