export const VALKYRIE_LINK_TYPE = 'valkyrie:application';
export const CREATIVE_ISLAND_TYPE = 'Creative:Island';
export const MODE_SET_TYPE = 'ModeSet';
export const BR_PLAYLIST_TYPE = 'BR:Playlist';
export const BR_TOURNAMENT_TYPE = 'BR:Tournament';
export const BR_ARENA_TYPE = 'BR:Arena';
export const SUB_GAME_TYPE = 'SubGame';

export const FIRST_PARTY_TYPES = [MODE_SET_TYPE, BR_PLAYLIST_TYPE, BR_TOURNAMENT_TYPE, BR_ARENA_TYPE, SUB_GAME_TYPE];
export const TEMP_IGNORE_OVERRIDE_LINKS = [
  'playlist_juno',
  'set_delmar_mrs_ranked',
  'set_delmar_casual',
  'playlist_pilgrimquickplay',
  'playlist_fmclubisland',
];

export const FNC = 'FNC';
export const UEFN = 'UEFN';

export const LINKCODE_AND_VERSION_REGEX = new RegExp('^(\\d\\d\\d\\d-\\d\\d\\d\\d-\\d\\d\\d\\d)(\\??v=?(\\d+))?$');

export enum DISCOVERY_INTENT {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
  DISABLED = 'DISABLED',
}

export enum LINK_STATE {
  LIVE = 'LIVE',
  STAGED = 'STAGED',
}

export const ValkyrieLinkType = 'valkyrie:application';
export const CreativeIslandType = 'Creative:Island';

export const CONTENT_LINK_TYPEKEY = 'contentLink';
export const CONTENT_LINK_CODE_COMPONENT_NAME = 'linkCode';
export const CONTENT_LINK_TITLE_COMPONENT_NAME = 'linkTitle';
export const CONTENT_LINK_TAGLINE_COMPONENT_NAME = 'linkTagline';
export const CONTENT_LINK_INTRODUCTION_COMPONENT_NAME = 'linkIntroduction';
export const CONTENT_LINK_THUMBNAIL_COMPONENT_NAME = 'linkThumbnail';
export const CONTENT_LINK_SQUARE_THUMBNAIL_COMPONENT_NAME = 'linkSquareThumbnail';
export const CONTENT_LINK_LOBBY_BACKGROUND_COMPONENT_NAME = 'linkLobbyBackground';
export const CONTENT_LINK_TRAILER_COMPONENT_NAME = 'linkTrailer';
export const CONTENT_LINK_ATTRIBUTION_COMPONENT_NAME = 'linkAttribution';

export enum LINK_CATEGORY {
  LEGO = 'LEGO',
  ROCKET_RACING = 'Rocket Racing',
  FALL_GUYS = 'Fall Guys',
}

export enum GAME_FEATURE {
  // beanstalkgfs = Fall Guys
  FALL_GUYS = 'beanstalkgfs',
  // limegfs = LEGO
  LEGO = 'limegfs',
  // delmargfs = Delmar (Rocket Racing)
  DELMAR = 'delmargfs',
  TMNT = 'meltedirongfs',
}
