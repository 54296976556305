import { useAuthSession } from '@/hooks/useAuthSession';

import type { AccountPolicy } from '@creator-portal/common/types';

/**
 * Check to see if a policy exists on the current account session.
 * @param policy the policy to check.
 */
export function useHasAccountPolicy(policy: AccountPolicy): boolean {
  const session = useAuthSession();

  return session ? session.policies.indexOf(policy) !== -1 : false;
}
