import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
  const isRTL = theme.direction === 'rtl';

  return createStyles({
    textField: {
      borderRadius: theme.spacing(1),
      width: '100%',
      '& .MuiInputBase-root': {
        backgroundColor: theme.palette.shade7,
      },
      '& .MuiFormHelperText-root': {
        textAlign: isRTL ? 'right' : 'left',
      },
      '& .MuiInputBase-adornedStart': {
        '& textarea, input': {
          [isRTL ? 'paddingRight' : 'paddingLeft']: '0 !important',
        },
      },
    },
    countAdornment: {
      fontSize: theme.typography.pxToRem(12),
    },
    startAdornment: {
      paddingLeft: isRTL ? 'unset' : theme.spacing(4),
      paddingRight: isRTL ? theme.spacing(4) : 'unset',
      lineHeight: theme.typography.pxToRem(20),
    },
    limitError: {
      '& .MuiInputAdornment-root > div': {
        color: theme.palette.commonRedGradient1,
      },
    },
  });
});

export default useStyles;
