import { FC } from 'react';
import { CloseIcon } from '@epic-ui/icons';
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Trans, useTranslation } from 'next-i18next';

import CircularLoader from '@/components/common/loaders/circular-loader';
import RatingInfoboxComponent from '@/components/publishing/deploy-release-flow/steps/form-parts/rating-infobox';
import RatingsDisplayComponent from '@/components/publishing/deploy-release-flow/steps/form-parts/ratings-display';

import useStyles from './ratings-dialog.styles';

import type { RatingInfo } from '@/hooks/useRatingData';

export enum RatingStatus {
  SUCCESS = 'SUCCESS',
  MATURE = 'MATURE',
  PARTIAL = 'PARTIAL',
}

interface ProjectRatingsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  projectName: string;
  privateVersion: string;
  ratingData: RatingInfo;
}

const ProjectRatingsDialog: FC<ProjectRatingsDialogProps> = ({ isOpen, onClose, privateVersion, projectName, ratingData }): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { email, data, status, date, projectId, certificateId } = ratingData;
  const title = t('projects.modal.ratings.title', { projectName });
  const subTitle = (
    <Typography className={classes.subTitle}>
      <Trans
        i18nKey="projects.modal.ratings.subTitle"
        values={{ version: privateVersion }}
        components={{
          strong: <strong></strong>,
        }}
      />
    </Typography>
  );

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="lg" scroll="paper" className={classes.dialog}>
      <DialogTitle>
        {title}
        <IconButton aria-label={t('button.close')} onClick={onClose} className={classes.closeIcon}>
          <CloseIcon />
        </IconButton>
        {subTitle}
      </DialogTitle>
      <DialogContent className={classes.content}>
        {email && status ? (
          <div className={classes.scrollContainer}>
            <div className={classes.contentBlock}>
              <Typography component="h3" className={classes.contentHeader}>
                {t('publish.stepper.sub-header.rating.received')}
              </Typography>
              <RatingInfoboxComponent date={date} status={status} />
            </div>
            <div className={classes.contentBlock}>
              <RatingsDisplayComponent email={email} status={status} data={data} certificateId={certificateId} projectId={projectId} />
            </div>
          </div>
        ) : (
          <div className={classes.loader}>
            <CircularLoader cssClassName="loader-after-list" />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ProjectRatingsDialog;
