'use client';

import { useState } from 'react';
import { InfoIcon, RetryIcon } from '@epic-ui/icons';
import { Alert, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { FallbackProps, ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import Dialog, { DialogType } from '@/components/ui/Dialog';

import useStyles from './error-boundary.styles';

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [openDetailsModal, setOpenDetailsModal] = useState(false);

  return (
    <>
      <Alert
        severity="error"
        action={
          <IconButton size="small" onClick={resetErrorBoundary}>
            <RetryIcon />
          </IconButton>
        }
      >
        <div className={classes.errorTitle}>
          <Typography variant="body1">{t('error-description')}</Typography>
          <IconButton size="small" onClick={() => setOpenDetailsModal(true)}>
            <InfoIcon className={classes.errorDetailsIcon} />
          </IconButton>
        </div>
      </Alert>
      <Dialog
        open={openDetailsModal}
        dialogType={DialogType.ERROR}
        title={t('error-title')}
        description={error?.message}
        onClose={() => setOpenDetailsModal(false)}
      />
    </>
  );
};

interface ErrorBoundaryProps {
  children: React.ReactNode;
}
export const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }: ErrorBoundaryProps) => (
  <ReactErrorBoundary FallbackComponent={ErrorFallback}>{children}</ReactErrorBoundary>
);
