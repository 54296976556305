import { BASE_64_ENCODED_TRANSPARENT_PIXEL } from '@/config/media/constants';

import type { Image, ImgLimit } from '@creator-portal/common/config/media.config';

const BYTES_IN_GB = 1024 * 1024 * 1024;

const FALLBACK_IMAGE = {
  src: `data:image/png;base64,${BASE_64_ENCODED_TRANSPARENT_PIXEL}`,
  width: 1,
  height: 1,
};

export function getFileExtensionFromName(name: string): string {
  return name.substring(name.lastIndexOf('.') + 1);
}

export const getFileNameWithoutExtension = (name: string): string => name.substring(0, name.lastIndexOf('.'));

export const ensureFileExtensionInLowerCase = (file: File): File => {
  const fileName = getFileNameWithoutExtension(file.name);
  let fileExtension = getFileExtensionFromName(file.name);
  const isOriginalFileExtensionInLowerCase = fileExtension === fileExtension.toLowerCase();

  if (isOriginalFileExtensionInLowerCase) return file;

  fileExtension = fileExtension.toLowerCase();

  return new File([file], `${fileName}.${fileExtension}`, {
    type: file.type,
    lastModified: file.lastModified,
  });
};

export const isVideoFileSizeExceeded = (size: number, maxContentLength: number): boolean => size > maxContentLength;

export const isMediaFileExtensionNotValid = (fileName: string, approvedFormats: string[]): boolean => {
  const fileExtension = getFileExtensionFromName(fileName);

  return !approvedFormats.some((extension) => extension === fileExtension);
};

export const isMediaFileWeightOrSizeExceedsLimit = (file: File, imgLimit: ImgLimit): Promise<boolean> => {
  return new Promise((resolve) => {
    const imgWeightLimitInBytes = imgLimit.weightInMb * 1024 * 1024;
    if (file.size > imgWeightLimitInBytes) {
      resolve(true);
    } else {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = (e) => {
        if (!e.target) {
          resolve(true); // If target is null, we assume the file exceeds limit.
          return;
        }

        img.onload = () => {
          if (img.width > imgLimit.width || img.height > imgLimit.height) {
            resolve(true);
          } else {
            resolve(false);
          }
        };

        img.src = e.target.result as string;
      };

      reader.readAsDataURL(file);
    }
  });
};

export const isMediaNameLengthExceeded = (fileName: string, mediaNameMaxLength: number): boolean => {
  const fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, '');
  const currentMediaNameLength = fileNameWithoutExtension.length;

  return mediaNameMaxLength < currentMediaNameLength;
};

export function getFiletype(type: string): string {
  return type.substring(0, type.indexOf('/'));
}

export const getMediaCompleteUrl = (mediaId: string): string => `/api/media/v1/${encodeURIComponent(mediaId)}/complete`;

export function getGb(size: number): number {
  return parseInt(String(size / BYTES_IN_GB));
}

export const getImage = (image: Image): Image => {
  const { src, width, height } = image;

  if (src && width && height) return image;

  return FALLBACK_IMAGE;
};
