export const MEDIA_URL = '/api/media/v1/';
export const MEDIA_UPLOAD_URL = '/api/media/v1/upload';
export const PROJECTS_URL = '/api/vk/v1/projects';

export const BASE_64_ENCODED_TRANSPARENT_PIXEL =
  'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+P+/HgAFhAJ/wlseKgAAAABJRU5ErkJggg==';

export const MIN_IN_SECONDS = 60;
export const HOUR_IN_SECONDS = 3600;
export const DAY_IN_SECONDS = HOUR_IN_SECONDS * 24;
export const DAY_IN_MISEC = DAY_IN_SECONDS * 1000;

export const TWO_TO_TENTH_POWER = 1024;
export const MAX_FILE_SIZE = TWO_TO_TENTH_POWER * TWO_TO_TENTH_POWER * TWO_TO_TENTH_POWER * 5; // 5GB

export const TIME_MEASURE = {
  seconds: 's',
  minutes: 'min',
  hours: 'h',
  days: 'd',
};

export const SIZE_MEASURE = {
  byte: 'b',
  kb: 'kb',
  mb: 'mb',
  gb: 'gb',
};

export enum UploadErrorTypes {
  UNSUPPORTED_MEDIA = 'unsupported-media',
  SIZE_LIMIT = 'size-limit',
  UNSUPPORTED_SUBTITLE = 'unsupported-subtitle',
  MEDIA_NAME_LENGTH_EXCEEDED = 'media-name-length-exceeded',
}

export { TEAM_PROFILE_IMAGE_NAME, IMG_ACCEPTED_FORMATS, FILE_TYPES } from '@creator-portal/common/media/constants';
