import { FC } from 'react';
import { IconButton, InputAdornment, TextField, useTheme } from '@mui/material';

import EditDarkModeIcon from '@/components/teams/edit-svg-dark-mode';
import EditLightModeIcon from '@/components/teams/edit-svg-light-mode';

import { withMuiRTL } from '@/hoc/withMuiRTL';

import useStyles from './prefilled-input.styles';

interface PrefilledInputComponentProps {
  hasEditButton?: boolean;
  openEditDialog?: () => void;
  label: string;
  value: string | null;
}

const PrefilledInputComponent: FC<PrefilledInputComponentProps> = ({
  hasEditButton = false,
  openEditDialog,
  label,
  value,
}): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isDarkMode = theme?.palette?.mode === 'dark';
  const editIcon = isDarkMode ? <EditDarkModeIcon /> : <EditLightModeIcon />;

  return (
    <TextField
      label={label}
      value={value}
      disabled={true}
      className={classes.textField}
      InputProps={{
        endAdornment: hasEditButton ? (
          <InputAdornment position="end" className={classes.inputAdornment}>
            <IconButton className={classes.editHeaderButton} onClick={openEditDialog}>
              {editIcon}
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
    />
  );
};

export default withMuiRTL(PrefilledInputComponent);
