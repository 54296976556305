import { WorkSansFont } from '@/components/common/main.layout.styles';

export const typography = {
  fontSize: 14,
  lineHeight: '20px',
  letterSpacing: 0.2,
  h1: {
    fontFamily: WorkSansFont,
    fontWeight: 700,
    fontSize: 30,
    lineHeight: '35px',
    letterSpacing: -0.3,
    '@media screen and (min-width: 1024px)': {
      fontSize: 44,
      lineHeight: '45px',
      letterSpacing: -0.5,
    },
  },
  h4: {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '25px',
    letterSpacing: -0.2,
    '@media screen and (min-width: 1024px)': {
      fontSize: 15,
      lineHeight: '25px',
    },
  },
};
