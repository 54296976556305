import { AppError } from '../app-error';
import { PERMISSION } from '../permissions/constants';

import type { AuthSession, ResourcePermission } from './types';

export const NO_AUTH_SESSION: AuthSession = { clientId: '', accountId: '', authAccountId: '', displayName: '', claims: [], policies: [] };

export enum AuthAction {
  CREATE = 1,
  READ = 2,
  UPDATE = 4,
  DELETE = 8,
}

export interface ClaimsToken {
  accountId: string;
  clientId: string;
  claims: ReadonlyArray<ResourcePermission>;
}

export function permissionExists(
  permissions: Readonly<ResourcePermission[]>,
  resource: string,
  action: AuthAction = AuthAction.READ,
): boolean {
  // check for exact matches
  for (const claim of permissions) {
    if (claim.resource.endsWith(':*')) {
      // "foo:*"" matches "foo:bar" but not "foo"
      if (resource.startsWith(claim.resource.slice(0, -1))) {
        if (((claim.action & action) as AuthAction) === action) return true;
      }
    } else {
      // exact match
      if (claim.resource === resource) {
        if (((claim.action & action) as AuthAction) === action) return true;
      }
    }
  }

  return false;
}

/** Returns true if the supplied auth session can access the described resource. */
export function canAccess(session: ClaimsToken | undefined, resource: string, action: AuthAction = AuthAction.READ): boolean {
  if (!session) return false;

  if (session === NO_AUTH_SESSION) {
    console.warn('A call to canAccess was made against NO_AUTH_SESSION... this may be a mistake.');

    return false;
  }

  return permissionExists(session.claims, resource, action);
}

export function canAdminAccess(session: ClaimsToken | undefined, resource: string, action: AuthAction = AuthAction.READ): boolean {
  if (canAccess(session, PERMISSION.ADMIN, AuthAction.READ) && canAccess(session, PERMISSION.ADMIN, AuthAction.UPDATE)) return true;

  return canAccess(session, resource, action);
}

function authActionToString(action: AuthAction) {
  const vals: string[] = [];
  if (action & AuthAction.CREATE) vals.push('CREATE');
  if (action & AuthAction.READ) vals.push('READ');
  if (action & AuthAction.UPDATE) vals.push('UPDATE');
  if (action & AuthAction.DELETE) vals.push('DELETE');

  return vals.join('+') || 'INVALID';
}

/** throws 403 error if the supplied auth session cannot access the described resource. */
export function requireAccess(
  session: ClaimsToken | undefined,
  resource: string,
  action: AuthAction = AuthAction.READ,
): asserts session is AuthSession {
  if (!session) throw new AppError(401, 'auth_required', 'Authentication is required.');

  if (!canAccess(session, resource, action))
    throw new AppError(
      403,
      'forbidden',
      `Missing required permission '${resource} ${authActionToString(action)}' (aid=${session.accountId},cid=${session.clientId})`,
    );
}

export function requireAdminAccess(
  session: ClaimsToken | undefined,
  resource: string,
  action: AuthAction = AuthAction.READ,
): asserts session is AuthSession {
  if (!session) throw new AppError(401, 'auth_required', 'Authentication is required.');

  if (!canAdminAccess(session, resource, action))
    throw new AppError(
      403,
      'forbidden',
      `Missing required permission '${resource} ${authActionToString(action)}' (aid=${session.accountId},cid=${session.clientId})`,
    );
}

export function requireElevatedAccess(session: ClaimsToken | undefined): asserts session is AuthSession {
  if (!session) throw new AppError(401, 'auth_required', 'Authentication is required.');

  if (!canAccess(session, PERMISSION.ADMIN_FTE))
    throw new AppError(403, 'forbidden', `Requires elevated admin permission' (aid=${session.accountId},cid=${session.clientId})`);
}
