import { alpha } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import type { Theme } from '@mui/material/styles';

const BOTTOM_LANG_BAR_HEIGHT = 56;

const useStyles = makeStyles((theme: Theme) => {
  const isRTL = theme.direction === 'rtl';

  return createStyles({
    additionalMarginNavSection: {
      '&.resourcesLinks': {
        [theme.breakpoints.down('lg')]: {
          marginBottom: `${theme.spacing(20)} !important`,
        },
      },
    },
    navSection: {
      '&.resourcesLinks': {
        marginBottom: theme.spacing(6),
        [theme.breakpoints.down('lg')]: {
          paddingBottom: theme.spacing(BOTTOM_LANG_BAR_HEIGHT / 5),
        },
        [theme.breakpoints.up('md')]: {
          marginTop: 'auto',
        },
        '& .MuiButtonBase-root': {
          '&.Mui-selected': {
            '&:hover': {
              background: alpha(theme.palette.shade6, 0.7),
            },
          },
        },
      },
      '&.teamFeaturesLinks': {
        '& .MuiButtonBase-root': {
          '&:hover': {
            background: `${alpha(theme.palette.shade6, 0.7)} !important`,
          },
        },
      },
      '&.brandLinks': {
        '& .MuiButtonBase-root': {
          '&:hover': {
            background: `${alpha(theme.palette.shade6, 0.7)} !important`,
          },
        },
      },
    },
    navSectionHeading: {
      textAlign: isRTL ? 'right' : 'left',
      paddingLeft: theme.spacing(16),
      fontSize: theme.typography.pxToRem(9),
      lineHeight: theme.typography.pxToRem(15),
      letterSpacing: '1px',
      textTransform: 'uppercase',
      color: theme.palette.shade2,
      marginBottom: theme.spacing(2.4),
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(1.6),
      },
      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(0, 5.6),
      },
      '.project-sub-nav-visible &': {
        display: 'none',
      },
    },
    divider: {
      backgroundColor: theme.palette.shade4,
      margin: theme.spacing(2.8, 0, 3.4),
      '.resourcesLinks &': {
        marginBottom: theme.spacing(2.8),
      },
      '.project-sub-nav-visible &': {
        margin: theme.spacing(3.2, 0),
      },
    },
  });
});

export default useStyles;
