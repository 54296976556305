import * as React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@mui/material';
import { Trans, useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';

import { MAX_IARC_EMAIL_LENGTH } from '@creator-portal/common/publishing/constants';

import { IARC_RATING_ONLINE_PRIVACY_URL } from '@/config/common/constants';
import { getPrivacyUrl } from '@/config/common/functions';

import { createSchemaForEditIarcEmail } from '@/services/publishing/publishing-validations';

import CommonLink from '@/components/common/common-link';
import { RATING_FIELDS } from '@/components/publishing/deploy-release-flow/stepper-types';
import Dialog, { DialogType } from '@/components/ui/Dialog';
import TextField from '@/components/ui/TextField';

import useStyles from './edit-iarc-email-dialog.styles';

export interface IarcEmailValues {
  iarcEmail: string;
}

interface EditIarcEmailDialogProps {
  onSubmit: (newEmail: string) => void;
  onClose: () => void;
  email?: string;
  isOpen: boolean;
  isLoading: boolean;
}

const EditIarcEmailDialog: React.FC<EditIarcEmailDialogProps> = ({ email = '', isOpen, onSubmit, onClose, isLoading }): JSX.Element => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const schema = React.useMemo(() => createSchemaForEditIarcEmail(t), [t]);
  const {
    setValue,
    trigger,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<IarcEmailValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      [RATING_FIELDS.iarcEmail]: email,
    },
  });
  const { iarcEmail: iarcEmailState } = watch();
  const handleEmailChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(RATING_FIELDS.iarcEmail, e.target.value);
    await trigger(RATING_FIELDS.iarcEmail);
  };
  const handleEmailSubmit = (): void => {
    onSubmit(iarcEmailState);
  };
  React.useEffect(() => {
    if (isOpen && !isLoading) {
      setValue(RATING_FIELDS.iarcEmail, email);
    }

    return () => clearErrors();
  }, [isLoading, email, isOpen]);
  const isErrorOccur = !!errors.iarcEmail?.message;
  const isNotEqualWithDefaultValue = iarcEmailState?.trim() !== email;

  const footerContent = (
    <Typography component="p" className={classes.footerContent}>
      <Trans
        i18nKey="edit-iarc-email-dialog.footer-text"
        components={{
          a: <CommonLink href={IARC_RATING_ONLINE_PRIVACY_URL} target="_blank" rel="noopener noreferrer" />,
          a2: <CommonLink href={getPrivacyUrl(i18n.language)} target="_blank" rel="noopener noreferrer" />,
        }}
      />
    </Typography>
  );

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      dialogType={DialogType.INFO}
      title={t('edit-iarc-email-dialog.title')}
      footerContent={footerContent}
      primaryButtonText={t('button.save')}
      secondaryButtonText={t('button.cancel')}
      onSecondaryButton={onClose}
      onPrimaryButton={handleEmailSubmit}
      isSecondaryButtonDisabled={isLoading}
      isPrimaryButtonDisabled={isLoading || isErrorOccur || !isNotEqualWithDefaultValue}
    >
      <Typography component="p" className={classes.description}>
        {t('edit-iarc-email-dialog.description')}
      </Typography>
      <div className={classes.textFieldBlock}>
        <TextField
          multiline
          maxLength={MAX_IARC_EMAIL_LENGTH}
          error={!!errors[RATING_FIELDS.iarcEmail]?.message}
          helperText={errors[RATING_FIELDS.iarcEmail]?.message}
          value={iarcEmailState}
          name={RATING_FIELDS.iarcEmail}
          label={t('edit-iarc-email-dialog.input-label')}
          onChange={handleEmailChange}
        />
      </div>
    </Dialog>
  );
};

export default EditIarcEmailDialog;
