'use client';

import { createContext } from 'react';

import type { CreatorProgramStatusResponse } from '@creator-portal/common/types';
import type { ReactNode } from 'react';

interface ContextProps {
  children: ReactNode;
  value?: CreatorProgramStatusResponse;
}

export const CreatorProgramStatusContext = createContext<CreatorProgramStatusResponse | undefined>(undefined);

/** React context for providing CreatorProgramStatusResponse */
export function CreatorProgramStatusProvider({ value, children }: ContextProps): JSX.Element {
  return <CreatorProgramStatusContext.Provider value={value}>{children}</CreatorProgramStatusContext.Provider>;
}
