import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) =>
  createStyles({
    ratingsDisplay: {
      background: theme.palette.shade7,
      padding: theme.spacing(4, 3),
    },
    textFieldsBlock: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr 1fr',
      gridGap: '20px',
      marginBottom: theme.spacing(3),

      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '1fr',
      },
    },
  }),
);

export default useStyles;
