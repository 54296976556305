import { alpha } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import getPaletteModeStyles from '@/util/getPaletteModeStyles';

import type { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => {
  const isRTL = theme.direction === 'rtl';

  return createStyles({
    userCard: {
      position: 'relative',
      backgroundColor: getPaletteModeStyles(theme.palette.mode)(theme.palette.shade7, theme.palette.shade6),
      borderRadius: '4px',
      boxShadow: getPaletteModeStyles(theme.palette.mode)(`1px 1px 6px ${alpha(theme.palette.shade0, 0.2)}`, 'none'),
      cursor: 'pointer',
      flex: '0 0 auto',
      margin: theme.spacing(0, 4.8, 6),
      direction: theme.direction,
      textAlign: isRTL ? 'right' : 'left',
      border: 'none',
      [isRTL ? 'borderRight' : 'borderLeft']: '6px solid',
      [theme.breakpoints.up('lg')]: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
      },
      '&:hover': {
        boxShadow: getPaletteModeStyles(theme.palette.mode)(`1px 1px 10px ${alpha(theme.palette.shade0, 0.4)}`, 'none'),
        backgroundColor: getPaletteModeStyles(theme.palette.mode)(theme.palette.shade7, alpha(theme.palette.shade6, 0.8)),
        '& .MuiTypography-root': {
          '&::before': {
            borderBottomColor: theme.palette.commonBlueGradient2,
          },
        },
      },
      '.project-sub-nav-visible &': {
        border: 0,
        marginLeft: theme.spacing(3.7),
        marginRight: theme.spacing(3.7),
        marginBottom: theme.spacing(5.2),
        minWidth: 35,
      },
    },
    userCardContent: {
      padding: '0 !important',
      display: 'block',
      '.project-sub-nav-visible &': {
        display: 'none',
      },
    },
    teamNameLetterWrapper: {
      display: 'none',
      width: 34.5,
      height: 34.5,
      textAlign: 'center',
      '.project-sub-nav-visible &': {
        display: 'block',
      },
    },
    userData: {
      position: 'relative',
      padding: isRTL ? theme.spacing(3.6, 2.6, 3.6, 5.6) : theme.spacing(3.6, 5.6, 3.6, 2.6),
      '.project-sub-nav-visible &': {
        padding: 0,
      },
    },
    teamNameLetter: {
      display: 'none',
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(24),
      lineHeight: theme.typography.pxToRem(34.5),
      color: theme.palette.blue,
      '.project-sub-nav-visible &': {
        display: 'block',
      },
    },
    arrowDown: {
      border: `solid ${theme.palette.shade3}`,
      display: 'inline-block',
      padding: theme.spacing(0.5),
      transform: 'rotate(45deg)',
      position: 'absolute',
      top: '40%',
      borderWidth: theme.spacing(0, 0.3, 0.3, 0),
      [isRTL ? 'left' : 'right']: theme.spacing(2.4),
    },
    teamName: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(14),
      display: 'block',
      color: theme.palette.blue,
      marginTop: theme.spacing(1),
      lineHeight: theme.typography.pxToRem(15),
      wordBreak: 'break-word',
    },
    label: {
      display: 'block',
      marginBottom: theme.spacing(-0.8),
      textTransform: 'uppercase',
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(8),
      color: theme.palette.shade2,
      letterSpacing: '0.5px',
    },
    ownerColor: {
      borderColor: theme.palette.commonBlueGradient2,
    },
    adminColor: {
      borderColor: theme.palette.commonOrange,
    },
    publisherColor: {
      borderColor: theme.palette.commonPurple,
    },
    collaboratorColor: {
      borderColor: theme.palette.shade4,
    },
    playtesterColor: {
      borderColor: theme.palette.commonGreen,
    },
  });
});

export default useStyles;
