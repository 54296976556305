import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { clsx } from 'clsx';
import { useTranslation } from 'next-i18next';

import useStyles from '@/components/common/navigation/team-card.styles';

import { Roles } from '@/util/teams';

import type { TeamMembershipRole } from '@creator-portal/common/types';

const TeamCard = ({
  activeTeamName,
  teamMemberRole,
  onClick,
  memberCount,
}: {
  activeTeamName: string;
  teamMemberRole?: TeamMembershipRole;
  memberCount?: number;
  onClick: () => void;
}): JSX.Element | null => {
  const classes = useStyles();
  const { t } = useTranslation();

  const RoleColorClasses = {
    [Roles.COLLABORATOR]: classes.collaboratorColor,
    [Roles.ADMIN]: classes.adminColor,
    [Roles.OWNER]: classes.ownerColor,
    [Roles.PUBLISHER]: classes.publisherColor,
  };

  if (!teamMemberRole) return null;

  return (
    <Card onClick={onClick} variant="outlined" className={clsx(classes.teamCard, RoleColorClasses[teamMemberRole])}>
      <CardContent className={classes.teamCardContent}>
        <div className={classes.teamName}>{activeTeamName}</div>
        {memberCount ? (
          <div className={classes.teamCardFooter}>
            <div className={classes.teamMembersLabel}>{t('modal.switch-team.members')}</div>
            <div className={classes.teamStatistic}>{memberCount < 0 ? 0 : memberCount}</div>
          </div>
        ) : null}
      </CardContent>
    </Card>
  );
};

export default React.memo(TeamCard);
