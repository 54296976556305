export const tooltipsOverrides = {
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        boxShadow: '2px 2px 6px #00000059',
        color: '#141414B8',
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: '0.2px',
        padding: '18px 35px',
        '.ar &': {
          direction: 'rtl',
        },
      },
    },
  },
};
