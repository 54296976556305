import { defaultTheme } from 'epic-games-nav-shared';

import type { LegalTagProps } from 'epic-games-footer/src/epic-games-footer.types';

const visibleToSpecific = 'visibleToSpecific' as LegalTagProps['visibility'];

export const theme = {
  ...defaultTheme,
  forwardIcon: 'fn-arrow-forward',
  palette: {
    ...defaultTheme.palette,
    text: {
      primary: '#ccc',
      primaryHover: '#fff',
    },
    background: {
      default: '#2a2a2a',
      paper: '#1f1f1f',
    },
    border: '#333',
    accent: {
      text: '#ff0',
      background: '#ff0',
      backgroundText: '#000',
      backgroundHover: '#f7f756',
    },
  },
  fonts: {
    default: 'OpenSans,sans-serif,arial',
  },
  easing: 'ease-out',
};

export const footerConfig = {
  socialLinks: [
    {
      id: 'twitter',
      href: 'https://x.com/FNCreate',
    },
    {
      id: 'discord',
      href: 'https://discord.gg/fortnitecreative',
    },
    {
      id: 'youtube',
      href: 'https://www.youtube.com/@fortnitecreate',
    },
    {
      id: 'instagram',
      href: 'https://www.instagram.com/fncreate',
    },
  ],
  legalTags: [
    {
      countryCodes: 'US,CA',
      visibility: visibleToSpecific,
      src: 'https://cdn2.unrealengine.com/esrb-eco-1535x904-c5306c23f44f.jpg',
    },
    {
      countryCodes: 'EUROPE',
      visibility: visibleToSpecific,
      src: 'https://cdn2.unrealengine.com/parentalguidance-black-en-295x456-648303502b1d.jpg',
    },
  ],
  newsLetterConfigs: {
    enableNewsLetterCMS: true,
    logo: 'https://cdn2.unrealengine.com/Fortnite%2Fsearch-for-survivors%2FsignupBanner-155x221-7d1f31411baf91e6cadf490c6f60f98a72b38b4c.png',
    loginRequired: false,
  },
  newLineCopyrights: false,
  epicPropertyName: 'fortnite',
  logos: [
    {
      id: 'eg',
    },
    {
      id: 'ue',
    },
    {
      id: 'pcGame',
    },
    {
      id: 'ps5-ps4',
    },
    {
      id: 'xbox-series',
    },
    {
      id: 'switch',
    },
    {
      id: 'android',
    },
  ],
  callToAction: {
    useCustomCallback: false,
  },
  hideDefaultTermsPolicy: false,
  enableNewsLetter: false,
  email: '',
};
