import { canAccess } from '../auth';
import { PERMISSION } from '../permissions/constants';
import { AuthSession } from '../types';

export function disableEnvironmentIarcRatings(
  config: { EPIC_ENV: Readonly<string>; EPIC_DEPLOYMENT: Readonly<string> },
  session?: AuthSession,
) {
  // IARC ratings should be disabled on all prod environments that aren't the main live environment
  const isNonLiveProd = config.EPIC_ENV === 'prod' && config.EPIC_DEPLOYMENT !== 'live';

  // unless the publishing account has a feature flag override, in which case you'll be allowed to publish IARC ratings on live-testing
  let disableEnvIarcRatings = isNonLiveProd;
  if (disableEnvIarcRatings && session) {
    disableEnvIarcRatings = !canAccess(session, PERMISSION.BYPASS_IARC_ENV_REQUIREMENT);
  }

  return disableEnvIarcRatings;
}

export const ALLOWED_RATING_CAP = [
  'ACB_AGE_G',
  'ACB_AGE_M',
  'ACB_AGE_PG',
  'ACB_AGE_NA',
  'ESRB_AGE_E',
  'ESRB_AGE_E10',
  'ESRB_AGE_T',
  'PEGI_AGE_3',
  'PEGI_AGE_7',
  'PEGI_AGE_12',
  'PEGI_AGE_PG',
  'CLASSIND_AGE_10',
  'CLASSIND_AGE_12',
  'CLASSIND_AGE_LIVRE',
  'USK_AGE_0',
  'USK_AGE_6',
  'USK_AGE_12',
  'GRAC_AGE_ALL',
  'GRAC_AGE_12',
  'GRAC_AGE_NA',
  'RUSSIA_AGE_0',
  'RUSSIA_AGE_6',
  'RUSSIA_AGE_12',
  'GEN_AGE_3',
  'GEN_AGE_7',
  'GEN_AGE_12',
];

export const LEGO_ALLOWED_RATING_CAP = [
  'ACB_AGE_G',
  'ACB_AGE_M',
  'ACB_AGE_PG',
  'ESRB_AGE_E',
  'ESRB_AGE_E10',
  'PEGI_AGE_3',
  'PEGI_AGE_7',
  'CLASSIND_AGE_10',
  'CLASSIND_AGE_LIVRE',
  'USK_AGE_0',
  'USK_AGE_6',
  'USK_AGE_12',
  'GRAC_AGE_ALL',
  'GRAC_AGE_12',
  'RUSSIA_AGE_0',
  'RUSSIA_AGE_6',
  'RUSSIA_AGE_12',
  'GEN_AGE_3',
  'GEN_AGE_7',
];

export const FALL_GUYS_ALLOWED_RATING_CAP = [
  'ACB_AGE_G',
  'ACB_AGE_M',
  'ACB_AGE_PG',
  'ESRB_AGE_E',
  'PEGI_AGE_3',
  'PEGI_AGE_7',
  'CLASSIND_AGE_10',
  'CLASSIND_AGE_12',
  'CLASSIND_AGE_LIVRE',
  'USK_AGE_0',
  'USK_AGE_6',
  'GRAC_AGE_ALL',
  'RUSSIA_AGE_0',
  'RUSSIA_AGE_6',
  'RUSSIA_AGE_12',
  'GEN_AGE_3',
  'GEN_AGE_7',
];
