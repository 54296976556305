const maybeICU = require('i18next-icu');

// Need to have run same file both on server and client.
const ICU = typeof maybeICU === 'function' ? maybeICU : maybeICU.default;

const DEFAULT_LOCALE = 'en-US';
const SUPPORTED_LOCALES = [DEFAULT_LOCALE, 'ar', 'de', 'es-ES', 'es-MX', 'fr', 'it', 'ja', 'ko', 'pl', 'pt-BR', 'ru', 'tr'];

/** @type {import('next-i18next').UserConfig} */
module.exports = {
  i18n: {
    defaultLocale: DEFAULT_LOCALE,
    locales: SUPPORTED_LOCALES,
    returnNull: false,
    returnEmptyString: false,
    ns: 'common',
    localePath: '../../packages/locales/src',
  },
  use: [new ICU()],
  serializeConfig: false,
};
