import { Box, Button, Divider } from '@mui/material';
import { clsx } from 'clsx';
import { useTranslation } from 'next-i18next';
import Image from 'next/legacy/image';

import { getImage } from '@/util/media';

import IconBackArrow from '@/styles/icons/icon-arrow-back.svg';

import useStyles from './sliding-panel-mobile.styles';
import UserAccountMenu from './user-account.component';

import type { FormEvent } from 'react';

export interface SlidingPanelMobileProps {
  isOpen: boolean;
  slideOpener: () => void;
  handleLogoutClick: (e: FormEvent) => void;
}

export default function SlidingPanelMobileComponent({ isOpen, slideOpener, handleLogoutClick }: SlidingPanelMobileProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box component="div" className={clsx(classes.slidingPanel, isOpen && 'opened')}>
      <Button variant="text" className={classes.buttonBack} onClick={slideOpener}>
        <Image src={getImage(IconBackArrow)} className={classes.arrowBack} layout="fixed" height="15" width="15" />
        {t('navbar.back')}
      </Button>
      <Divider />
      <UserAccountMenu handleLogoutClick={handleLogoutClick} />
    </Box>
  );
}
