import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import getPaletteModeStyles from '@/util/getPaletteModeStyles';

import type { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => {
  const isRTL = theme.direction === 'rtl';

  return createStyles({
    teamCard: {
      height: 97,
      position: 'relative',
      backgroundColor: theme.palette.shade6,
      borderRadius: '4px',
      [isRTL ? 'borderRight' : 'borderLeft']: '6px solid',
      boxShadow: getPaletteModeStyles(theme.palette.mode)(`1px 1px 6px ${theme.palette.shade3}`, 'none'),
      cursor: 'pointer',
      flex: '0 0 auto',
      border: 'none',
      margin: theme.spacing(0, 16, 6.6),
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
      },
      '&:hover': {
        boxShadow: getPaletteModeStyles(theme.palette.mode)(`1px 1px 10px ${theme.palette.shade3}`, 'none'),
        '& .MuiTypography-root': {
          '&::before': {
            borderBottomColor: theme.palette.commonBlueGradient2,
          },
        },
      },
    },
    teamCardContent: {
      position: 'relative',
      padding: theme.spacing(1.8, 2.6),
      paddingBottom: '9px !important',
      display: 'block',
      height: '100%',
    },
    teamName: {
      fontWeight: theme.typography.fontWeightBold,
      wordBreak: 'break-word',
      fontSize: theme.typography.pxToRem(14),
      height: 32,
      color: theme.palette.blue,
      marginTop: theme.spacing(0.9),
      marginBottom: theme.spacing(2.8),
      lineHeight: theme.typography.pxToRem(15),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
    },
    teamCardFooter: {
      borderTop: `1px solid ${theme.palette.shade4}`,
      paddingTop: theme.spacing(0.6),
    },
    teamMembersLabel: {
      fontSize: theme.typography.pxToRem(8),
      lineHeight: theme.typography.pxToRem(15),
      textTransform: 'uppercase',
      color: theme.palette.shade2,
      letterSpacing: '0.5px',
    },
    teamStatistic: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(10),
    },
    ownerColor: {
      borderColor: theme.palette.commonBlueGradient2,
    },
    adminColor: {
      borderColor: theme.palette.commonOrange,
    },
    publisherColor: {
      borderColor: theme.palette.commonPurple,
    },
    collaboratorColor: {
      borderColor: theme.palette.shade4,
    },
  });
});

export default useStyles;
