import { debounce } from 'lodash';

import { ModerationUrl } from '@/services/moderation/moderation-service';

import * as Xhr from '@/util/xhr';

const DEBOUNCE_TIME = 300;

enum ScreenResponseAction {
  Allow = 'allow',
  Nudge = 'nudge',
  Reject = 'reject',
}

export class ScreenApiValidaton {
  static getInstance() {
    if (!this.instance) {
      this.instance = new ScreenApiValidaton();
    }

    return this.instance;
  }

  private titleSafe: boolean;
  private taglineSafe: boolean;
  private introSafe: boolean[];
  private attributionSafe: { [key: string]: boolean };

  private xhr: Xhr.XhrService;

  private static instance: ScreenApiValidaton;

  constructor() {
    this.xhr = Xhr.getInstance();
    this.titleSafe = true;
    this.taglineSafe = true;
    this.introSafe = [true, true, true];
    this.attributionSafe = {
      title: true,
      author: true,
      license: true,
    };
  }

  isTitleSafe = () => this.titleSafe;
  isTaglineSafe = () => this.taglineSafe;
  isIntroSafe = (index: number) => this.introSafe[index];
  isAttributionSafe = (field: string) => this.attributionSafe[field];

  private screenText = (text: string) =>
    this.xhr.fetchJson<{ action: ScreenResponseAction }>(ModerationUrl.screenText(), {
      method: 'POST',
      body: JSON.stringify({ text }),
    });

  screenTitle = debounce(async (value: string, validate: () => void) => {
    const rsp = await this.screenText(value);

    this.titleSafe = true;

    if (rsp.success) {
      this.titleSafe = rsp.data.action !== ScreenResponseAction.Reject;
    }

    validate();
  }, DEBOUNCE_TIME);

  screenTagline = debounce(async (value: string, validate: () => void) => {
    const rsp = await this.screenText(value);

    this.taglineSafe = true;

    if (rsp.success) {
      this.taglineSafe = rsp.data.action !== ScreenResponseAction.Reject;
    }

    validate();
  }, DEBOUNCE_TIME);

  screenIntroduction = debounce(async (value: string, index: number, validate: () => void) => {
    const rsp = await this.screenText(value);

    this.introSafe[index] = true;

    if (rsp.success) {
      this.introSafe[index] = rsp.data.action !== ScreenResponseAction.Reject;
    }

    validate();
  }, DEBOUNCE_TIME);

  screenFullIntroduction = debounce(async (values: string[], validate: () => void) => {
    for (let i = 0; i < values.length; i++) {
      const rsp = await this.screenText(values[i]);
      this.introSafe[i] = true;
      if (rsp.success) {
        this.introSafe[i] = rsp.data.action !== ScreenResponseAction.Reject;
      }
    }

    validate();
  });

  screenAttribution = debounce(async (value: string, field: string, validate: () => void) => {
    const rsp = await this.screenText(value);

    this.attributionSafe[field] = true;

    if (rsp.success) {
      this.attributionSafe[field] = rsp.data.action !== ScreenResponseAction.Reject;
    }

    validate();
  }, DEBOUNCE_TIME);
}
