import { useContext } from 'react';

import { CreatorProgramStatusContext } from '@/contexts/creator-program-status.context';

import type { CreatorProgramStatusResponse } from '@creator-portal/common/types';

/**
 * Returns current CreatorProgramStatusResponse from react context api.
 * @type AssertNotUndefined removes undefined from return type definition.
 */
export function useCreatorProgramStatus<AssertNotUndefined extends boolean = false>(): AssertNotUndefined extends true
  ? CreatorProgramStatusResponse
  : CreatorProgramStatusResponse | undefined {
  return useContext(CreatorProgramStatusContext) as AssertNotUndefined extends true
    ? CreatorProgramStatusResponse
    : CreatorProgramStatusResponse | undefined;
}
