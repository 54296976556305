import { Box, useMediaQuery, useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { clsx } from 'clsx';
import { usePathname } from 'next/navigation';

import { canAccess } from '@creator-portal/common/auth';
import { PERMISSION } from '@creator-portal/common/permissions/constants';

import useStyles from '@/components/common/navigation/nav-section.styles';
import { BootstrapTooltip } from '@/components/common/tooltip.component';

import { useAuthSession } from '@/hooks/useAuthSession';
import { useCreatorProgramStatus } from '@/hooks/useCreatorProgramStatus';

import { NavLink, NavLinkItem } from './nav-link';

import type { Dispatch, SetStateAction } from 'react';

export interface NavSection {
  header?: string;
  links: NavLink[];
  cssClass: string;
  isVisible: boolean;
  dataTestId: string;
}

export const NavSectionItem = ({
  navSection,
  index,
  setNewExternalRoute,
  onClick,
  isNavbarCollapsed,
}: {
  navSection: NavSection;
  index: number;
  setNewExternalRoute: Dispatch<SetStateAction<string | undefined>>;
  onClick?: () => void;
  isNavbarCollapsed: boolean;
}): JSX.Element | null => {
  const theme = useTheme();
  const classes = useStyles();
  const { header, links, cssClass, isVisible, dataTestId } = navSection;
  const user = useAuthSession();
  const creatorProgramStatus = useCreatorProgramStatus();
  const pathname = usePathname();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  const isEnrollButtonVisible =
    canAccess(user, PERMISSION.ICP_FEATURE) &&
    !pathname?.includes('/enroll') &&
    (creatorProgramStatus?.needsToEnroll || creatorProgramStatus?.resumeEnroll);

  if (!isVisible) return null;

  return (
    <Box
      data-testid={dataTestId}
      className={clsx(classes.navSection, cssClass, { [classes.additionalMarginNavSection]: isEnrollButtonVisible })}
    >
      {index > 0 && <Divider variant="fullWidth" className={classes.divider} />}
      {header && (
        <Typography component="div" className={classes.navSectionHeading}>
          {header}
        </Typography>
      )}
      {links.map((link) => {
        const pageTitle = link.label;
        const isPageDisabled = !!link.messageIfDisabled;
        const tooltipTitle = link.messageIfDisabled || link.tooltip || pageTitle;
        const isTooltipVisible = isPageDisabled || !!link.tooltip || isNavbarCollapsed;

        return (
          <BootstrapTooltip
            placement={isSmDown ? 'top' : 'right'}
            disableHoverListener={!isTooltipVisible}
            title={tooltipTitle}
            key={link.label}
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -5],
                  },
                },
              ],
            }}
          >
            <div>
              <NavLinkItem onClick={onClick} key={link.key} link={link} setNewExternalRoute={setNewExternalRoute} />
            </div>
          </BootstrapTooltip>
        );
      })}
    </Box>
  );
};
