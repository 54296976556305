import { AppError } from '../app-error';

/** An awaitable implementation of setTimeout.
 * @param fn An async function to execute at the end of the timeout period.
 * @param ms Number of miliseconds in the timeout period.
 * @returns A promise that resolves after the supplied async function executes at the end of the timeout period.
 */
export function setTimeoutAsync<T>(asyncFn: (...args: any[]) => Promise<T>, ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms)).then(asyncFn);
}

export class ParallelWaitError<T> extends AppError {
  constructor(
    public readonly errors: Error[],
    public readonly results: (T | undefined)[],
  ) {
    super(500, 'parallel_wait_error', 'A parallel async task encountered an error', { errors: errors.map((e) => new AppError(e)) });
  }
}

export function isParallelWaitError<T = unknown>(test: unknown): test is ParallelWaitError<T | undefined> {
  return test instanceof ParallelWaitError;
}

// this differs from Promise.all in that it always waits for all promises to resolve before resolve/rejecting
export async function parallelExecute<T>(promises: Promise<T>[]): Promise<T[]> {
  if (promises.length <= 0) return [];

  return new Promise<T[]>((resolve, reject) => {
    const results: T[] = [];
    const errors: Error[] = [];
    let handled = 0;
    let finished = false;
    const checkFinished = () => {
      if (finished) throw new Error('finished twice?');
      if (handled >= promises.length) {
        finished = true;
        if (errors.length > 0) reject(new ParallelWaitError<T | undefined>(errors, results));
        else resolve(results);
      }
    };
    promises.forEach((p, i) => {
      p.then((val) => {
        results[i] = val;
      })
        .catch((err: unknown) => {
          errors[i] = err as Error;
        })
        .finally(() => {
          handled++;
          checkFinished();
        });
    });
  });
}
