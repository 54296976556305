import { useContext } from 'react';

import { ColorModeContext } from '@/contexts/color-mode.context';

export interface ColorModeInterface {
  toggleColorMode: () => void;
}

/**
 * Returns current ColorModeContext from react context api.
 * @type AssertNotUndefined removes undefined from return type definition.
 */
export function useColorMode<AssertNotUndefined extends boolean = false>(): AssertNotUndefined extends true
  ? ColorModeInterface
  : ColorModeInterface | undefined {
  return useContext(ColorModeContext) as AssertNotUndefined extends true ? ColorModeInterface : ColorModeInterface | undefined;
}
