import { useContext } from 'react';

import { CreatorPrivilegesContext } from '@/contexts/creator-privileges.context';

import type { CreatorPrivileges } from '@creator-portal/common/types';

/**
 * Returns current CreatorPrivileges from react context api.
 * @type AssertNotUndefined removes undefined from return type definition.
 */
export function useCreatorPrivileges<AssertNotUndefined extends boolean = false>(): AssertNotUndefined extends true
  ? CreatorPrivileges
  : CreatorPrivileges | undefined {
  return useContext(CreatorPrivilegesContext) as AssertNotUndefined extends true ? CreatorPrivileges : CreatorPrivileges | undefined;
}
