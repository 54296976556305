import { StepperItem } from '@/components/publishing/deploy-release-flow/stepper';
import { STEPPER_KEYS } from '@/components/publishing/deploy-release-flow/stepper-types';

import gameDetailsActiveIcon from '@/styles/icons/publishing-stepper/game-details-active.svg';
import gameDetailsIcon from '@/styles/icons/publishing-stepper/game-details-default.svg';
import mediaActiveIcon from '@/styles/icons/publishing-stepper/media-active.svg';
import mediaIcon from '@/styles/icons/publishing-stepper/media-default.svg';
import ratingActiveIcon from '@/styles/icons/publishing-stepper/rating-active.svg';
import ratingAttributionsActiveIcon from '@/styles/icons/publishing-stepper/rating-attributions-active.svg';
import ratingAttributionsIcon from '@/styles/icons/publishing-stepper/rating-attributions-default.svg';
import ratingIcon from '@/styles/icons/publishing-stepper/rating-default.svg';
import visibilityActiveIcon from '@/styles/icons/publishing-stepper/visibility-active.svg';
import visibilityIcon from '@/styles/icons/publishing-stepper/visibility-default.svg';

// Possible translations for t(`publishing.stepper.label.${step.stepTitleKey}`)
// t("publishing.stepper.label.game-details")
// t("publishing.stepper.label.promotional-media")
// t("publishing.stepper.label.rating")
// t("publishing.stepper.label.ratings-attributions")
// t("publishing.stepper.label.visibility")

export const stepperItems: StepperItem[] = [
  // {
  //   key: STEPPER_KEYS.buildValidation,
  //   uefnExclusive: true,
  //   stepTitleKey: 'build-validation',
  //   stepDefaultIcon: gameDetailsIcon,
  //   stepActiveIcon: gameDetailsActiveIcon,
  // },
  {
    key: STEPPER_KEYS.gameDetails,
    stepTitleKey: 'game-details',
    stepDefaultIcon: gameDetailsIcon,
    stepActiveIcon: gameDetailsActiveIcon,
  },
  {
    key: STEPPER_KEYS.rating,
    stepTitleKey: 'rating',
    stepDefaultIcon: ratingIcon,
    stepActiveIcon: ratingActiveIcon,
  },
  {
    key: STEPPER_KEYS.promotionalMedia,
    stepTitleKey: 'promotional-media',
    stepDefaultIcon: mediaIcon,
    stepActiveIcon: mediaActiveIcon,
  },
  {
    key: STEPPER_KEYS.attributions,
    stepTitleKey: 'ratings-attributions',
    stepDefaultIcon: ratingAttributionsIcon,
    stepActiveIcon: ratingAttributionsActiveIcon,
  },
  {
    key: STEPPER_KEYS.visibility,
    stepTitleKey: 'visibility',
    stepDefaultIcon: visibilityIcon,
    stepActiveIcon: visibilityActiveIcon,
  },
];
