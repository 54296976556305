import { List, ListItem } from '@mui/material';
import { NavCustomAccountLink } from '@wf-components/navigation-consumer';
import { clsx } from 'clsx';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';

import { headerConfig } from '@/config/common/header.config';

import { Button } from '@/components/ui';

import { useAuthSession } from '@/hooks/useAuthSession';

import useStyles from './sliding-panel-mobile.styles';

import type { SlidingPanelMobileProps } from './sliding-panel-mobile.component';

export default function UserAccountMenu({ handleLogoutClick }: Pick<SlidingPanelMobileProps, 'handleLogoutClick'>): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const user = useAuthSession();

  const config = headerConfig();
  const userAccountLinks = [...config.customAccountLinks, ...config.dropdownLinks];

  return (
    <List component="nav" className={classes.slidingPanelList}>
      {userAccountLinks.map((userLink) => {
        const isDarkModeSwitcher = (userLink as NavCustomAccountLink).owner === 'colorMode';

        return (
          <ListItem key={userLink.key} className={classes.slidingPanelItem}>
            <Link
              href={userLink.hrefTemplate as string}
              passHref
              prefetch={false}
              onClick={(e) => {
                if (isDarkModeSwitcher) {
                  e.preventDefault();
                  config.colorMode?.toggleColorMode();
                }
              }}
              target="_blank"
              rel="noreferrer"
              title={userLink.label}
              className={clsx(classes.slidingPanelLink, isDarkModeSwitcher && classes.darkModeSwitcher)}
            >
              {userLink.label}
            </Link>
          </ListItem>
        );
      })}

      {user && (
        <ListItem key="Logout" className={classes.slidingPanelItem}>
          <Button title={t('button.sign-out')} onClick={handleLogoutClick} className={classes.slidingPanelLink} variant="text">
            {t('button.sign-out')}
          </Button>
        </ListItem>
      )}
    </List>
  );
}
