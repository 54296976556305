import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import type { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toast: {
      width: '100%',

      '& .Toastify__toast': {
        minHeight: '98px',
        padding: theme.spacing(4),
      },
      '& .Toastify__toast--success': {
        background: `linear-gradient(91.54deg, ${theme.palette.commonGreenGradient1} -43.98%, ${theme.palette.commonGreen} 168.13%)`,
      },
      '& .Toastify__toast--error': {
        background: `linear-gradient(91.53deg, ${theme.palette.commonRedGradient1} -5.24%, ${theme.palette.commonRedGradient2} 103.3%)`,
      },
      '& .Toastify__progress-bar': {
        visibility: 'hidden',
      },
      '& .Toastify__close-button': {
        opacity: 1,
      },
      '& .Toastify__toast-body': {
        fontSize: theme.typography.pxToRem(18),
        fontHeight: theme.typography.pxToRem(25),
        fontWeight: theme.typography.fontWeightBold,
        padding: theme.spacing(0, 1.2),
      },
      '& .Toastify__toast-icon': {
        marginInlineEnd: theme.spacing(3.6),
      },

      '&.Toastify__toast-container--top-right': {
        top: 0,
        right: 0,
        padding: 0,
      },

      [theme.breakpoints.up('sm')]: {
        width: '576px',

        '&.Toastify__toast-container--top-right': {
          top: '1em',
          right: '1em',
        },
      },
    },
    grayToastSuccess: {
      '&.Toastify__toast--success': {
        background: theme.palette.commonGray,
      },
    },
  }),
);

export default useStyles;
