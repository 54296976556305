import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() =>
  createStyles({
    navIconDefault: {
      '.navigation-link-container:hover &, .project-sub-nav-link-container.sub-nav-link-active &': {
        display: 'none',
      },
      '.navigation-link-container.Mui-selected &, .project-sub-nav-link-container:hover &': {
        display: 'none',
      },
      '.navigation-link-container.Mui-selected &, .project-sub-nav-link-container.Mui-selected &': {
        display: 'none',
      },
    },
    navIconAnimated: {
      display: 'none',
      '.navigation-link-container:hover &': {
        display: 'block',
      },
      '.navigation-link-container.Mui-selected &': {
        display: 'none',
      },
    },
    navIconActive: {
      display: 'none',
      '.navigation-link-container.Mui-selected &, .project-sub-nav-link-container:hover &, .project-sub-nav-link-container.sub-nav-link-active &':
        {
          display: 'block',
        },
    },
  }),
);

export default useStyles;
