import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import getPaletteModeStyles from '@/util/getPaletteModeStyles';

const useStyles = makeStyles((theme) => {
  const isRTL = theme.direction === 'rtl';

  return createStyles({
    composer: {
      direction: theme.direction,
      '& .MuiPaper-root': {
        backgroundColor: getPaletteModeStyles(theme.palette.mode)(theme.palette.shade7, theme.palette.shade6),
        maxWidth: '100%',
        width: '100%',
        border: 'none',
        margin: 0,
        [theme.breakpoints.up('xs')]: {
          maxWidth: '576px',
          margin: theme.spacing(6.4),
        },
      },
      '& .EuiDialogHeader-root': {
        padding: theme.spacing(4, 4.8, 3.2),

        '& .EuiDialogHeader-body': {
          textAlign: 'center',
        },

        '& .MuiButtonBase-root': {
          position: 'absolute',
          padding: theme.spacing(1),
          top: theme.spacing(3),
          [isRTL ? 'left' : 'right']: theme.spacing(3),
        },
      },
      '& .MuiSvgIcon-root': {
        fontSize: theme.typography.pxToRem(26),
        margin: 'auto',
        display: 'block',
        [theme.breakpoints.up('sm')]: {
          fontSize: theme.typography.pxToRem(24),
        },
      },
      '& .MuiDialogContent-root': {
        overflowX: 'hidden',
        padding: theme.spacing(6.2, 4.8, 4.8),
        [theme.breakpoints.up('sm')]: {
          padding: theme.spacing(6.2, 10.6, 4.8),
        },
      },

      '& .MuiDialogActions-root': {
        overflowX: 'hidden',
        padding: theme.spacing(0, 4.8, 6.4),
        [theme.breakpoints.up('sm')]: {
          padding: theme.spacing(0, 10.6, 6.4),
        },
      },
    },
    composerError: {
      '& .EuiDialogHeader-root': {
        background: theme.palette.commonGradientBackgroundRed,
      },
    },
    composerInfo: {
      '& .EuiDialogHeader-root': {
        background: theme.palette.commonGradientBackgroundBlue,
      },
    },
    composerWarning: {
      '& .EuiDialogHeader-root': {
        background: theme.palette.commonGradientBackgroundOrange,
      },
    },
    actions: {
      display: 'flex',
      gap: theme.spacing(8),
      width: '100%',
      justifyContent: 'center',
    },
    button: {
      minWidth: '41%',
    },
    title: {
      textAlign: 'center',
      color: theme.palette.commonWhite,
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(25),
      fontWeight: theme.typography.fontWeightBold,
      marginTop: theme.spacing(2),
    },
    titleWarning: {
      color: theme.palette.commonBlack,
    },
    description: {
      textAlign: 'center',
      color: theme.palette.shade0,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(20),
      wordBreak: 'break-word',
    },
    infoIcon: {
      color: theme.palette.commonWhite,
      '&.MuiSvgIcon-root': {
        height: '34px',
        width: '34px',
        margin: 'auto',
        display: 'block',
      },
    },
    warningIcon: {
      '&.MuiSvgIcon-root': {
        color: theme.palette.commonBlack,
      },
    },
    closeWhite: {
      color: `${theme.palette.commonWhite} !important`,
    },
    closeBlack: {
      color: theme.palette.commonBlack,
    },
    approvedIcon: {
      color: theme.palette.commonWhite,
      '&.MuiSvgIcon-root': {
        height: '42px',
        width: '42px',
        margin: '-4px auto',
        display: 'block',
      },
    },
  });
});

export default useStyles;
