import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) =>
  createStyles({
    link: {
      color: theme.palette.blue,
      textDecoration: 'underline',
      fontWeight: 600,
      '&:hover': {
        filter: theme.palette.brightnessUp,
        textDecoration: 'underline',
        cursor: 'pointer',
      },
      '&:active': {
        filter: theme.palette.brightnessDown,
        textDecoration: 'underline',
      },
    },
  }),
);

export default useStyles;
