import { ParsedUrlQuery } from 'querystring';

import { DEFAULT_LOCALE, toEpicLocale } from '@/config/common/locale.config';

import { EPIC_LOCALE_COOKIE_NAME, getCookieValue, setCookieValue } from './cookie';

import type { GetServerSidePropsContext } from 'next';

/**
 * getLocale returns the selected or user preferred locale.
 *
 * The order of precedence for locale is as follows:
 *
 * 1. Explicit `lang` query parameter
 * 2. User chosen language in other epic property (cookie)
 * 3. User preferred language in browser
 * 4. Fallback to system default (en-US)
 */
export function getLocale(ctx: GetServerSidePropsContext): string {
  let locale = DEFAULT_LOCALE;

  if (ctx) {
    const acceptLanguageHeader = ctx.req.headers['accept-language'];
    const preferredLocale = acceptLanguageHeader && getPreferredLocaleFromHeader(acceptLanguageHeader);

    locale = getLangParam(ctx.query).lang || ctx?.req?.cookies?.[EPIC_LOCALE_COOKIE_NAME] || preferredLocale || DEFAULT_LOCALE;
  } else if (typeof window !== 'undefined') {
    const params = new URLSearchParams(window.location.href);

    locale = params.get('lang') || getCookieValue(EPIC_LOCALE_COOKIE_NAME, document.cookie) || navigator.language || DEFAULT_LOCALE;
  }

  return toEpicLocale(locale);
}

export function getLangParam(query: ParsedUrlQuery): { lang?: string } {
  const { lang } = query;

  if (Array.isArray(lang)) return { lang: lang[0] };

  return { ...(lang && { lang }) };
}

export const getPreferredLocaleFromHeader = (acceptLanguageHeader: string): string | undefined => {
  const [preferredLocale] = acceptLanguageHeader.split(',');

  const [locale] = preferredLocale.split(';');

  return locale;
};

export const switchLanguage = (code: string) => {
  const url = new URL(window.location.href);
  code === DEFAULT_LOCALE ? url.searchParams.delete('lang') : url.searchParams.set('lang', code);
  setCookieValue(EPIC_LOCALE_COOKIE_NAME, code);
  window.location.assign(url.toString());
};
