import * as React from 'react';
import { useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import Image from 'next/legacy/image';

import { getImage } from '@/util/media';

import useStyles from './navIconBuilder.styles';

interface Icon {
  name: string;
  enableAnimation?: boolean;
}

const NavIconBuilder: React.FunctionComponent<Icon> = ({ name, enableAnimation = true }) => {
  const [animationData, setAnimationData] = useState({});
  const [defaultIcon, setDefaultIcon] = useState(null);
  const [activeIcon, setActiveIcon] = useState(null);
  const styles = useStyles();

  useEffect(() => {
    import(`./${name}.svg`)
      .then((res) => setDefaultIcon(res.default))
      .catch(() => {
        console.error(`Default icon with name: ${name} not found!`);
      });

    import(`./${name}-active.svg`)
      .then((res) => setActiveIcon(res.default))
      .catch(() => {
        console.error(`Active icon with name: ${name} not found!`);
      });

    if (enableAnimation) {
      import(`./${name}-hover.json`)
        .then((res) => setAnimationData(res.default))
        .catch(() => {
          console.error(`Animation with name: ${name} not found!`);
        });
    }
  }, []);

  if (!defaultIcon || !activeIcon) return <></>;

  const isAnimationEnabled = enableAnimation && Object.keys(animationData).length !== 0;

  return (
    <>
      <div className={styles.navIconDefault}>
        <Image src={getImage(defaultIcon)} layout="fixed" priority />
      </div>
      {isAnimationEnabled && <Lottie className={styles.navIconAnimated} animationData={animationData} loop={true} />}
      <div className={styles.navIconActive}>
        <Image src={getImage(activeIcon)} layout="fixed" priority />
      </div>
    </>
  );
};

export default NavIconBuilder;
