import { ParsedUrlQuery } from 'querystring';

export const addAuthQueryParameters = (originalUrl: string, query: ParsedUrlQuery) => {
  let url = originalUrl;

  for (const [name, value] of Object.entries(query)) {
    if (Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) url = `${url}${encodeURIComponent(name)}=${encodeURIComponent(value[i])}&`;
    } else if (value) url = `${url}${encodeURIComponent(name)}=${encodeURIComponent(value)}&`;
  }

  return url;
};
