export class AppError extends Error {
  public readonly statusCode: number;
  public readonly errorCode: string;

  // Key-value pairs reported to Sentry. Consider these sensitive like Stacktrace.
  public readonly extras: Record<string, unknown>;

  public readonly options: Record<string, unknown>;

  constructor(
    status_code: number,
    error_code: string,
    message: string,
    extras?: Record<string, unknown>,
    options?: Record<string, unknown>,
  );
  constructor(status_code: number, error_code: string, message: string, extras?: Record<string, unknown>);
  constructor(status_code: number, error_code: string, causedBy: Error);
  constructor(causedBy: Error);
  constructor(causedBy: unknown);
  constructor(
    status_code_or_error: unknown,
    error_code?: string,
    message_or_error?: string | Error,
    extras?: Record<string, unknown>,
    options?: Record<string, unknown>,
  ) {
    if (typeof status_code_or_error === 'number') {
      if (message_or_error === undefined || typeof message_or_error === 'string') {
        super(message_or_error || '');
        this.extras = extras || {};
        this.options = options || {};
      } else {
        super(message_or_error.message);
        this.join_stack(message_or_error.stack || '');

        if (message_or_error instanceof AppError) {
          this.extras = extras || message_or_error.extras;
          this.options = options || message_or_error.options;
        } else {
          this.extras = extras || {};
          this.options = options || {};
        }
      }
      this.errorCode = error_code || 'unknown_error';
      this.statusCode = status_code_or_error;
    } else if (status_code_or_error instanceof Error) {
      super(status_code_or_error.message);
      if (status_code_or_error instanceof AppError) {
        this.errorCode = status_code_or_error.errorCode;
        this.statusCode = status_code_or_error.statusCode;
        this.extras = status_code_or_error.extras;
        this.options = status_code_or_error.options;
      } else {
        // some other errors have various semi-standard conventions
        const patterns = status_code_or_error as Partial<{ __type: string; code: string }>;
        this.errorCode = patterns.__type || patterns.code || 'internal_error';
        this.statusCode = 500;
        this.extras = {};
        this.options = {};
      }
      this.join_stack(status_code_or_error.stack || status_code_or_error.message);
    } else {
      super('Unknown error');
      this.errorCode = 'unknown_error';
      this.statusCode = 500;
      this.extras = {};
      this.options = {};
    }
  }

  get status(): number {
    return this.statusCode;
  }

  private join_stack(stack: string) {
    let ourstack = this.stack;
    if (ourstack === undefined) throw new Error(`stack is undefined (Error parent should be generating this) ${this.message}`);

    // combine with inner stack
    if (this.message) {
      const errmsg = `Error: ${this.message}`;
      if (ourstack.startsWith(errmsg)) ourstack = ourstack.substr(errmsg.length + 1);
    }
    const ourStackLines = ourstack.split('\n');

    // build a new stack
    this.stack = `${stack}\n    at join_stack (${__filename})\n${ourStackLines.join('\n')}`;
  }
}

export function isAppError(error: unknown): error is AppError;
export function isAppError(error: unknown, statusCode: number, errorCode?: string): error is AppError;
export function isAppError(error: unknown, statusCode?: number, errorCode?: string): error is AppError {
  if (error instanceof AppError) {
    if (typeof statusCode !== 'undefined' && typeof errorCode !== 'undefined')
      if (error.statusCode === statusCode && error.errorCode.endsWith(errorCode)) return true;
      else return false;
    else if (typeof statusCode !== 'undefined')
      if (statusCode && error.statusCode === statusCode) return true;
      else return false;
    return true;
  }

  return false;
}
