export function isNode(): boolean {
  return typeof global !== 'undefined' && global.global === global;
}

export function isLiveEnv(config: { EPIC_ENV: string; EPIC_DEPLOYMENT: string }): boolean {
  return config.EPIC_ENV === 'prod' && config.EPIC_DEPLOYMENT === 'live';
}

export function isLiveTestingEnv(config: { EPIC_ENV: string; EPIC_DEPLOYMENT: string }): boolean {
  return config.EPIC_ENV === 'prod' && config.EPIC_DEPLOYMENT !== 'live';
}
