import { AcknowledgementType } from '@creator-portal/common/profile-service/types';

import type { Platform } from '@/components/profile/social-links/social-links.component';

export const getProfileDraftUrl = (accountId: string) => `/api/profile/v1/${encodeURIComponent(accountId)}/draft` as const;
export const getProfileLiveUrl = (accountId: string) => `/api/profile/v1/${encodeURIComponent(accountId)}` as const;
export const getInitProfileUrl = (accountId: string) => `/api/profile/v1/${encodeURIComponent(accountId)}/draft/init` as const;
export const getProfileBioUrl = (accountId: string) => `/api/profile/v1/${encodeURIComponent(accountId)}/draft/bio` as const;
export const getIslandUrl = (code: string) => `/api/links/v1/${encodeURIComponent(code)}` as const;
export const getPublishProfileUrl = (accountId: string) => `/api/profile/v1/${encodeURIComponent(accountId)}/draft/publish` as const;
export const getPinIslandUrl = (accountId: string, linkCode: string) =>
  `/api/profile/v1/${encodeURIComponent(accountId)}/pinned/${encodeURIComponent(linkCode)}` as const;
export const getProfileSocialUrl = (accountId: string, platform: Platform) =>
  `/api/profile/v1/${encodeURIComponent(accountId)}/draft/social/${encodeURIComponent(platform)}` as const;
export const getCreatorProgramStatusUrl = () => `/api/creator/v1/status` as const;
export const getCreatorPrivilegesUrl = () => `/api/creator/v1/privileges` as const;
export const getProfileHeaderImageUrl = (accountId: string) => `/api/profile/v1/${encodeURIComponent(accountId)}/header-image` as const;
export const getProfileHeaderSaveImageUrl = (accountId: string, mediaId: string) =>
  `/api/profile/v1/${encodeURIComponent(accountId)}/header-image/${encodeURIComponent(mediaId)}` as const;
export const getProfileIslandsUrl = (accountId: string) => `/api/profile/v1/${encodeURIComponent(accountId)}/islands` as const;
export const getCurrentProfileReleaseUrl = (accountId: string) => `/api/profile/v1/${encodeURIComponent(accountId)}/release` as const;
export const getPickedIslandsUrl = (accountId: string) => `/api/profile/v1/${encodeURIComponent(accountId)}/picks` as const;
export const getProfileMediaUrl = (accountId: string) => `/api/profile/v1/${encodeURIComponent(accountId)}/draft/media` as const;
export const getProfileSaveMediaUrl = (accountId: string, mediaId: string) =>
  `/api/profile/v1/${encodeURIComponent(accountId)}/draft/media/${encodeURIComponent(mediaId)}` as const;
export const getSharedPlayHistoryUrl = (accountId: string) =>
  `/api/profile/v1/${encodeURIComponent(accountId)}/shared-play-history` as const;
export const getAcknowledgementsUrl = (accountId: string, acknowledgementType?: AcknowledgementType) =>
  `/api/profile/v1/${encodeURIComponent(accountId)}/acknowledgements${acknowledgementType ? `/${encodeURIComponent(acknowledgementType)}` : ''}`;
export const getFollowersStatsUrl = () => `/api/profile/v1/followers/stats` as const;
