const colorVariables = {
  // Grayscale
  light100: '#FFFFFF',
  gray10: '#F5F7F7',
  gray20: '#EBEAED',
  gray30: '#D3D4D7',
  gray40: '#CACED6',
  gray50: '#A2A4AA',
  gray60: '#8C94A0',
  gray70: '#7C7E86',
  gray80: '#5C606A',
  gray90: '#525860',
  gray100: '#4B4D55',
  gray110: '#3A3D4C',
  gray120: '#33363D',
  gray130: '#212329',
  gray140: '#101010',
  gray150: '#111111',
  dark100: '#000000',
  // Accent
  blue10: '#C9E8FF',
  blue20: '#689EFD',
  blue30: '#369AFA',
  blue40: '#1A90FF',
  blue50: '#1382FF',
  blue60: '#038CDF',
  blue70: '#4A83E0',
  blue80: '#0E71D0',
  blue90: '#0160CA',
  cyan40: '#37D8FE',
  green30: '#29CD6B',
  green40: '#11CF59',
  green80: '#0B873A',
  orange30: '#FAC181',
  orange60: '#FFC229',
  orange80: '#FFA640',
  orange100: '#A87033',
  brown50: '#AF4000',
  red40: '#EF646F',
  red50: '#DE3341',
  pink40: '#E96DB5',
  pink70: '#CA539A',
  purple30: '#B17FFB',
  purple70: '#846BCA',
  purple80: '#7C4BC2',
  magenta40: '#E96DB5',
  magenta70: '#CA539A',
  // Transparent
  transparentLight010: 'rgba(255, 255, 255, 0.1)',
  transparentLight020: 'rgba(255, 255, 255, 0.2)',
  transparentLight040: 'rgba(255, 255, 255, 0.4)',
  transparentGray40_060: 'rgba(202, 206, 214, 0.6)',
  transparentGray80_060: 'rgba(92, 96, 106, 0.6)',
  transparentGray150_020: 'rgba(17, 17, 17, 0.2)',
  transparentDark000: 'rgba(0, 0, 0, 0.0)',
  transparentDark005: 'rgba(0, 0, 0, 0.05)',
  transparentDark008: 'rgba(0, 0, 0, 0.08)',
  transparentDark010: 'rgba(0, 0, 0, 0.1)',
  transparentDark040: 'rgba(0, 0, 0, 0.4)',
  transparentDark060: 'rgba(0, 0, 0, 0.6)',
  // Gradient
  // bespoke section to cover Creator Portal Background - aim is to list and maintain assets in both light and dark mode seamlessly.
  gradientDarkLin01: '#413775',
  gradientDarkLin02: '#1C1E49',
  gradientDarkLin03: '#1A1A1A',
  gradientDarkLin04: '#18264A',

  gradientDarkRad01: 'rgba(30, 61, 139, 0.6)',
  gradientDarkRad02: 'rgba(30, 61, 139, 0.0)',

  gradientDarkTex01: 'rgba(47, 53, 66, 1)',
  gradientDarkTex02: 'rgba(47, 53, 66, 0.0)',

  gradientLightLin01: '#D5E8FF',
  gradientLightLin02: '#E2F0FF',
  gradientLightLin03: '#FFFFFF',
  gradientLightLin04: '#E2F0FF',

  gradientLightRad01: 'rgba(188, 207, 255, 0.6)',
  gradientLightRad02: 'rgba(188, 207, 255, 0.0)',

  gradientLightTex01: 'rgba(218, 232, 242, 1)',
  gradientLightTex02: 'rgba(218, 232, 242, 0.0)',
};

export default colorVariables;
