import { ButtonProps as BaseButtonProps, Button } from '@epic-ui/components';
import { clsx } from 'clsx';
import Link from 'next/link';

import useStyles from './Button.styles';

export interface ButtonProps extends BaseButtonProps {
  // Add any additional props here
  icon?: React.ReactNode;
  target?: string;
}

export default function ButtonComponent(props: ButtonProps): JSX.Element {
  const classes = useStyles();
  const icon = props.icon;

  return (
    <Button {...props} LinkComponent={Link} className={clsx(classes.button, props.className, icon && classes.buttonWithIcon)}>
      {icon && icon}
      {props.children}
    </Button>
  );
}
