import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import type { Theme } from '@mui/material/styles';

const iconsSizeDefault = 32;
const iconsSizeSmall = 24;

const useStyles = makeStyles((theme: Theme) => {
  const isRTL = theme.direction === 'rtl';

  return createStyles({
    navLeftItem: {
      textAlign: isRTL ? 'right' : 'left',
      display: 'inline-block',

      '& .MuiTypography-root': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '.resourcesLinks &': {
        paddingLeft: theme.spacing(3.6),
      },
      [isRTL ? 'marginRight' : 'marginLeft']: theme.spacing(1),
      fontSize: theme.typography.pxToRem(11),
      [theme.breakpoints.up('md')]: {
        [isRTL ? 'paddingRight' : 'paddingLeft']: theme.spacing(1.6),
        '.resourcesLinks &': {
          [isRTL ? 'paddingRight' : 'paddingLeft']: theme.spacing(3.4),
        },
      },
      [theme.breakpoints.up('lg')]: {
        display: 'inline-block',
      },
    },
    navLeftLink: {
      textDecoration: 'none',
    },
    navLeftOptionWrapper: {
      '.project-sub-nav-visible &': {
        overflow: 'hidden',
      },
    },
    navLeftOption: {
      borderRadius: theme.spacing(1),
      height: '48px',
      margin: theme.spacing(0, 2.2),
      textTransform: 'uppercase',
      width: 'auto',
      '.resourcesLinks &': {
        height: '36px',
      },
      [theme.breakpoints.up('md')]: {
        [isRTL ? 'paddingRight' : 'paddingLeft']: theme.spacing(1.6),
      },
      [theme.breakpoints.up('lg')]: {
        [isRTL ? 'paddingRight' : 'paddingLeft']: theme.spacing(3),
      },
      '&:active': {
        background: theme.palette.shade5,
      },
      '& .MuiTypography-body1': {
        fontStyle: 'normal',
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.pxToRem(11),
        lineHeight: theme.typography.pxToRem(13),
        letterSpacing: theme.typography.pxToRem(0.75),
        [theme.breakpoints.up('lg')]: {
          fontSize: theme.typography.pxToRem(11),
        },
      },
      '& svg': {
        color: theme.palette.dividerStrong,
      },
      '.project-sub-nav-visible &': {
        height: 50,
        padding: theme.spacing(2, 2),
        margin: theme.spacing(1.6, 2),
        minWidth: 50,
      },
      '.project-sub-nav-visible .resourcesLinks &': {
        height: 30,
        padding: theme.spacing(0, 2.8),
        margin: theme.spacing(0, 2, 2),
        minWidth: 30,
      },
    },
    navLeftOptionDisabled: {
      opacity: 0.4,
      pointerEvents: 'none',
    },
    navLeftOptionHover: {
      color: theme.palette.shade2,

      '&:hover': {
        color: theme.palette.mode === 'light' ? theme.palette.commonBlueGradient2 : theme.palette.shade0,
        background: 'transparent',
      },
    },
    navLeftButton: {
      '&.Mui-selected': {
        background: theme.palette.shade6,
        color: theme.palette.mode === 'light' ? theme.palette.commonBlueGradient2 : theme.palette.shade0,
        pointerEvents: 'none',
      },
      '& div.MuiTypography-root': {
        fontWeight: 600,
        lineHeight: '0.8em',
      },
      '& svg': {
        color: 'inherit',
      },
    },
    navLeftButtonEventsAuto: {
      '&.Mui-selected': {
        pointerEvents: 'auto',
      },
    },
    navLeftActiveLink: {
      '&.Mui-selected': {
        pointerEvents: 'auto',
      },
    },
    listItemIcon: {
      display: 'flex',
      justifyContent: 'flex-start',
      minWidth: iconsSizeDefault,
      minHeight: iconsSizeDefault,
      width: iconsSizeDefault,
      height: iconsSizeDefault,
      '& > div': {
        height: '100%',
      },
      '.resourcesLinks &': {
        minWidth: iconsSizeSmall,
        minHeight: iconsSizeSmall,
        width: iconsSizeSmall,
        height: iconsSizeSmall,
      },
    },
  });
});

export default useStyles;
