import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import type { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainFooter: {
      direction: theme.direction,
      '& hr': {
        borderColor: 'transparent !important',
      },
      '& > div': {
        margin: theme.spacing(0, 24),
        paddingLeft: 0,
        paddingRight: 0,
        width: 'auto',
        maxWidth: '1640px',
        // @ts-ignore-next-line
        [theme.breakpoints.down('xxl')]: {
          margin: theme.spacing(0, 16),
          maxWidth: '1640px',
        },
        [theme.breakpoints.down('lg')]: {
          margin: theme.spacing(0, 11.2),
          maxWidth: '1640px',
        },
        [theme.breakpoints.down('md')]: {
          margin: theme.spacing(0, 8),
        },
        [theme.breakpoints.down('sm')]: {
          margin: theme.spacing(0, 4.8),
        },
      },
    },
  }),
);

export default useStyles;
