'use client';

import { useEffect } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { FortniteNavigation } from '@wf-components/navigation-react';
import { useTranslation } from 'next-i18next';
import { useParams, usePathname, useRouter } from 'next/navigation';

import { canAccess } from '@creator-portal/common/auth';
import { PERMISSION } from '@creator-portal/common/permissions/constants';

import { LOGIN_URL } from '@/config/common/constants';
import { headerConfig } from '@/config/common/header.config';

import { isMainNavigationCollapsed } from '@/services/common/navigationUtils';

import { useAuthSession } from '@/hooks/useAuthSession';
import { useCreatorProgramStatus } from '@/hooks/useCreatorProgramStatus';
import { switchLanguage } from '@/util/locale';

import type { AuthSession } from '@creator-portal/common/auth/types';
import type {
  NavAccountLinkClickEvent,
  NavDrawerToggleEvent,
  NavLinkClickEvent,
  NavLocaleClickEvent,
  NavPropertyLogoClickEvent,
} from '@wf-components/navigation-consumer';

interface mainHeaderProps {
  isLoggedIn: AuthSession | undefined;
  burgerCallBack: (mode: 'open' | 'close') => void;
  handleLogoutClick: () => void;
}

type CustomEventNavOnClickEvent = CustomEvent<NavPropertyLogoClickEvent> | CustomEvent<NavLinkClickEvent>;
interface Drawer extends Element {
  closeDrawer: () => void;
  toggleDrawer: () => void;
}

const CLOSE_ICON =
  'M2.25 5A.75.75 0 0 1 3 4.25h18a.75.75 0 0 1 0 1.5H3A.75.75 0 0 1 2.25 5Zm0 7a.75.75 0 0 1 .75-.75h18a.75.75 0 0 1 0 1.5H3a.75.75 0 0 1-.75-.75Zm0 7a.75.75 0 0 1 .75-.75h18a.75.75 0 0 1 0 1.5H3a.75.75 0 0 1-.75-.75Z';

export default function MainHeaderComponent({ isLoggedIn, burgerCallBack, handleLogoutClick }: mainHeaderProps): JSX.Element {
  const router = useRouter();
  const pathname = usePathname();
  const params = useParams();
  const config = headerConfig();
  const { t } = useTranslation();
  const theme = useTheme();
  const user = useAuthSession();
  const creatorProgramStatus = useCreatorProgramStatus();

  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const { locale, localeConfig } = config;

  //need to disable sticky on mobile for pages that have subnavigation
  const isSticky = isMdUp || !isMainNavigationCollapsed(user, params) ? true : false;

  const navLinkOnClick = (e: CustomEventNavOnClickEvent) => {
    const link = e.detail?.link;
    if (link?.owner === 'postparty' && link?.relativePath) {
      e.detail.originalEvent.preventDefault();
      void router.push(link.relativePath);
    }
  };
  const localeOnClick = (e: CustomEvent<NavLocaleClickEvent>) => {
    e.preventDefault();
    switchLanguage(e.detail.link.langCode);
  };
  const accountLinkOnClick = (e: CustomEvent<NavAccountLinkClickEvent>) => {
    const link = e.detail?.link;
    if (link?.owner === 'colorMode') {
      e.detail.originalEvent.preventDefault();
      config.colorMode?.toggleColorMode();
    }
    if (link.key === 'signout') {
      handleLogoutClick();
    }
  };
  const onDrawerToggle = (e: CustomEvent<NavDrawerToggleEvent>) => {
    const defaultDrawer = document.querySelector('fortnite-navigation') as Drawer | undefined;

    const burgerBtn = defaultDrawer?.shadowRoot?.getElementById('mobile-drawer-toggle');
    const svgIcon = burgerBtn?.querySelector('svg')?.querySelector('path');
    const defaultMobileNavigation = defaultDrawer?.shadowRoot?.getElementById('mobile-drawer');

    if (burgerBtn && e.detail.opened) {
      if (defaultMobileNavigation) {
        defaultMobileNavigation.style.display = 'none';
      }
      svgIcon?.setAttribute('d', CLOSE_ICON);
      burgerCallBack('open');
    } else {
      burgerCallBack('close');
    }
  };

  useEffect(() => {
    const defaultDrawer = document.querySelector('fortnite-navigation') as Drawer | undefined;
    const burgerMenu = defaultDrawer?.shadowRoot?.getElementById('mobile-drawer-toggle');
    const burgerMenuParent = burgerMenu?.parentElement;
    const userAndLanguageMenu = defaultDrawer?.shadowRoot?.getElementById('mobile-drawer');

    if (!burgerMenu || !userAndLanguageMenu || !burgerMenuParent) return;

    if (isLgUp) {
      burgerMenu.style.display = 'none';
      burgerMenuParent.style.display = 'none';
      userAndLanguageMenu.style.display = 'flex';
    } else {
      burgerMenu.style.display = 'block';
      burgerMenuParent.style.display = 'flex';
      userAndLanguageMenu.style.display = 'none';
    }
  }, [isLgUp]);

  const btnConfig = {
    isVisible: false,
    label: '',
    href: '',
  };
  const loggedIn = !!user;

  if (
    canAccess(user, PERMISSION.ICP_FEATURE) &&
    !pathname?.includes('/enroll') &&
    (creatorProgramStatus?.needsToEnroll || creatorProgramStatus?.resumeEnroll)
  ) {
    btnConfig.isVisible = true;
    btnConfig.href = '/enroll';
    btnConfig.label = creatorProgramStatus?.resumeEnroll
      ? t('epic.ecp.header.cta-btn.continue-enrollment')
      : t('epic.ecp.header.cta-btn.enroll');
  }
  if (!loggedIn) {
    btnConfig.isVisible = false;
  }

  return (
    <FortniteNavigation
      // state
      locale={locale}
      displayName={isLoggedIn?.displayName}
      localeOptions={localeConfig}
      isLoggedIn={!!isLoggedIn}
      disableSearch={config.hideSearch}
      propertyLogo={config.propertyLogo}
      propertyLogoWidth={config.logoWidth}
      disableCtaButton={!btnConfig.isVisible}
      // @ts-ignore:next-line
      sticky={isSticky}
      // links
      mainLinks={config.mainLinks}
      accountLinks={config.dropdownLinks}
      customAccountLinks={config.customAccountLinks}
      // cb
      localeOnClick={localeOnClick}
      drawerToggle={onDrawerToggle}
      navLinkOnClick={navLinkOnClick}
      propLogoOnClick={navLinkOnClick}
      accountLinkOnClick={accountLinkOnClick}
      platformCtaButton={{
        id: 'header-cta-btn',
        locale,
        label: btnConfig.label,
        defaultHref: btnConfig.href,
      }}
      signOutLink={{
        id: 'header-signout-btn',
        locale,
        label: t('button.sign-out'),
        key: 'signout',
      }}
      // auth link
      domain={''}
      signInLinkHref={LOGIN_URL}
      signInLink={{
        id: 'header-sign-in',
        label: t('navbar.login'),
        locale: locale,
      }}
    />
  );
}
