import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import type { Theme } from '@mui/material/styles';

export const BUTTON_HEIGHT_DEFAULT = 46;
export const BUTTON_HEIGHT_LARGE = 60;

const useStyles = makeStyles((theme: Theme) => {
  const isRTL = theme.direction === 'rtl';

  return createStyles({
    button: {
      textTransform: 'none',
      '&.MuiButton-root.MuiButton-containedPrimary': {
        background: theme.palette.commonGradientCTABlue,
      },
      '&.MuiButton-root.MuiButton-outlinedPrimary': {
        color: theme.palette.blue,
        borderColor: theme.palette.blue,
      },
      '&.MuiButton-root.MuiButton-containedError': {
        background: theme.palette.commonGradientBackgroundRed,
      },
      '&.MuiButton-root.MuiButton-outlinedError': {
        color: theme.palette.commonRedGradient1,
        borderColor: theme.palette.commonRedGradient1,
      },
      '&.MuiButton-root.EuiButton-root': {
        padding: theme.spacing(3, 6.6),
        height: BUTTON_HEIGHT_DEFAULT,
        fontStyle: 'normal',
        fontWeight: 800,
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(15),
        letterSpacing: '0.5px',
        display: 'flex',
        '&:hover': {
          filter: theme.palette.brightnessUp,
        },
        '&:active': {
          filter: theme.palette.brightnessDown,
        },
        '& span': {
          display: 'flex',
          alignItems: 'center',
        },
        '&.MuiButton-sizeLarge': {
          height: BUTTON_HEIGHT_LARGE,
          fontSize: theme.typography.pxToRem(18),
        },
      },
      '&.MuiButton-root.MuiButton-outlined': {
        borderWidth: '2px',
        '&:hover': {
          borderWidth: '2px',
        },
      },
      '&.MuiButton-root.Mui-disabled': {
        opacity: '0.25',
      },
    },
    buttonWithIcon: {
      padding: `${theme.spacing(1, 3)} !important`,
      '& svg': {
        width: '24px',
        height: '24px',
        [isRTL ? 'marginLeft' : 'marginRight']: theme.spacing(2),
      },
    },
  });
});

export default useStyles;
