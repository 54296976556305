// palette values for both modes
export const commonColors = {
  commonWhite: '#ffffff',
  commonBlack: '#000000',
  commonSkyBlue: '#C9E8FF',
  commonGreen: '#29CD6B',
  commonOrange: '#F5992E',
  commonRed: '#E2535C',
  commonPurple: '#846BCA',
  commonGray: '#3A3D4C',

  commonBlueGradient1: '#0160CA',
  commonBlueGradient2: '#038CDF',
  commonBlueGradient3: '#37D8FE',
  commonBlueGradient4: '#369AFA',
  commonBlueGradient5: '#0E71D0',

  // Selected Icons in Nav
  commonGradientIconBlue: 'linear-gradient(315deg, #1382FF 0%, #37D8FE 100%)',
  commonGradientIconBlueReversed: 'linear-gradient(315deg, #37D8FE 0%, #1382FF 100%)',

  // CTAs
  commonGradientCTABlue: 'linear-gradient(270deg, #038CDF 0%, #0160CA 100%)',

  // General Modal Headers
  commonGradientBackgroundBlue: 'linear-gradient(91.52deg, #0E71D0 -5.24%, #369AFA 117.06%)',

  // Error Modal Headers, Toasts
  commonRedGradient1: '#DE3341',
  commonRedGradient2: '#EF646F',
  commonGradientBackgroundRed: 'linear-gradient(91.53deg, #DE3341 -5.24%, #EF646F 103.3%)',

  // Alert Modal Headers, Toasts
  commonOrangeGradient1: '#FFA640',
  commonOrangeGradient2: '#FAC181',
  commonGradientBackgroundOrange: 'linear-gradient(91.52deg, #FFA640 -5.24%, #FAC181 117.06%)',

  // Success Modal Headers, Toasts
  commonGreenGradient1: '#0B873A',
  commonGreenGradient2: '#11CF59',
  commonGradientBackgroundGreen: 'linear-gradient(91.54deg, #0B873A -43.98%, #11CF59 168.13%)',

  brightnessDown: 'brightness(80%)',
  brightnessUp: 'brightness(120%)',
};
