import { AlertIcon } from '@epic-ui/icons';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { clsx } from 'clsx';
import { useTranslation } from 'next-i18next';
import Image from 'next/legacy/image';

import { canAccess } from '@creator-portal/common/auth';
import { GAME_FEATURE } from '@creator-portal/common/links/constants';
import { PERMISSION } from '@creator-portal/common/permissions/constants';

import { BootstrapTooltip } from '@/components/common/tooltip.component';
import { RatingStatus } from '@/components/publishing/ratings-dialog.component';

import { useAuthSession } from '@/hooks/useAuthSession';
import { useProject } from '@/contexts/project.context';
import { getImage } from '@/util/media';
import { checkProjectSysMetaIncludesGameFeature } from '@/util/publishing';

import OverriddenIcon from '@/styles/icons/publishing-stepper/overridden-icon.svg';

import { RatingTableHeadTitles } from '@/types/publishing';

import useStyles from './ratings-table.styles';

import type { RatingData } from '@/util/ratingFunctions';

interface RatingsTableComponentProps {
  data: RatingData[] | null;
  status: RatingStatus;
}

const RatingsTableComponent = ({ data, status }: RatingsTableComponentProps): JSX.Element | null => {
  const { t } = useTranslation();
  const classes = useStyles();
  const user = useAuthSession();
  const { project } = useProject();
  const hasIPPublishingAccess = canAccess(user, PERMISSION.LEGO_FEATURE);
  const hasFallGuysAccess = canAccess(user, PERMISSION.FALL_GUYS_FEATURE);
  const hasTmntAccess = canAccess(user, PERMISSION.TMNT_FEATURE);
  const isLegoProject = checkProjectSysMetaIncludesGameFeature(project?.sysMeta, GAME_FEATURE.LEGO);
  const isFallGuysProject = checkProjectSysMetaIncludesGameFeature(project?.sysMeta, GAME_FEATURE.FALL_GUYS);
  const isTmntProject = checkProjectSysMetaIncludesGameFeature(project?.sysMeta, GAME_FEATURE.TMNT);

  const showGRACTooltip = canAccess(user, PERMISSION.SHOW_GRAC_TOOLTIP);

  const isCommonRatingMature = status === RatingStatus.MATURE;
  const isCommonRatingSuccess = status === RatingStatus.SUCCESS;

  if (!data) return null;

  return (
    <TableContainer className={classes.tableContainer} data-testid="ratingTable">
      <Table>
        <TableHead>
          <TableRow>
            {Object.values(RatingTableHeadTitles).map((title) => (
              <TableCell key={title}>
                {
                  // t("iarc-table.header.preview")
                  // t("iarc-table.header.ratingCategory")
                  // t("iarc-table.header.region")
                  // t("iarc-table.header.descriptors")
                  // t("iarc-table.header.interactive-elements")
                  t(`iarc-table.header.${title}`)
                }
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item: RatingData, id): JSX.Element => {
            const {
              ratingImage,
              ratingBoardShortText,
              isIARC,
              isMature,
              descriptorTexts,
              region,
              interactiveElementTexts,
              ageRatingText,
              isRatingOverridden,
            } = item;
            const isPartialMatureRow = !isCommonRatingMature && isMature;
            let partialMatureTooltip = t('iarc-table.partial-mature.icon.tooltip');

            if (hasIPPublishingAccess && isLegoProject)
              partialMatureTooltip = t('projects.rating.received.button.next.disabled.tooltip.brand', { brand: t('projectCategory.lego') });

            if (hasFallGuysAccess && isFallGuysProject)
              partialMatureTooltip = t('projects.rating.received.button.next.disabled.tooltip.brand', {
                brand: t('projectCategory.fall-guys'),
              });

            if (hasTmntAccess && isTmntProject) partialMatureTooltip = t('iarc-table.partial-mature.icon.tooltip.tmnt');

            return (
              <TableRow
                key={id}
                className={clsx({
                  [classes.matureBacklight]: isCommonRatingMature,
                  [classes.partialMatureBacklight]: isMature,
                })}
              >
                <TableCell>
                  <div
                    className={classes.img}
                    style={{
                      backgroundImage: `url(${ratingImage || '/defaultRating.png'})`,
                    }}
                  >
                    {isPartialMatureRow && (
                      <BootstrapTooltip title={partialMatureTooltip} placement="top" className={classes.tooltip}>
                        <div className={classes.iconWrapper}>
                          <AlertIcon fontSize="small" className={clsx(classes.warnIcon)} />
                        </div>
                      </BootstrapTooltip>
                    )}
                    {showGRACTooltip && isCommonRatingSuccess && ratingBoardShortText.toLocaleUpperCase() === 'GRAC' && (
                      <BootstrapTooltip title={t('iarc-table.success.icon.tooltip')} placement="top" className={classes.tooltip}>
                        <div className={classes.iconWrapper}>
                          <AlertIcon fontSize="small" className={clsx(classes.successIcon)} />
                        </div>
                      </BootstrapTooltip>
                    )}
                  </div>
                </TableCell>
                <TableCell>
                  <Typography className={clsx(isCommonRatingMature && classes.matureCategory, classes.ratingCategory)}>
                    {ageRatingText}
                  </Typography>
                  {isIARC ? (
                    <div className={classes.ratingCategoryLabel}>
                      {isRatingOverridden && (
                        <BootstrapTooltip title={t('iarc-table.overridden.icon.tooltip')} placement="top" className={classes.tooltip}>
                          <span className={classes.overriddenIcon}>
                            <Image src={getImage(OverriddenIcon)} />
                          </span>
                        </BootstrapTooltip>
                      )}
                      {t('iarc-table.ratingCategory.label.iarc')}
                    </div>
                  ) : null}
                </TableCell>
                <TableCell>
                  <Typography>
                    <div className={classes.regionTitle}>{ratingBoardShortText}</div>
                    <div>{region}</div>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>{descriptorTexts}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{interactiveElementTexts}</Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RatingsTableComponent;
