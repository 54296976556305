import { useCallback } from 'react';
import { useQueryState } from 'nuqs';

export interface DialogWithQueryKey {
  queryValue?: string;
  handleOpenDialog: (value: string) => void;
  handleCloseDialog: () => void;
}

const useDialogWithQueryKey = (queryParam: string): DialogWithQueryKey => {
  const [queryKey, setQueryKey] = useQueryState(queryParam);

  const handleOpenDialog = useCallback(
    async (value: string) => {
      await setQueryKey(value);
    },
    [setQueryKey],
  );

  const handleCloseDialog = useCallback(async () => {
    await setQueryKey(null);
  }, [setQueryKey]);

  return {
    queryValue: queryKey ?? undefined,
    handleOpenDialog,
    handleCloseDialog,
  };
};

export default useDialogWithQueryKey;
