import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { toast } from 'react-toastify';
import { useSWRConfig } from 'swr';

import { IarcUrl, updateIarcEmail } from '@/services/iarc/iarc-service';

import PrefilledInputComponent from '@/components/common/prefilled-input';
import EditIarcEmailDialog from '@/components/publishing/deploy-release-flow/steps/form-parts/edit-iarc-email-dialog';
import RatingTable from '@/components/publishing/deploy-release-flow/steps/form-parts/ratings-table';
import { RatingStatus } from '@/components/publishing/ratings-dialog.component';

import { trackClickInteraction } from '@/util/tracking';

import useStyles from './ratings-display.styles';

import type { RatingData } from '@/util/ratingFunctions';

interface RatingsDisplayComponentProps {
  email: string;
  certificateId: string | null;
  projectId: string;
  data: RatingData[] | null;
  status: RatingStatus;
}

const RatingsDisplayComponent: React.FC<RatingsDisplayComponentProps> = ({
  email: defaultEmail,
  certificateId,
  projectId,
  status,
  data = [],
}): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();
  const urlForCertificateDetails = certificateId ? IarcUrl.ratingInfo(projectId, certificateId) : null;
  const { mutate } = useSWRConfig();
  const [isEditIarcEmailDialogOpen, setIsEditIarcEmailDialogOpen] = useState<boolean>(false);
  const [isUpdatingIarcEmail, setisUpdatingIarcEmail] = useState(false);
  const iarcEmailErrorText = t('edit-iarc-email-dialog.toast.rename.error');
  const [email, setEmail] = useState(defaultEmail);

  useEffect(() => {
    setEmail(defaultEmail);
  }, [defaultEmail]);

  const handleEditIarcEmailDialogCancel = () => {
    setIsEditIarcEmailDialogOpen(false);
  };

  const openEmailEditDialog = () => {
    setIsEditIarcEmailDialogOpen(true);
  };

  const handleUpdateIarcEmail = async (newEmail: string): Promise<void> => {
    trackClickInteraction({
      eventCategory: 'Iarc',
      eventAction: 'update email',
    });
    setisUpdatingIarcEmail(true);
    try {
      if (!certificateId || !newEmail) {
        toast.error(iarcEmailErrorText);
        console.log('Iarc email is missing');

        return;
      }
      await updateIarcEmail(projectId, certificateId, newEmail);
      setEmail(newEmail);
      await toast.promise(mutate(urlForCertificateDetails), {
        success: t('edit-iarc-email-dialog.toast.rename.success'),
        error: iarcEmailErrorText,
      });
    } catch (error) {
      console.log('Error while renaming Iarc email');
    } finally {
      setIsEditIarcEmailDialogOpen(false);
      setisUpdatingIarcEmail(false);
    }
  };

  return (
    <>
      <div className={classes.ratingsDisplay}>
        <div className={classes.textFieldsBlock}>
          <PrefilledInputComponent
            hasEditButton={true}
            openEditDialog={openEmailEditDialog}
            label={t('projects.modal.ratings.email-label.new')}
            value={email}
          />
          <PrefilledInputComponent label={t('projects.modal.ratings.certificate-id-label')} value={certificateId} />
        </div>
        <RatingTable data={data} status={status} />
      </div>
      <EditIarcEmailDialog
        isOpen={isEditIarcEmailDialogOpen}
        email={email}
        isLoading={isUpdatingIarcEmail}
        onSubmit={handleUpdateIarcEmail}
        onClose={handleEditIarcEmailDialogCancel}
      />
    </>
  );
};

export default RatingsDisplayComponent;
