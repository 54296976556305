import { FC } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import Image from 'next/image';

import { canAccess } from '@creator-portal/common/auth';
import { GAME_FEATURE } from '@creator-portal/common/links/constants';
import { PERMISSION } from '@creator-portal/common/permissions/constants';

import { FORTNITE_CONTENT_GUIDELINES_PATH } from '@/config/common/constants';
import {
  getLegoBrandAndCreatorGuidelinesInFortniteCreativeUrl,
  getWorkingWithFallGuysIslandsInFortniteCreativeUrl,
} from '@/config/common/functions';
import { toDocLocale } from '@/config/common/locale.config';

import InfoBox from '@/components/common/alerts/info-box';
import CommonLink from '@/components/common/common-link';
import useStyles from '@/components/publishing/deploy-release-flow/steps/form-parts/rating-infobox.styles';
import { RatingStatus } from '@/components/publishing/ratings-dialog.component';

import { usePublicRuntimeConfig } from '@/hooks/use-public-runtime-config';
import { useAuthSession } from '@/hooks/useAuthSession';
import { useProject } from '@/contexts/project.context';
import { checkProjectSysMetaIncludesGameFeature } from '@/util/publishing';

import OpenIconBlack from '@/public/enroll/icons/external_open_black.svg';
import OpenIcon from '@/public/enroll/icons/external_open.svg';

interface RatingInfoboxComponent {
  retakeIarcHandler?: () => void;
  status: RatingStatus;
  date: string;
}

const RatingInfoboxComponent: FC<RatingInfoboxComponent> = ({ status, date, retakeIarcHandler }): JSX.Element | null => {
  const { EPIC_FORTNITE_URL } = usePublicRuntimeConfig();
  const { t, i18n } = useTranslation();
  const docLang = toDocLocale(i18n.language);
  const classes = useStyles();
  const session = useAuthSession();
  const { project } = useProject();
  const hasIPPublishingAccess = canAccess(session, PERMISSION.LEGO_FEATURE);
  const hasFallGuysAccess = canAccess(session, PERMISSION.FALL_GUYS_FEATURE);
  const isLegoProject = checkProjectSysMetaIncludesGameFeature(project?.sysMeta, GAME_FEATURE.LEGO);
  const isFallGuysProject = checkProjectSysMetaIncludesGameFeature(project?.sysMeta, GAME_FEATURE.FALL_GUYS);

  let infobox: JSX.Element | null = null;

  if (status === RatingStatus.SUCCESS) {
    infobox = (
      <InfoBox
        severity="success"
        onSubmit={retakeIarcHandler}
        submitButtonText={t('publish.stepper.rating.info-box.button.retake-questionnaire')}
      >
        <Trans
          i18nKey="publish.stepper.rating.info-box.success.status"
          components={{ span: <span className={classes.infoBoxDescription} /> }}
          values={{ date }}
        />
      </InfoBox>
    );
  }

  if (status === RatingStatus.MATURE) {
    infobox = (
      <InfoBox
        severity="error"
        className={classes.additionalPadding}
        onSubmit={retakeIarcHandler}
        submitButtonText={t('publish.stepper.rating.info-box.button.retake-questionnaire')}
      >
        {hasIPPublishingAccess && isLegoProject && (
          <Trans
            i18nKey="publish.stepper.rating.info-box.mature.status.brand"
            values={{ brand: t('projectCategory.lego') }}
            components={{
              span: <span className={classes.infoBoxDescription} />,
              a: (
                <CommonLink
                  href={getLegoBrandAndCreatorGuidelinesInFortniteCreativeUrl(docLang)}
                  target="_blank"
                  rel="noopener noreferrer"
                  additionalClasses={classes.whiteLink}
                />
              ),
              img: <Image src={OpenIcon} alt="open in new window" width={16} height={16} />,
            }}
          />
        )}

        {hasFallGuysAccess && isFallGuysProject && (
          <Trans
            i18nKey="publish.stepper.rating.info-box.mature.status.fall-guys"
            components={{
              span: <span className={classes.infoBoxDescription} />,
              a: (
                <CommonLink
                  href={getWorkingWithFallGuysIslandsInFortniteCreativeUrl(docLang)}
                  target="_blank"
                  rel="noopener noreferrer"
                  additionalClasses={classes.whiteLink}
                />
              ),
              img: <Image src={OpenIcon} alt="open in new window" width={16} height={16} />,
            }}
          />
        )}

        {!(hasIPPublishingAccess && isLegoProject) && !(hasFallGuysAccess && isFallGuysProject) && (
          <Trans
            i18nKey="publish.stepper.rating.info-box.mature.status"
            components={{
              span: <span className={classes.infoBoxDescription} />,
              a: (
                <CommonLink
                  href={EPIC_FORTNITE_URL + FORTNITE_CONTENT_GUIDELINES_PATH}
                  target="_blank"
                  rel="noopener noreferrer"
                  additionalClasses={classes.whiteLink}
                />
              ),
            }}
          />
        )}
      </InfoBox>
    );
  }

  if (status === RatingStatus.PARTIAL) {
    infobox = (
      <InfoBox
        severity="warning"
        className={classes.additionalPadding}
        onSubmit={retakeIarcHandler}
        submitButtonText={t('publish.stepper.rating.info-box.button.retake-questionnaire')}
      >
        {hasFallGuysAccess && isFallGuysProject && (
          <Trans
            i18nKey="publish.stepper.rating.info-box.partially.status.fall-guys"
            components={{
              span: <span className={classes.infoBoxDescription} />,
              a: (
                <CommonLink
                  href={getWorkingWithFallGuysIslandsInFortniteCreativeUrl(docLang)}
                  target="_blank"
                  rel="noopener noreferrer"
                  additionalClasses={classes.blackLink}
                />
              ),
              img: <Image src={OpenIconBlack} alt="open in new window" width={16} height={16} />,
            }}
          />
        )}

        {hasIPPublishingAccess && isLegoProject && (
          <Trans
            i18nKey="publish.stepper.rating.info-box.partially.status.brand"
            values={{ brand: t('projectCategory.lego') }}
            components={{
              span: <span className={classes.infoBoxDescription} />,
              a: (
                <CommonLink
                  href={getLegoBrandAndCreatorGuidelinesInFortniteCreativeUrl(docLang)}
                  target="_blank"
                  rel="noopener noreferrer"
                  additionalClasses={classes.blackLink}
                />
              ),
              img: <Image src={OpenIconBlack} alt="open in new window" width={16} height={16} />,
            }}
          />
        )}

        {!(hasIPPublishingAccess && isLegoProject) && !(hasFallGuysAccess && isFallGuysProject) && (
          <Trans
            i18nKey="publish.stepper.rating.info-box.partially.status"
            components={{
              span: <span className={classes.infoBoxDescription} />,
              a: (
                <CommonLink
                  href={EPIC_FORTNITE_URL + FORTNITE_CONTENT_GUIDELINES_PATH}
                  target="_blank"
                  rel="noopener noreferrer"
                  additionalClasses={classes.blackLink}
                />
              ),
            }}
          />
        )}
      </InfoBox>
    );
  }

  return infobox;
};

export default RatingInfoboxComponent;
