import { DATA_AUTHORITY, ORIGINATING_ISLAND_ID } from './constants';

export function resolveProjectOriginAndAuthority(sysMeta?: Record<string, unknown> & { [DATA_AUTHORITY]?: { dataAuthority?: string } }): {
  origin: 'FNC' | 'UEFN';
  authority: 'FNC' | 'UEFN';
} {
  // if an originating island is set, this project's origin is FNC, otherwise UEFN.
  const origin: 'FNC' | 'UEFN' = sysMeta?.[ORIGINATING_ISLAND_ID] ? 'FNC' : 'UEFN';

  let authority: 'FNC' | 'UEFN' = origin;

  // check if an active data authority has been explicitly set...
  if (sysMeta?.[DATA_AUTHORITY]?.dataAuthority)
    // if it's an island use FNC, otherwise UEFN.
    authority = sysMeta?.[DATA_AUTHORITY]?.dataAuthority === 'island' ? 'FNC' : 'UEFN';

  return { origin, authority };
}
