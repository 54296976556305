import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import type { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputAdornment: {
      // @ts-ignore
      pointerEvents: 'all !important',
      marginRight: theme.spacing(7),
      marginBottom: theme.spacing(1),
    },
    textField: {
      direction: theme.direction,
      borderRadius: theme.spacing(0.8),
      background: `${theme.palette.shade5} !important`,
      '& .MuiInputBase-root': {
        opacity: 'inherit !important',
      },
      '& fieldset': {
        border: 'none',
      },
      '& label': {
        opacity: 'inherit !important',
      },
      '& input': {
        textOverflow: 'ellipsis',
        '-webkit-text-fill-color': `${theme.palette.shade1} !important`,
      },
      '& .MuiInputBase-input': {
        color: theme.palette.shade1,
      },
    },
    editHeaderButton: {
      '&:hover': {
        filter: theme.palette.brightnessUp,
        background: 'none',
      },
      padding: theme.spacing(1),
      marginTop: theme.spacing(1),
      position: 'absolute',
      display: 'flex',
      '& svg': {
        width: 25,
        height: 25,
        '& path': {
          fill: theme.palette.shade3,
        },
      },
    },
  }),
);

export default useStyles;
