import { CharacterCountAdornment } from '@epic-ui/components';
import { TextFieldProps as BaseTextFieldProps, InputAdornment, TextField } from '@mui/material';
import { clsx } from 'clsx';

import { withMuiRTL } from '@/hoc/withMuiRTL';

import useStyles from './TextField.styles';

export type TextFieldProps = Omit<BaseTextFieldProps, 'variant'> & {
  // Add any additional props here
  maxLength: number;
  startAdornmentText?: string;
  value?: string;
};

const TextFieldComponent = (props: TextFieldProps): JSX.Element => {
  const classes = useStyles();
  const { maxLength, value = '', className, fullWidth = true, startAdornmentText, InputProps, ...rest } = props;
  const valueLength = value.length;
  const adornment = {
    startAdornment: startAdornmentText && (
      <InputAdornment position="start" className={classes.startAdornment}>
        {startAdornmentText}
      </InputAdornment>
    ),
    endAdornment: maxLength && (
      <InputAdornment position="end">
        <CharacterCountAdornment max={maxLength} value={valueLength || 0} className={classes.countAdornment} />
      </InputAdornment>
    ),
  };

  return (
    <TextField
      {...rest}
      className={clsx(classes.textField, className, valueLength > maxLength && classes.limitError)}
      InputProps={InputProps || adornment}
      fullWidth={fullWidth}
      value={value}
    />
  );
};

export default withMuiRTL(TextFieldComponent);
