import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) =>
  createStyles({
    additionalPadding: {
      paddingTop: `${theme.spacing(2.8)} !important`,
      paddingBottom: `${theme.spacing(2.8)} !important`,
    },
    infoBoxDescription: {
      fontWeight: 400,
    },
    whiteLink: {
      color: theme.palette.commonWhite,
      fontWeight: 400,
    },
    blackLink: {
      color: theme.palette.commonBlack,
      fontWeight: 400,
    },
  }),
);

export default useStyles;
