export enum IPContentType {
  TITLE = 'Title',
  THUMBNAIL = 'Thumbnail',
  DESCRIPTION = 'Description',
  GAMEPLAY = 'Gameplay',
}

export enum IPContentMatchType {
  IDENTICAL = 'Identical',
  MODIFICATION = 'Modification',
  UNIQUE = 'Unique',
}
export enum IPContentEventType {
  CREATED = 'CREATED',
  ESCALATED = 'ESCALATED',
  STATE_CHANGED = 'STATE_CHANGED',
  ASSIGNMENT = 'ASSIGNMENT',
  COMMENT = 'COMMENT',
  APPEALED = 'APPEALED',
  APPEAL_STARTED = 'APPEAL_STARTED',
  WITHDRAWN = 'WITHDRAWN',
  CREATOR_QUESTIONED = 'CREATOR_QUESTIONED',
  CREATOR_REPLIED = 'CREATOR_REPLIED',
  INITIAL_ASSESSMENT = 'INITIAL_ASSESSMENT',
  ASSESSMENT = 'ASSESSMENT',

  TICKET_CREATED = 'SALESFORCE_TICKET_CREATED',
  TICKET_FAILED = 'SALESFORCE_TICKET_FAILED',
}
export enum CaseState {
  TO_DO = 'TO_DO',
  ESCALATED = 'ESCALATED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE_ACTION = 'COMPLETE_ACTION',
  COMPLETE_NO_ACTION = 'COMPLETE_NO_ACTION',
  ARCHIVE = 'ARCHIVE',
}
export enum CaseType {
  REPORT = 'Report',
  APPEAL = 'Appeal',
  WITHDRAWAL = 'Withdrawal',
}
export enum CaseRecordType {
  DETAILS = 'Details',
  AUDIT = 'Audit',
}
export const TEST_ASSIGNED_TO = [
  {
    id: 'qwpo23i434ioierqwe',
    title: 'John Doe',
  },
  {
    id: '675s233i434ioiert4',
    title: 'Jane Doe',
  },
];
export const ALL_IP_CASE_TYPES = Object.values(CaseType);
export const ALL_IP_CONTENT_TYPES = Object.values(IPContentType);
export const ALL_IP_CONTENT_MATCH_TYPES = Object.values(IPContentMatchType);
export interface CopiedContentReport {
  reportingLinkCode: string; // original island reference
  reportedLinkCode: string; // island reference reported to have IP content violation
  copiedContent: IPContentType[];
  report?: string;
}
export interface DisputeSideAssets {
  islandTitle: string;
  islandDescription: string;
  islandThumbnail: string;
  islandLastUpdated: Date;
  islandPlayers: number;
  islandsByAccount: number;
}
export interface DisputeSideDetails {
  accountId: string;
  accountDisplayName: string;
  accountEmail?: string;
  accountCreated?: Date;
  accountFirstName?: string;
  accountLastName?: string;
  creatorCode?: string;
  islandCode: string;
  islandCodeVersion?: number;
  islandCreated: Date;
  islandProgramJoined?: Date;
  projectId: string;
  assetsRecorded: DisputeSideAssets;
  assetsLatest?: DisputeSideAssets;
}
export interface DisputeCase extends Omit<CopiedContentReport, 'reportingLinkCode' | 'reportedLinkCode'> {
  caseId: string;
  reportedSide: DisputeSideDetails;
  reportingSide: DisputeSideDetails;
  created: Date;
  updated: Date;
  state: CaseState;
  type: CaseType;
  assignedTo?: string; // moderator user reference
  withdrawn?: Date; // withdrawal date
  appealCnt?: number; // potentially could appeal more than once
  appeal?: DisputeAppeal; // latest appeal
  initialAssessment?: DisputeAssessment;
  finalAssessment?: DisputeAssessment;
  appealAssessment?: DisputeAppealAssessment;
  ticketId?: string; // salesforce ticket id
}
export interface DisputeEvent {
  eventId: string;
  caseId: string;
  userId?: string;
  displayName?: string; // could be system events
  eventType: IPContentEventType;
  created: Date;
  text: string;
  prevState?: CaseState;
  newState?: CaseState;
}
export interface DisputeStrikes {
  caseId: string;
  state: CaseState;
  type: CaseType;
  copiedContent: IPContentType[];
  created: Date;
  updated: Date;
  report: string;
  reportedSide: DisputeSideDetails;
  reportingSide: DisputeSideDetails;
}
export interface DisputeAssessment {
  comment: string;
  title: IPContentMatchType;
  description: IPContentMatchType;
  thumbnail: IPContentMatchType;
  timestamp?: Date; // assessment date
}
export interface DisputeAppeal {
  comment: string;
  assets?: string[]; // some attachment references?
  timestamp?: Date; // appeal date
}
export interface DisputeAppealAssessment {
  comment: string;
  valid: boolean;
  timestamp?: Date; // assessment date
}
export interface DisputeWithdrawal {
  comment: string;
}
export interface DisputeCaseComment {
  comment: string;
}
export interface DisputeCaseUpdate {
  state?: CaseState;
  assignment?: string;
  comment?: string;
}
export interface OrderBy {
  field: string;
  desc: boolean;
}
export interface DisputeListingParams {
  lastRangeKey?: string;
  limit?: number;
  state?: CaseState[];
  type?: CaseType[];
  assignedTo?: string[];
  search?: string;
  orderBy?: OrderBy;
}
export type LineageRequestAlgo = 'dreamsim' | 'pdq' | 'dreamsim+pdq';
export type LineageMatchMetaAlgo = Exclude<LineageRequestAlgo, 'dreamsim+pdq'>;
export interface LineageMatchMeta {
  algorithm: LineageMatchMetaAlgo;
}
export interface LineageMatch {
  score: number;
  island_code: string;
  island_version: number;
  published: Date;
  creator: string;
  public_url: string;
  matching_metadata: LineageMatchMeta;
  link_manager_url: string;
}
export interface LineageMatchResponse {
  reporter_matches: Array<LineageMatch>;
  pair_similarity: number;
}
export interface LineageRequestMatchAlgoScores {
  dreamsim?: number;
  pdq?: number;
}
export interface LineageRequestParams {
  max_k?: number;
  algorithm?: LineageRequestAlgo;
  min_scores?: LineageRequestMatchAlgoScores;
}
export interface LineageRequest {
  reporter_thumbnail: string;
  reported_thumbnail: string;
  parameters?: LineageRequestParams;
}
