import * as React from 'react';
import { clsx } from 'clsx';

import useStyles from './common-link.styles';

interface CommonLinkProps {
  href?: string;
  download?: any;
  target?: string;
  rel?: string;
  additionalClasses?: string | string[];
  onClick?: (e: React.SyntheticEvent) => void;
  testId?: string;
}

const CommonLink: React.FC<React.PropsWithChildren<CommonLinkProps>> = ({
  href,
  download,
  onClick,
  target,
  rel,
  children,
  additionalClasses,
  testId,
}): JSX.Element => {
  const classes = useStyles();

  return (
    <a
      data-testid={testId}
      className={clsx(classes.link, additionalClasses)}
      rel={rel}
      href={href}
      onClick={onClick}
      download={download}
      target={target}
    >
      {children}
    </a>
  );
};

export default CommonLink;
