import { IcpState } from '@creator-portal/common/ecp';
import { DEFAULT_GROUP_NAME } from '@creator-portal/common/teams/constants';

import { MONETIZATION_URL } from '@/config/common/constants';
import {
  getAcceptInviteJoinPlaytestUrl,
  getRemovePlaytesterUrl,
  getTeamInfoUrl,
  getTeamMembersUrl,
  getTeamPlaytestersUrl,
  getTeamsUrl,
} from '@/config/teams/functionalConstants';

import { PublishingUrl } from '@/services/publishing/publishing-service';

import * as Xhr from '@/util/xhr';

import type { TeamValues } from '@/components/teams/create-or-edit-team-dialog/create-or-edit-team-dialog.component';
import type { TeamDefaultInfo } from '@/components/teams/switch-team-dialog/switch-team-dialog.component';
import type { TeamMember } from '@/pages/[team]/teams';
import type { PlaytestCodeInfo, PlaytestGroup, ProjectDoc } from '@creator-portal/common/content-service/types';
import type {
  LinkCodeInfo,
  PagedResults,
  TeamDetailResult,
  TeamMemberInfo,
  TeamMembershipRole,
  TeamSearchResult,
} from '@creator-portal/common/types';

export interface ToastError {
  data?: {
    message: string;
  };
}

const xhr = Xhr.getInstance();

export namespace TeamsUrl {
  export const getMonetizationUrl = (teamId: string) => `${MONETIZATION_URL}?team=${encodeURIComponent(teamId)}` as const;
  export const removeTeamUrl = (teamId: string) => `/api/vk/v1/teams/${encodeURIComponent(teamId)}` as const;
  export const getTeamOwnerStatusInIcp = (teamId: string) => `/api/vk/v1/teams/${encodeURIComponent(teamId)}/team-owner-status` as const;
}

export const createNewTeam = async (data: TeamDefaultInfo): Promise<TeamSearchResult> => {
  const response = await xhr.fetchJson<TeamSearchResult>(getTeamsUrl(), {
    method: 'POST',
    body: JSON.stringify({
      name: data.teamName,
      description: data.teamDescription,
      emailDomains: [],
    }),
  });
  Xhr.throwOnFailure(response);

  return response.data;
};

export const changeMemberRole = async (
  accountId: string,
  teamId: string,
  cpRole?: TeamMembershipRole,
  oldCpRole?: TeamMembershipRole,
): Promise<TeamMemberInfo> => {
  const url = getTeamMembersUrl(teamId);

  const response = await xhr.fetchJson<TeamMemberInfo>(url, {
    method: 'PUT',
    body: JSON.stringify({
      memberAccountIdOrDisplayName: `#${accountId}`,
      cpRole,
      oldCpRole,
    }),
  });

  Xhr.throwOnFailure(response);

  return response.data;
};

export const updateTeam = async (teamId: string, data: TeamValues): Promise<TeamSearchResult> => {
  const response = await xhr.fetchJson<TeamSearchResult>(`/api/vk/v1/teams/${encodeURIComponent(teamId)}`, {
    method: 'PUT',
    body: JSON.stringify({
      name: data.teamName,
      description: data.teamDescription,
    }),
  });
  Xhr.throwOnFailure(response);

  return response.data;
};

export const apiCreateNewPlaytestGroup = async (teamId: string, xhr: Xhr.XhrService): Promise<PlaytestGroup> => {
  const response = await xhr.fetchJson<PlaytestGroup>(`/api/vk/v1/teams/${encodeURIComponent(teamId)}/playtest`, {
    method: 'POST',
  });
  Xhr.throwOnFailure(response);

  return response.data;
};

export const apiGetPlaytestGroupInfo = async (projectId: string, xhr: Xhr.XhrService): Promise<PlaytestCodeInfo[]> => {
  const response = await xhr.fetchJson<PlaytestCodeInfo[]>(PublishingUrl.projectPlaytestInfo(projectId), {
    method: 'GET',
  });
  Xhr.throwOnFailure(response);

  return response.data;
};

export const getDefaultGroupFromApi = async (projectId: string, xhr: Xhr.XhrService): Promise<PlaytestCodeInfo | undefined> => {
  const playTestGroupResponse = await apiGetPlaytestGroupInfo(projectId, xhr);
  const defaultGroup = playTestGroupResponse.find((el) => el.group.name === DEFAULT_GROUP_NAME);

  return defaultGroup;
};

export const removeTeamMember = async (memberAccountId: string, teamId: string): Promise<null> => {
  const response = await xhr.fetchJson<null>(
    `/api/vk/v1/teams/${encodeURIComponent(teamId)}/members/${encodeURIComponent(memberAccountId)}`,
    {
      method: 'DELETE',
    },
  );

  Xhr.throwOnFailure(response);

  return null;
};

export const removeTeam = async (teamId: string): Promise<void> => {
  const response = await xhr.fetchJson<null>(TeamsUrl.removeTeamUrl(teamId), {
    method: 'DELETE',
  });

  Xhr.throwOnFailure(response);
};

export const inviteTeamMembers = async (
  teamMembersFormData: TeamMember[],
  teamId: string,
  flow: string,
): Promise<(Xhr.ErrorPayload | TeamMemberInfo)[]> => {
  const jobs = teamMembersFormData.map((teamMember) =>
    xhr.fetchJson<TeamMemberInfo>(getTeamMembersUrl(teamId), {
      method: 'PUT',
      body: JSON.stringify({
        cpRole: teamMember.role,
        memberAccountIdOrDisplayName: teamMember.epicId,
        flow,
      }),
    }),
  );

  const result = await Promise.all(jobs).then((responses) => Promise.all<Xhr.ErrorPayload | TeamMemberInfo>(responses.map((r) => r.data)));

  return result;
};

export const apiAcceptInvite = async (xhr: Xhr.XhrService, teamId: string): Promise<Xhr.XhrResponse<PagedResults<TeamSearchResult>>> => {
  const acceptInviteResponse = await xhr.fetchJson<PagedResults<TeamSearchResult>>(
    `/api/vk/v1/teams/${encodeURIComponent(teamId)}/members/accept`,
    {
      method: 'PUT',
    },
  );
  Xhr.throwOnFailure(acceptInviteResponse);

  return acceptInviteResponse;
};

export const apiAcceptInviteJoinPlaytest = async (teamId: string, codeId: string): Promise<Xhr.XhrResponse<unknown>> => {
  const xhr = Xhr.getInstance();

  const response = await xhr.fetchJson<unknown>(getAcceptInviteJoinPlaytestUrl(teamId, codeId), { method: 'POST' });
  Xhr.throwOnFailure(response);

  return response;
};

export const apiGetTeams = async (xhr: Xhr.XhrService): Promise<Xhr.XhrResponse<PagedResults<TeamSearchResult>>> => {
  const res = await xhr.fetchJson<PagedResults<TeamSearchResult>>(getTeamsUrl(), { method: 'GET' });
  Xhr.throwOnFailure(res);

  return res;
};

export const apiGetTeamMembers = async (xhr: Xhr.XhrService, teamId: string): Promise<Xhr.XhrResponse<PagedResults<TeamMemberInfo>>> => {
  const teamMembersResponse = await xhr.fetchJson<PagedResults<TeamMemberInfo>>(getTeamMembersUrl(teamId), { method: 'GET' });
  Xhr.throwOnFailure(teamMembersResponse);

  return teamMembersResponse;
};

export const apiGetTeamPlaytesters = async (
  xhr: Xhr.XhrService,
  teamId: string,
  playtestGroupId: string,
  limit: number,
): Promise<TeamMemberInfo[]> => {
  const teamPlaytestersResponse = await xhr.fetchJson<PagedResults<TeamMemberInfo>>(getTeamPlaytestersUrl(teamId, playtestGroupId, limit), {
    method: 'GET',
  });
  Xhr.throwOnFailure(teamPlaytestersResponse);

  return teamPlaytestersResponse?.data?.results || [];
};

export const apiCreateOrUpdatePlaytestLinkFromBaseCode = async (
  projectId: string,
  ptgId: string,
  teamId: string,
  baseCode: LinkCodeInfo,
): Promise<Xhr.XhrResponse<Record<string, any>>> => {
  const response = await xhr.fetchJson<Record<string, any>>(
    `/api/vk/v1/projects/${encodeURIComponent(projectId)}/publish/playtest/${encodeURIComponent(ptgId)}`,
    {
      method: 'POST',
      body: JSON.stringify({
        teamId,
        baseCode: baseCode.linkCode,
        baseCodeVersion: baseCode.version,
      }),
    },
  );
  Xhr.throwOnFailure(response);

  return response;
};

export const removePlaytester = async (userId: string, groupId: string, teamId: string): Promise<null> => {
  const response = await xhr.fetchJson<null>(getRemovePlaytesterUrl(teamId, groupId, userId), {
    method: 'DELETE',
  });

  Xhr.throwOnFailure(response);

  return null;
};

export const transferProjects = async (
  projectIds: string[],
  teamId: string | null,
  previousTeamId: string | null,
  flow: string,
): Promise<(Xhr.ErrorPayload | ProjectDoc)[]> => {
  const jobs = projectIds.map((projectId) =>
    xhr.fetchJson<ProjectDoc>(`/api/vk/v1/projects/${encodeURIComponent(projectId)}/transfer`, {
      method: 'POST',
      body: JSON.stringify({ teamId, flow, previousTeamId }),
    }),
  );

  const result = await Promise.all(jobs).then((responses) => Promise.all<Xhr.ErrorPayload | ProjectDoc>(responses.map((r) => r.data)));

  return result;
};

export const extractErrorMessage = (data: ToastError, defaultError: string): string => data?.data?.message?.split('|')[0] || defaultError;

export const fetchTeamInfo = async (xhr: Xhr.XhrService, teamId: string): Promise<TeamDetailResult> => {
  const selectedTeamResponse = await xhr.fetchJson<TeamDetailResult>(getTeamInfoUrl(teamId), { method: 'GET' });
  Xhr.throwOnFailure(selectedTeamResponse);

  return selectedTeamResponse.data;
};

export const apiGetTeamOwnerStatusInIcp = async (teamId: string, xhr: Xhr.XhrService): Promise<{ ownerIcpEnrollState: IcpState }> => {
  const url = TeamsUrl.getTeamOwnerStatusInIcp(teamId);
  const teamOwnerStatusResponse = await xhr.fetchJson<{ ownerIcpEnrollState: IcpState }>(url);
  Xhr.throwOnFailure(teamOwnerStatusResponse);

  return teamOwnerStatusResponse.data;
};
