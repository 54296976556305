'use client';

import { createContext } from 'react';

import type { CreatorPrivileges } from '@creator-portal/common/types';
import type { ReactNode } from 'react';

interface ContextProps {
  children: ReactNode;
  value?: CreatorPrivileges | null;
}

export const CreatorPrivilegesContext = createContext<CreatorPrivileges | undefined | null>(undefined);

/** React context for providing CreatorPrivileges */
export function CreatorPrivilegesProvider({ value, children }: ContextProps): JSX.Element {
  return <CreatorPrivilegesContext.Provider value={value}>{children}</CreatorPrivilegesContext.Provider>;
}
