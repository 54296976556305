const getEventPath = (event: React.MouseEvent): EventTarget[] => {
  const nativeEvent = event.nativeEvent;
  if (nativeEvent.composedPath) {
    return nativeEvent.composedPath();
  }
  // Fallback for older browsers
  const path: EventTarget[] = [];
  for (let node = event.target as HTMLElement | null; node; node = node.parentElement) {
    path.push(node);
  }
  return path;
};

export default getEventPath;
