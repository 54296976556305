export const TEAM_PROFILE_IMAGE_NAME = 'team-profile-img';
export const IMG_ACCEPTED_FORMATS = ['image/png', 'image/jpeg', 'image/jpg'];
export enum FILE_TYPES {
  video = 'video',
  image = 'image',
}
export enum PROFILE_IMG_TYPES {
  cover = 'cover',
  profile = 'profile',
}

export const IMG_NAMES = {
  [PROFILE_IMG_TYPES.cover]: 'cover-img',
  [PROFILE_IMG_TYPES.profile]: 'profile-img',
};
