import * as React from 'react';
import { useTranslation } from 'next-i18next';

import Dialog, { DialogType } from '@/components/ui/Dialog';

interface Props {
  open: boolean;
  onClose: () => void;
  cancelUploading: () => void;
  isUploading: boolean;
  description: string;
}

const CancelUploadDialog: React.FunctionComponent<Props> = ({ open, onClose, cancelUploading, isUploading, description }) => {
  const { t } = useTranslation();
  const errorDescription = (
    <>
      {t('error-description')}
      <br />
      {t('cancel-upload.error-additional-info')}
    </>
  );
  const warningDescription = description;

  return (
    <Dialog
      open={open}
      dialogType={isUploading ? DialogType.WARNING : DialogType.ERROR}
      title={isUploading ? t('cancel-upload.title') : t('error-title')}
      description={isUploading ? warningDescription : errorDescription}
      onClose={onClose}
      onSecondaryButton={onClose}
      onPrimaryButton={isUploading && cancelUploading}
      secondaryButtonText={isUploading ? t('button.continue-upload') : t('button.continue')}
      primaryButtonText={isUploading && t('button.cancel-upload')}
    />
  );
};

export default CancelUploadDialog;
