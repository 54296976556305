import { ar, de, en_US, es_ES, es_MX, fr, it, ja, ko, pl, pt_BR, ru, tr } from '@creator-portal/locales';
import { Locale } from 'date-fns/locale';
import * as locales from 'date-fns/locale';

export const getStoreByIarcLang = (lang: string): { [key: string]: string } | null => {
  switch (lang) {
    case 'ar-SA':
      return ar;
    case 'de-DE':
      return de;
    case 'es-ES':
      return es_ES;
    case 'fr-FR':
      return fr;
    case 'it-IT':
      return it;
    case 'ja-JP':
      return ja;
    case 'ko-KR':
      return ko;
    case 'pl-PL':
      return pl;
    case 'pt-BR':
      return pt_BR;
    case 'ru-RU':
      return ru;
    case 'tr-TR':
      return tr;
    case 'en':
      return en_US;
    default:
      return null;
  }
};

export const getStoreByLang = (lang: string): { [key: string]: string } | null => {
  switch (lang) {
    case 'ar':
      return ar;
    case 'de':
      return de;
    case 'es-ES':
      return es_ES;
    case 'es-MX':
      return es_MX;
    case 'fr':
      return fr;
    case 'it':
      return it;
    case 'ja':
      return ja;
    case 'ko':
      return ko;
    case 'pl':
      return pl;
    case 'pt-BR':
      return pt_BR;
    case 'ru':
      return ru;
    case 'tr':
      return tr;
    case 'en-US':
      return en_US;
    default:
      return null;
  }
};

export const getTranslationByKey = (iarcLang: string, key: string): string => {
  const store = getStoreByLang(iarcLang) || getStoreByIarcLang(iarcLang) || (en_US as { [key: string]: string });

  return store[key] || key;
};

export const getTagTranslation = (iarcLang: string, tagValue: string): string => {
  // It is necessary in order to fix incorrect handling of keys that contain the ":" character. (for example 'difficulty: easy')
  const tagInStore = tagValue.includes(':') ? tagValue.replaceAll(':', '.') : tagValue;

  return getTranslationByKey(iarcLang, `options.island-tags.${tagInStore}`);
};

interface Locales {
  [key: string]: Locale;
}

export const getDateFnsLocale = (lang: string): Locale => {
  const dateFnsLocales = locales as Locales;
  const locale: Locale = dateFnsLocales[lang];

  switch (lang) {
    case 'es-ES':
      return locales['es'];
    case 'es-MX':
      return locales['es'];
    case 'pt-BR':
      return locales['ptBR'];

    default:
      if (locale) {
        return locale;
      }
      return locales['enUS'];
  }
};
