import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import type { Theme } from '@mui/material/styles';

const LEFT_NAV_WIDTH_DESKTOP = 240;
const LEFT_NAV_WIDTH_TABLET = 212;
const LEFT_NAV_WIDTH_MOBILE = 345;
const LEFT_NAV_WIDTH_COLLAPSED = 72;

export enum ContentSpacing {
  DESKTOP = 16,
  TABLET = 10,
  MOBILE = 8,
}

export const WorkSansFont = 'Work Sans, Inter';

export const HEADER_HEIGHT = '72px';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: '100vh',
      color: theme.palette.shade0,
    },
    standardRootBackground: {
      // default bg before loading backgroundImage
      background: theme.palette.shade7,
      // bg after loading backgroundImage
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed',
      backgroundImage: `url(/${theme.palette.mode}-background.jpg) !important`,
    },
    enrollBackground: {
      // default bg before loading backgroundImage
      background: theme.palette.shade7,
      // bg after loading backgroundImage
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
      backgroundImage: `url(/enroll/enroll_bg_${theme.palette.mode}.webp) !important`,
    },
    mainNavigation: {
      backgroundColor: theme.palette.shade7,
      direction: theme.direction,
      position: 'sticky',
      top: HEADER_HEIGHT,
      minHeight: `calc(100vh - ${HEADER_HEIGHT})`,
      maxHeight: `calc(100vh - ${HEADER_HEIGHT})`,
    },
    mainContentWrapper: {
      display: 'flex',
    },
    mainContainerWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: '1',
      direction: 'ltr',
      flexBasis: '100%',
      maxWidth: '100%',
      [theme.breakpoints.up('lg')]: {
        flexBasis: `calc(100% - ${LEFT_NAV_WIDTH_TABLET}px)`,
        maxWidth: `calc(100% - ${LEFT_NAV_WIDTH_TABLET}px)`,
      },
      [theme.breakpoints.up('xl')]: {
        flexBasis: `calc(100% - ${LEFT_NAV_WIDTH_DESKTOP}px)`,
        maxWidth: `calc(100% - ${LEFT_NAV_WIDTH_DESKTOP}px)`,
      },
      '.project-sub-nav-visible &': {
        flexBasis: '100%',
        maxWidth: '100%',
        [theme.breakpoints.up('lg')]: {
          flexBasis: `calc(100% - ${LEFT_NAV_WIDTH_COLLAPSED}px)`,
          maxWidth: `calc(100% - ${LEFT_NAV_WIDTH_COLLAPSED}px)`,
        },
      },
    },
    mainContainerWrapperFullWidth: {
      [theme.breakpoints.up('md')]: {
        flexBasis: '100%',
        maxWidth: '100%',
      },
      [theme.breakpoints.up('lg')]: {
        flexBasis: '100%',
        maxWidth: '100%',
      },
      [theme.breakpoints.up('xl')]: {
        flexBasis: '100%',
        maxWidth: '100%',
      },
    },
    mainContainer: {
      flexGrow: 1,
      minHeight: `calc(100vh - ${HEADER_HEIGHT})`,
    },
    sideBar: {
      height: '100%',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing(18),
      [theme.breakpoints.up('lg')]: {
        paddingTop: theme.spacing(4.8),
      },
      '.project-sub-nav-visible &': {
        paddingTop: theme.spacing(6.8),
      },
    },
    appBarIcon: {
      fontSize: theme.typography.pxToRem(22),
    },
    drawer: {
      color: theme.palette.commonBlueGradient3,
      height: '100%',
    },
    drawerPaper: {
      color: theme.palette.shade2,
      background: theme.palette.shade7,
      borderRight: 'none',
      overflowX: 'hidden',
      overflowY: 'auto',
      width: LEFT_NAV_WIDTH_MOBILE,
      '&::-webkit-scrollbar-thumb': {
        borderRadius: theme.spacing(1),
        backgroundColor: theme.palette.shade5,
        border: '2px solid white',
      },
      '&::-webkit-scrollbar': {
        width: '8px',
        // scrollMarginRight: theme.spacing(4),
      },
      [theme.breakpoints.up('lg')]: {
        width: LEFT_NAV_WIDTH_TABLET,
        height: '100%',
        position: 'static',
      },
      [theme.breakpoints.up('xl')]: {
        width: LEFT_NAV_WIDTH_DESKTOP,
      },
      '.project-sub-nav-visible &': {
        width: LEFT_NAV_WIDTH_COLLAPSED,
      },
      boxShadow: 'none',
    },
    navLeftContainer: {
      overflow: 'auto',
      width: LEFT_NAV_WIDTH_DESKTOP,
      display: 'inline-block',
      position: 'fixed',
    },
    navLeftPaper: {
      width: LEFT_NAV_WIDTH_DESKTOP,
      color: theme.palette.commonBlueGradient3,
      minHeight: `calc(100vh - ${HEADER_HEIGHT})`,
    },
    content: {
      flexGrow: 1,
      height: '100%',
    },
    lastNavBlock: {
      marginBottom: theme.spacing(8),
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(24),
      },
    },
    wrapper: {
      position: 'fixed',
      bottom: 0,
      backgroundColor: theme.palette.background.default,
    },
    button: {
      bottom: 0,
      width: 315,
      margin: theme.spacing(3),
    },
    bottomBar: {
      display: 'flex',
      borderTop: `1px solid ${theme.palette.shade4}`,
      backgroundColor: theme.palette.background.default,
      '& .MuiList-root': {
        padding: theme.spacing(0.5, 2),
      },
    },
    switcherContainer: {
      display: 'flex',
      width: '52px',
      borderLeft: `1px solid ${theme.palette.shade4}`,
      '& .MuiButtonBase-root': {
        width: '100%',
        margin: theme.spacing(0.5, 2),
      },
      '& .MuiButtonBase-root:hover': {
        backgroundColor: `${theme.palette.shade5} !important`,
      },
    },
    navList: {
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
    },
    navLeftOption: {
      height: '50px',
      color: theme.palette.shade1,
      margin: theme.spacing(0),
      textTransform: 'uppercase',
      width: '260px',
      padding: theme.spacing(0, 16),

      '&:active': {
        background: theme.palette.shade7,
      },
      '& .MuiTypography-body1': {
        fontStyle: 'normal',
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.pxToRem(11),
        lineHeight: theme.typography.pxToRem(13),
        letterSpacing: theme.typography.pxToRem(0.75),
        [theme.breakpoints.up('lg')]: {
          fontSize: theme.typography.pxToRem(11),
        },
      },
      '& svg': {
        color: theme.palette.shade3,
      },
    },
    navLeftOptionHover: {
      '&:hover': {
        borderRadius: '5px',
        color: theme.palette.commonBlueGradient2,
        background: theme.palette.shade5,
        '& svg': {
          color: theme.palette.commonBlueGradient2,
        },
      },
    },
    listItemIcon: {
      display: 'flex',
      justifyContent: 'flex-start',
      minWidth: theme.spacing(4),
    },
    navLeftItem: {
      display: 'inline-block',
      fontSize: theme.typography.pxToRem(11),
    },
  }),
);

export default useStyles;
