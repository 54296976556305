import { Box, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/styles';

interface Props {
  cssClassName?: string;
  size?: string;
}

export default function CircularLoader({ cssClassName, size }: Props): JSX.Element {
  const theme = useTheme();

  return (
    <Box className={cssClassName} sx={{ color: theme.palette.shade3 }}>
      <CircularProgress color="inherit" size={size} />
    </Box>
  );
}
