import { RatingLinkMetadata } from '@creator-portal/common/links-service/types';
import {
  GetCertResponse,
  LinkCodeInfo,
  Product,
  QuestionnaireResponse,
  QuestionnaireUrlRequest,
  RatingBoardResponse,
  UpdateProductResponse,
} from '@creator-portal/common/types';

import * as Xhr from '@/util/xhr';

import { CertificateDetails } from '@/types/publishing';

export interface DescriptorResponse {
  descriptorId: number;
  descriptorText: string;
  localizedDescriptorText: string;
  descriptorShortText: string;
  ratingBoardId: number;
  ratingBoardShortText: string;
  categoryId: number;
  categoryName: string;
  rank: number;
}

export interface InteractiveElementResponse {
  interactiveElementId: number;
  interactiveElementShortText: string;
  interactiveElementText: string;
}

export type IarcQuestionnaireRequestModel = {
  projectId: string;
  creatorEmail: string;
  publicDeveloperEmail?: string;
  langCode: string;
};

export interface IarcLocalizedResponse {
  descriptorResponse: DescriptorResponse[];
  interactiveElementResponse: InteractiveElementResponse[];
}

export type IarcLocalizedQuery = {
  locale: string;
  descriptorIds?: string[];
  interactiveElementIds?: string[];
};

export namespace IarcUrl {
  export const generateQuestionnaire = (linkCode: string, version: number) =>
    `/api/iarc/v1/${encodeURIComponent(linkCode)}/${encodeURIComponent(version)}/questionnaire` as const;
  export const createCert = (requestId: string) => `/api/iarc/v1/requests/${encodeURIComponent(requestId)}/certs` as const;
  export const getRatingBoardData = (locale?: string | null) =>
    `/api/iarc/v1/rating-board${locale ? `?locale=${encodeURIComponent(locale)}` : ''}` as const;

  export const localizedRatingInfo = (query: IarcLocalizedQuery) => {
    const url = `/api/iarc/v1/localized?locale=${encodeURIComponent(query.locale)}`;
    let queryString = '';

    if (query.descriptorIds?.length) query.descriptorIds.forEach((el) => (queryString += `&descriptorId=${el}`));

    if (query.interactiveElementIds?.length) query.interactiveElementIds.forEach((el) => (queryString += `&elementId=${el}`));

    return url + queryString;
  };
  export const updateIarcEmail = (projectId: string, certId: string) =>
    `/api/iarc/v1/${encodeURIComponent(projectId)}/email/${encodeURIComponent(certId)}` as const;
  export const ratingInfo = (projectId: string, certId: string) =>
    `/api/iarc/v1/${encodeURIComponent(projectId)}/certs/${encodeURIComponent(certId)}` as const;
  export const _getRatingFromCertId = (certId: string) => `/api/admin/iarc/cert/${encodeURIComponent(certId)}` as const;
  export const _transformCertDetailsToRatingLinkMetadata = () => `/api/admin/iarc/transform` as const;
  export const _publishCert = (linkCode: string) => `/api/admin/iarc/publish/${encodeURIComponent(linkCode)}` as const;
  export const _generateQuestionnaire = () => `/api/admin/iarc/questionnaire` as const;
  export const _finalizeQuestionnaire = (requestId: string) => `/api/admin/iarc/requests/${encodeURIComponent(requestId)}/certs` as const;
}

export const generateQuestionnaire = async (
  buildCode: LinkCodeInfo,
  model: IarcQuestionnaireRequestModel,
): Promise<{ questionnaireUrl: string; requestId: string }> => {
  const xhr = Xhr.getInstance();
  const dto = {
    projectId: model.projectId?.trim(),
    developerEmail: model.creatorEmail?.trim(),
    publicDeveloperEmail: model.publicDeveloperEmail?.trim(),
    langCode: model.langCode,
  };

  const response = await xhr.fetchJson<{ questionnaireUrl: string; requestId: string }>(
    IarcUrl.generateQuestionnaire(buildCode.linkCode, buildCode.version),
    {
      method: 'POST',
      body: JSON.stringify(dto),
    },
  );

  Xhr.throwOnFailure(response);

  return response.data;
};

export const getRatingBoardData = async (
  locale: string | null,
  xhr: Xhr.XhrService = Xhr.getInstance(),
): Promise<RatingBoardResponse[]> => {
  const response = await xhr.fetchJson<RatingBoardResponse[]>(IarcUrl.getRatingBoardData(locale), {
    method: 'GET',
  });

  Xhr.throwOnFailure(response);

  return response.data;
};

export const getLocalizedContent = async (
  query: IarcLocalizedQuery,
  xhr: Xhr.XhrService = Xhr.getInstance(),
): Promise<IarcLocalizedResponse> => {
  const url = IarcUrl.localizedRatingInfo(query);
  const response = await xhr.fetchJson<IarcLocalizedResponse>(url);

  Xhr.throwOnFailure(response);

  return response.data;
};

export const getLocalizedContentList = async (
  queryList: IarcLocalizedQuery[],
  xhr: Xhr.XhrService = Xhr.getInstance(),
): Promise<IarcLocalizedResponse[]> => {
  const tasks: Promise<IarcLocalizedResponse>[] = [];

  queryList.forEach((query) => {
    tasks.push(getLocalizedContent(query, xhr));
  });

  return Promise.all(tasks);
};

export const updateIarcEmail = async (projectId: string, certId: string, email: string): Promise<void> => {
  const xhr = Xhr.getInstance();

  const response = await xhr.fetchJson(IarcUrl.updateIarcEmail(projectId, certId), {
    method: 'PUT',
    body: JSON.stringify({ email }),
  });

  Xhr.throwOnFailure(response);

  return;
};

export const getRatingData = async (
  projectId: string,
  certId: string,
  xhr: Xhr.XhrService = Xhr.getInstance(),
): Promise<CertificateDetails> => {
  const response = await xhr.fetchJson<CertificateDetails>(IarcUrl.ratingInfo(projectId, certId), {
    method: 'GET',
  });

  Xhr.throwOnFailure(response);

  return response.data;
};

export const getRatingDataList = async (
  projectId: string,
  certIdList: string[],
  xhr: Xhr.XhrService = Xhr.getInstance(),
): Promise<CertificateDetails[]> => {
  const tasks: Promise<CertificateDetails>[] = [];
  certIdList.forEach((certId) => {
    tasks.push(getRatingData(projectId, certId, xhr));
  });

  return Promise.all(tasks);
};

export const getRatingFromCertId = async (certId: string, xhr: Xhr.XhrService = Xhr.getInstance()): Promise<CertificateDetails> => {
  const response = await xhr.fetchJson<CertificateDetails>(IarcUrl._getRatingFromCertId(certId), {
    method: 'GET',
  });

  Xhr.throwOnFailure(response);

  return response.data;
};

export const transformCertDetailsToRatingLinkMetadata = async (certDetails: CertificateDetails): Promise<RatingLinkMetadata> => {
  const xhr = Xhr.getInstance();

  const response = await xhr.fetchJson<RatingLinkMetadata>(IarcUrl._transformCertDetailsToRatingLinkMetadata(), {
    method: 'POST',
    body: JSON.stringify(certDetails),
  });

  Xhr.throwOnFailure(response);

  return response.data;
};

export const publishCert = async (linkCode: string): Promise<UpdateProductResponse> => {
  const xhr = Xhr.getInstance();

  const response = await xhr.fetchJson<UpdateProductResponse>(IarcUrl._publishCert(linkCode), {
    method: 'PUT',
  });

  Xhr.throwOnFailure(response);

  return response.data;
};

export const generateQuestionnaireWithoutLink = async (questionnaireRequest: QuestionnaireUrlRequest): Promise<QuestionnaireResponse> => {
  const xhr = Xhr.getInstance();

  const response = await xhr.fetchJson<QuestionnaireResponse>(IarcUrl._generateQuestionnaire(), {
    method: 'POST',
    body: JSON.stringify(questionnaireRequest),
  });

  Xhr.throwOnFailure(response);

  return response.data;
};

export const finalizeQuestionnaire = async (requestId: string, product: Product): Promise<GetCertResponse> => {
  const xhr = Xhr.getInstance();

  const response = await xhr.fetchJson<GetCertResponse>(IarcUrl._finalizeQuestionnaire(requestId), {
    method: 'POST',
    body: JSON.stringify(product),
  });

  Xhr.throwOnFailure(response);

  return response.data;
};
