import { Agreement } from './types';

import type { Eula, Locale, Paging, ProfileType as PostProfileType, TaxRecordStatus } from '@epic-ecp/types';

export type { Paging, TaxRecordStatus, Eula, Locale };

export enum BalanceStatus {
  BALANCE_NOT_EXIST = 'BALANCE_NOT_EXIST',
  ACTIVE = 'ACTIVE',
  FROZEN = 'FROZEN',
}

export enum TaxAccountStatus {
  TAX_RECORD_NOT_EXIST = 'TAX_RECORD_NOT_EXIST',
  TAX_PENDING_APPROVAL = 'TAX_PENDING_APPROVAL',
  TAX_NEED_UPDATE = 'TAX_NEED_UPDATE',
  TAX_INVALID = 'TAX_INVALID',
  TAX_VERIFIED = 'TAX_VERIFIED',
}

export enum PayoutAccountStatus {
  ACCOUNT_NOT_EXIST = 'ACCOUNT_NOT_EXIST',
  ACCOUNT_PENDING_TRANSFER_METHOD = 'ACCOUNT_PENDING_TRANSFER_METHOD',
  ACCOUNT_PENDING_ACTIVATION = 'ACCOUNT_PENDING_ACTIVATION',
  ACCOUNT_PENDING_VERIFICATION = 'ACCOUNT_PENDING_VERIFICATION',
  ACCOUNT_UNDER_REVIEW = 'ACCOUNT_UNDER_REVIEW',
  ACCOUNT_ACTIVATED = 'ACCOUNT_ACTIVATED',
  ACCOUNT_DISABLED = 'ACCOUNT_DISABLED',
  ACCOUNT_UNKNOWN_STATUS = 'ACCOUNT_UNKNOWN_STATUS',
  ACCOUNT_LOCKED = 'ACCOUNT_LOCKED',
  ACCOUNT_PENDING_REPROCESS = 'ACCOUNT_PENDING_REPROCESS',
  DRAFT = 'DRAFT',
}

export enum PayoutExperinceModel {
  PAY_PORTAL_PAYOUT_EXPERIENCE = 'PAY_PORTAL_PAYOUT_EXPERIENCE',
  EMBEDDED_PAYOUT_EXPERIENCE = 'EMBEDDED_PAYOUT_EXPERIENCE',
}

export interface PayoutProfileStatus {
  payeeBound: boolean;
  balanceStatus: BalanceStatus | keyof typeof BalanceStatus;
  taxProfileStatus: TaxAccountStatus | keyof typeof TaxAccountStatus;
  actualTaxProfileStatus: TaxAccountStatus | keyof typeof TaxAccountStatus;
  payoutAccountStatus: PayoutAccountStatus | keyof typeof PayoutAccountStatus;
  actualPayoutAccountStatus: PayoutAccountStatus | keyof typeof PayoutAccountStatus;
  taxCountrySanctioned: boolean;
  pendingTransferMethod: boolean;

  taxCountry?: string;
  requirePayoutExperienceModel?: PayoutExperinceModel;
}

export interface PayoutProfileStatusResponse {
  data: PayoutProfileStatus;
  success: boolean;
}

export interface TaxRecordSimple {
  recordIdentityKey: string | boolean;
  ownerAccountId: string;
  name: string; // only available if isIndividual = false
  taxCountry: string;
  id: string;
  isIndividual: boolean;
  email: string;
  taxStatus: string;
}

export interface Payment {
  amount: number;
  withholdingTaxRate?: string;
  notes: string;
  withholdingAmount?: number;
  pmtProviderPmtCreatedOn?: Date;
  paymentId: string;
  paymentHwToken?: string;
  paymentCreateDate: string;
  earnerId: string;
  payeeId: string;
  paymentStatus?: string;
}

export interface PaymentResponse {
  elements: Payment[];
  paging: Partial<Paging>;
}

/** SAC not currently supported */
export type PayoutPortalType = 'ENGAGEMENT' | 'SELLER' | 'DEVELOPER' | 'AFFILIATE' | 'SAC';

export interface PayoutReportDetail {
  adjustedBaseRevenue: number;
  adjustment: number;
  adjustmentUs: number; // added in v2
  basePayoutPrice: number;
  chargeBackAmount: number;
  createdAt: string;
  emailSent: boolean; // added in v2
  esportEventEndTime: string;
  esportEventId: string;
  esportWinningAccepted: boolean;
  gameId: string;
  grossRevenue: number;
  grossRevenueNonUs: number; // added in v2
  grossRevenueUs: number; // added in v2
  id: string;
  identityId: string; // added in v2
  identityType: PayoutPortalType; // added in v2
  importedBy: string;
  importedClientId: string; // added in v2
  locked: boolean; // added in v2
  namespace: string;
  namespaceDisplayName: string;
  netUnitsSold: number;
  netUnitsSoldYearToDate: number; // added in v2
  offerId: string;
  offerTitle: string;
  paymentDate: string; // added in v2
  paymentId: string; // added in v2
  period: string;
  prizeType: string;
  refundAmount: number;
  reportId: string;
  revenueEarnedThisPeriod: number;
  revenueEarnedYearToDate: number;
  revenueShare: number;
  sacCostAmount: number;
  salesAmount: number;
  shareAmount: number;
  totalNumberOfChargeBack: number;
  totalNumberOfPurchase: number;
  totalNumberOfRefund: number;
  withholdingAmount: number;
}

export interface PayoutReportDetailResponse {
  elements: Partial<PayoutReportDetail>[];
  paging: Partial<Paging>;
  paymentId: string;
}

export interface HyperWalletSecureLink {
  expireTime?: string;
  link?: string;
  linkEnable: boolean;
}

export enum PayoutAccountTypes {
  OFFLINE = 'OFFLINE',
  HYPERWALLET = 'HYPERWALLET',
  BOA = 'BOA',
}

export interface PayoutProviderTypeResponse {
  payoutProviderType: PayoutAccountTypes | keyof typeof PayoutAccountTypes;
}

export interface Payee {
  stopReason?: string;
  finalPaymentPayable: boolean;
  payoutProviderType: PayoutAccountTypes | keyof typeof PayoutAccountTypes;
  identityType: PayoutPortalType;
  earnerId: string;
  payeeId: string;
  stopDate: string;
  exitDate: string;
}

export interface InterviewRequest {
  paymentConsentAccepted: boolean;
  hasReadPaymentConsent: boolean;
  profileType: string;
  locale: string;
  eula: Agreement; // just one payment consent here;
  paymentConsentAgreement: Agreement;
  organizationId?: string;
  organizationEmail?: string;
  organizationName?: string;
}

export interface InterviewRequestBody {
  profileType?: string;
  locale: string;
  eula: Agreement; // just one payment consent here;
  organizationId?: string;
  organizationEmail?: string;
  organizationName?: string;
}

export interface TaxProfileInfo {
  hasAccountSetting: boolean;
  taxCountrySanctioned: boolean;
  taxCountry: string;
  email: string;
  taxStatus: string;
  actualTaxStatus: TaxAccountStatus | keyof typeof TaxAccountStatus;
}

export interface PayoutAcceptance {
  amount: number;
  vBucks: number;
}

// Initially it was assumed that this type could be obtained from '@epic-ecp/types' ProfileType,
// but the values in the response are as shown below
export enum ProfileType {
  INDIVIDUAL = 'Individual',
  SOLE_PROPRIETOR = 'SoleProprietor',
  SINGLE_MEMBER_LLC = 'SingleMemberLLC',
  OTHER_ENTITY = 'OtherEntity',
}

// Initially it was assumed that this type could be obtained from '@epic-ecp/types' TaxRecord,
// but since there are differences (in field types) between the type from '@epic-ecp/types' and the real response, I had to make this type
export interface TaxRecord {
  recordModifiedOn: string;
  mailingAddressState: string;
  // recordIdentityKey is string in '@epic-ecp/types' and boolean response
  recordIdentityKey: string | boolean;
  modifiedAt: string;
  guardianPhoneNumber: string;
  organizationEntity: boolean;
  taxStatus: TaxRecordStatus;
  legalName: string;
  taxVerified: boolean;
  createdAt: string;
  mailingAddressLine1: string;
  mailingAddressLine2: string;
  // profileType  in '@epic-ecp/types' and in response differ, in response the same as described above
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  profileType: ProfileType | keyof typeof ProfileType | string;
  taxStatusExpiryDate: string;
  paymentHandler: string;
  tin: string;
  addressLine1: string;
  addressLine2: string;
  recordCreatedOn: string;
  payeeId: string;
  addressCity: string;
  uSPerson: boolean;
  individual: boolean;
  rvn: number;
  firstName: string;
  emailStatus: string;
  affiliateUse: string;
  phoneNumber: string;
  foreignTin: string;
  isUSPerson: boolean;
  doingBusinessAsName: string;
  emailSentCount: number;
  addressPostCode: string;
  errorNote: string;
  guardianEmail: string;
  lastName: string;
  guardianFirstName: string;
  mailingAddressPostCode: string;
  tinType: string;
  mailingAddressDifferent: boolean;
  retakeBan: boolean;
  guardianLastName: string;
  mailingAddressCity: string;
  addressCountryCode: string;
  email: string;
  taxTreatyCountryCode: string;
  lastEmailSentTime: string;
  mailingAddressCountryCode: string;
  dateOfBirth: string;
  addressState: string;
  paymentErrorCode: string;
  isOrganizationEntity: boolean;
  identityId: string;
  // usperson does not exist in in '@epic-ecp/types'
  usperson: boolean;
  // custom additions, not returned from api
  canBoundToPayee?: boolean;
  retakeAvailable?: boolean;
  syncAvailable?: boolean;
}

export interface TaxRecordData {
  payoutStatus: PayoutProfileStatus;
  // This type is based on the actual response, but in pep api it will work like this { ...TaxRecord, canBoundToPayee, retakeAvailable, recordIdentityKey}
  // , but there is a contradiction in TaxRecord recordIdentityKey: string; and in the response recordIdentityKey: boolean;
  record: Partial<TaxRecord>;
}
