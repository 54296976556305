import { useContext } from 'react';

import { PublicRuntimeConfigContext } from '@/contexts/public-runtime-config.provider';

export function usePublicRuntimeConfig() {
  const config = useContext(PublicRuntimeConfigContext);

  if (config === undefined) {
    throw new Error('Runtime config is undefined');
  }

  return config;
}
