// palette values for dark mode
import colors from './colorVariables';

export const darkColors = {
  // OUTDATED COLORS that need to be replaced with the new ones
  shade0: '#ffffff',
  shade1: '#D3D4D7',
  shade2: '#A2A4AA',
  shade3: '#7C7E86',
  shade4: '#5C606A',
  shade5: '#33363D',
  shade6: '#212329',
  shade7: '#101010',
  blue: '#1A90FF',
  blue1: '#689EFD',
  purple: '#B17FFB',
  pink: '#E96DB5',

  // NEW COLORS

  // text token variables
  textPrimary: colors.light100, // Default text color. Use on main page headers and form Input texts.

  textSecondary: colors.gray50, // Use on secondary page headers (News), and inactive (but enabled) navigation texts. For placeholder text and content with a lower emphasis. Use on paragraph texts, project card details, labels, and page numbers.

  textAccent: colors.blue40, // For high-emphasis text links, button texts, and active elements.

  textContrastWhite: colors.light100, // For content placed over any accent color. Use on primary buttons and similar components. The color adapts without switching modes.

  textContrastBlack: colors.dark100, // The color adapts without switching modes.

  textDisabled: colors.gray80, // For all text on disabled components.
  textSuccess: colors.green30, // For content related to a success status.
  textProcessing: colors.orange30, // For content related to a processing status.
  textWarning: colors.orange30, // For content related to a warning status.
  textError: colors.red40, // For content related to a error status.

  // icon token variables
  iconPrimary: colors.gray50, // Default icon color. Use on system-level icons.
  iconSecondary: colors.gray60, // For icons that match content with lower emphasis. Use on inactive Nav Icons.
  iconAccent: colors.blue40, // For content related to an information status.
  iconContrastWhite: colors.light100, // For content placed over any accent color. Use on status chips.
  iconContrastBlack: colors.dark100, // The color adapts without switching modes.
  iconDisabled: colors.gray80, // For all icons on disabled components.
  iconSuccess: colors.green30, // For content related to a success status.
  iconProcessing: colors.orange30, // For content related to a processing status.
  iconWarning: colors.orange30, // For content related to a warning status.
  iconError: colors.red40, // For content related to an error status.
  // Fill token variables
  bgPrimary: colors.gray140, // Used as container base-layer. In modals, will work as input component base.
  bgSecondary: colors.gray130, // Used as container base-layer +1. In modals, will work background.
  bgTertiary: colors.gray120, // Used as container base-layer +2. In modals/setup pages, will work as background for actions.
  statusAccent: colors.blue40, // Use for UI components that aren’t icons. E.g., date picker.
  statusListed: colors.blue40, // Use for publishing status chips.
  statusUnlisted: colors.purple70, // Use for publishing status chips (unlisted).
  statusUnpublished: colors.gray110, // Use for publishing status chips (unpublished).
  statusDisabled: colors.red50, // Use for publishing status chips (disabled).
  statusHidden: colors.brown50, // Use for publishing status chips.
  statusSuccess: colors.green30, // Use for publishing status chips.
  tooltip: colors.gray120, // Elevated grey to use in the different tooltips to guide the user.
  contrastBlack: colors.dark100, // The color adapts without switching modes.
  contrastWhite: colors.light100, // The color adapts without switching modes.
  // Gradient
  button01: colors.blue60, // Used for main Creator Portal CTAs.
  button02: colors.blue80, // Used for main Creator Portal CTAs.
  icon01: colors.blue50, // Used for more prominent Creator Portal navigation icons.
  icon02: colors.blue40, // Used for more prominent Creator Portal navigation icons.
  info01: colors.blue80, // Used in info banners.
  info02: colors.blue30, // Used in info banners.
  success01: colors.green80, // Used in success confirmation snackbars.
  success02: colors.green40, // Used in success confirmation snackbars.
  error01: colors.red50, // Used in error snackbars and error dialogs.
  error02: colors.red50, // Used in error snackbars and error dialogs.
  warning01: colors.orange60, // Used in warning dialogs.
  warning02: colors.orange30, // Warning dialogs.
  bgLinear01: colors.gradientDarkLin01, // Part of the creator portal background asset.
  bgLinear02: colors.gradientDarkLin02, // Part of the creator portal background asset.
  bgLinear03: colors.gradientDarkLin03, // Part of the creator portal background asset.
  bgLinear04: colors.gradientDarkLin04, // Part of the creator portal background asset.
  bgLinear05: colors.gradientDarkLin01, // Part of the creator portal background asset.
  bgRadial01: colors.gradientDarkRad01, // Part of the creator portal background asset.
  bgRadial02: colors.gradientDarkRad02, // Part of the creator portal background asset.
  bgTexture01: colors.gradientDarkTex01, // Part of the creator portal background asset.
  bgTexture02: colors.gradientDarkTex02, // Part of the creator portal background asset.
  // Border token variables. Used as border/stroke/ divider colour. Publish them to use in design projects!
  borderDefault: colors.gray80, // Base divider.
  borderHover: colors.transparentLight010, // Border hover state.
  borderPress: colors.transparentDark040, // Border press state.
  borderDisabled: colors.transparentDark040, // Border disabled state for components.
  borderActive: colors.blue40, // Border active/focus state for components.
  // Transparent token variables. Opacity primitives applied into components to help interaction. Publish them to use in design projects!
  hover: colors.transparentLight010, // Component hover state.
  press: colors.transparentDark040, // Component press state.
  disabled: colors.transparentGray80_060, // Component disabled state.
  contrastMuted: colors.transparentGray150_020, // ?
  coreShadow: colors.transparentDark000, // Shadow in cards and menus.
  castShadow: colors.transparentDark000, // Shadow in cards and menus.
  modalOverlay: colors.transparentDark060, // Shadow in modals and menus.
  // Analytics token variables. Exclusive colours for data vis. Publish them to use in design projects!
  analyticsBlue: colors.blue20, // Use it as base colour in charts.
  analyticsPurple: colors.purple30, // Use it as base colour in charts.
  analyticsPink: colors.pink40, // Use it as base colour in charts.
};
