export interface DescriptorResponse {
  descriptorId: number;
  descriptorText: string;
  localizedDescriptorText: string;
  descriptorShortText: string;
  ratingBoardId: number;
  ratingBoardShortText: string;
  categoryId: number;
  categoryName: string;
  rank: number;
}

export interface AgeRatingResponse {
  ageRatingId: number;
  ageRatingText: string;
  localizedAgeRatingText: string;
  ratingBoardId: number;
  ratingBoardShortText: string;
  ageRatingShortText: string;
  rank: number;
  severityLevel: number;
  notApplicable: boolean;
  ratingRefused: boolean;
  ageControl?: number | null;
  ratingImage?: string | null;
  rectangularRatingImage?: string | null;
}

export interface RatingBoardResponse {
  ratingBoardId: number;
  ratingBoardShortText: string;
  ratingBoardText: string;
  ratingBoardLocale: string;
  isIARC: boolean;
  ageRatings: AgeRatingResponse[];
  descriptors: DescriptorResponse[];
}

export interface IarcCertCreateResponse {
  projectId: string;
  privateBuildCode: string;
  teamId: string;
}

export interface IarcQuestionnaireRequest {
  projectId: string;
  developerEmail: string;
  publicDeveloperEmail?: string;
  langCode: string;
}

export interface QuestionnaireUrlRequest {
  productId: string;
  developerEmail: string;
  publicDeveloperEmail: string;
  productCategory: 'PRODUCT' | 'ITEM';
}

export interface Product {
  productId: string;
  productCategory: 'PRODUCT' | 'ITEM';
  productName: string;
  developerId: string;
  developerName: string;
  developerEmail: string;
  parentProductId?: string;
}

export interface CertCreateRequest {
  requestId: string;
  product: Product;
  isLegacy?: boolean;
}

export enum ResponseResponseCodeResult {
  Success = 'Success',
}

export interface ResponseResult {
  responseCode: string | ResponseResponseCodeResult;
  errorId: string | null;
  messageList: string[];
}
export interface GetCertResponse {
  cert: CertDetail;
  Result: ResponseResult;
}
export interface CertDetail {
  certId: string;
  productType: number;
  ratingList: RatingDetail[];
}

export interface RatingDetail {
  ratingBoardId: number;
  ratingBoardShortText: string;
  ratingBoardText: string;
  ageRatingId: number;
  ageRatingText: string;
  ageControl: number | null;
  descriptorIds: number[];
  interactiveElementIds: number[];
  descriptorTexts: string[];
  interactiveElementTexts: string[];
  fileDescriptions: FileDescription[];
  trad: boolean;
}

export interface FileDescription {
  fileId: number;
  platformDetails: PlatformDetail[];
}

export interface PlatformDetail {
  platformId: number;
  platformText: string;
}

export interface QuestionnaireResponse {
  requestId: string;
  productId?: string;
  productCategory: string;
  developerEmail?: string;
  locale: string;
  questionnaireUrl: string;
  certId?: string | null;
  startingDate: Date;
}

export interface LoadCertResult {
  productName: string;
  developerEmail: string;
  ratingLogicVersion?: string;
  dateCreated?: Date | string;
  certDetail: CertDetail;
  publicDeveloperEmail?: string | null;
}

export interface LoadCertResponse {
  matchFound?: boolean;
  storeVersionNotSupported?: boolean;
  loadResult: LoadCertResult;
}

export interface LoadCertProductsResponse {
  certId: string;
  productIds: string[];
}

export interface CertEmailUpdateRequest {
  certId: string;
  developerEmail: string;
}

export interface CertEmailUpdate {
  updateList: CertEmailUpdateRequest[];
}

export interface CertEmailUpdateResult {
  certId: string;
  result: ResponseResult;
}

export interface UpdateCertEmailResponse {
  resultList: CertEmailUpdateResult[];
}

export interface ProductResponse {
  requestId: string;
  productId: string;
  productCategory: string;
  developerEmail: string;
  publicDeveloperEmail?: string | null;
}

export interface InteractiveElementResponse {
  interactiveElementId: number;
  interactiveElementShortText: string;
  interactiveElementText: string;
}

export enum IarcMessageSubject {
  PRODUCT_RATING_CHANGE = 'PRODUCT_RATING_CHANGE',
}

export interface IarcRatingChangeMessage {
  correlationId: string;
  certId: string;
  productId: string;
  productCategory: string;
  oldCertDetail: CertDetail;
  newCertDetail: CertDetail;
  store: string;
}

export interface ProductPatch {
  certId: string;
  productId: string;
  changeSummaries: ProductSummary[];
  updateDate: string;
}
export interface UpdateProductResponse {
  resultList: {
    certId: string;
    productId: string;
    result: ResponseResult;
  }[];
}

interface LocalizedData {
  languageId: string;
  title: string;
  regionalProductUrl?: string;
}

interface Platform {
  name: string;
  minOs: string;
}

export interface GenreList {
  languageId: string;
  genres: string[];
}

export interface PatchRequest {
  updateList: ProductPatch[];
}

export interface PatchResponse {
  productUpdateId: number;
  certId: string;
  productId: string;
  result: ResponseResult;
}

export interface ProductSummary {
  summary: string;
  languageId: string;
}

export interface UpdateRequest {
  certId: string;
  productId: string;
  countryCodes: string[];
  productName: string;
  developerEmail: string;
  inAppPurchases: boolean;
  developerId: string;
  developerName: string;
  publicDeveloperEmail: string;
  localizedData: LocalizedData[];
  platforms: Platform[];
  genres: GenreList[];
  productSummaries: ProductSummary[];
  productUrl?: string;
  developerPhone?: string;
  futureReleaseDate?: string; // must be a stringify date
  parentProductId?: string;
}

export interface CertificateEmails {
  developerEmail: string;
  publicDeveloperEmail?: string | null;
}

export interface CertificateEmailsResponse {
  certId: string;
  developerEmail: string[];
  publicDeveloperEmail: string[];
}

export type RatingBoardShortText =
  | 'ACB'
  | 'CERO'
  | 'CSRR'
  | 'ClassInd'
  | 'ESRB'
  | 'FPB'
  | 'GRAC'
  | 'Generic'
  | 'OFLC'
  | 'PEGI'
  | 'Russia'
  | 'USK'
  | 'GCAM'
  | 'MRO'
  | 'EPIC SUGGESTED RATING';
