import { FC, memo } from 'react';
import { Alert2Icon, AlertIcon, CloseIcon, InfoIcon, StatusApprovedIcon } from '@epic-ui/icons';
import { Alert, AlertColor, AlertTitle, IconButton } from '@mui/material';
import { clsx } from 'clsx';

import Button from '@/components/ui/Button';

import { BootstrapTooltip } from '../tooltip.component';
import useStyles from './info-box.styles';

interface InfoBoxProps {
  children: React.ReactNode;
  severity: AlertColor;
  onClose?: () => void;
  onSubmit?: () => void;
  icon?: any;
  submitButtonText?: string;
  submitButtonTooltip?: string;
  className?: string;
  isSubmitDisabled?: boolean;
  isLoading?: boolean;
}

const InfoBox: FC<InfoBoxProps> = ({
  severity,
  onClose,
  onSubmit,
  submitButtonText,
  submitButtonTooltip,
  className,
  icon,
  isSubmitDisabled,
  isLoading = false,
  children,
}): JSX.Element => {
  const classes = useStyles();

  const hasSubmitButton = !!(submitButtonText && onSubmit);
  const hasCloseButton = !!onClose;

  const icons = {
    success: <StatusApprovedIcon className={classes.iconSuccess} />,
    info: <InfoIcon className={classes.iconInfo} />,
    warning: <Alert2Icon />,
    error: <AlertIcon />,
  };

  const actions: JSX.Element[] = [];

  if (hasSubmitButton) {
    const submitBtn = (
      <BootstrapTooltip key="submit" placement="bottom" title={submitButtonTooltip}>
        <span>
          <Button id="submit_alert_btn" onClick={onSubmit} disabled={isSubmitDisabled} loading={isLoading}>
            {submitButtonText}
          </Button>
        </span>
      </BootstrapTooltip>
    );
    actions.push(submitBtn);
  }

  if (hasCloseButton) {
    const closeIcon = (
      <IconButton key="close" aria-label="Close" size="small" onClick={onClose} data-testid="closeInfoboxButton">
        <CloseIcon fontSize="small" />
      </IconButton>
    );

    actions.push(closeIcon);
  }

  return (
    <Alert
      action={actions.length ? actions : undefined}
      severity={severity}
      icon={icon || icons[severity]}
      className={clsx(classes.alert, className, { [classes.alertWithMobileStyles]: hasCloseButton })}
    >
      <AlertTitle>{children}</AlertTitle>
    </Alert>
  );
};

export default memo(InfoBox);
