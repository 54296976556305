export enum AccountPolicy {
  EGPI_COLLECTION_ALLOWED = 'g-01ffc173dcc1',
  DATA_SHARING_POSSIBLY_PERMITTED = 'g-4d92b458',
  ASSUMED_OVER_18 = 'g-34ebd542',
  ASSUMED_OVER_AODC = 'g-1e96983324b9',
  KNOWN_OVER_13 = 'g-a81a33a3b9a7',
  KNOWN_OVER_18 = 'g-t79q16m0g9a7',
}

export interface ResourcePermission {
  readonly resource: string;
  readonly action: number;
}

export interface AuthSession {
  /** clientId used when this session was authenticated. */
  readonly clientId: string;

  /** clientService (name) used when this session was authenticated. */
  readonly clientService?: string;

  /** account which this session represents (might be impersonated). */
  readonly accountId: string;

  /** account which was actually authenticated. */
  readonly authAccountId: string;

  /** account's displayName at the time it was authenticated. */
  readonly displayName?: string;

  // Ideally would be ReadOnlyArray<ResourcePermission>,
  // but tsoa 3.6 has a bug in it around that.
  readonly claims: Array<ResourcePermission>;

  readonly policies: AccountPolicy[];
}
