import { SyntheticEvent } from 'react';

import { makeNavPath } from '@creator-portal/common/util/makeNavPath';
import { canAccess, ClaimsToken } from '@creator-portal/common/auth';
import { PERMISSION } from '@creator-portal/common/permissions/constants';
import { PERSONAL_TEAM_ID } from '@creator-portal/common/publishing/constants';
import {
  GetMediaProjectsResult,
  MarketingOptIn,
  PagedResults,
  ProjectDetailResult,
  ProjectSearchResult,
  VerseRuntimeErrorAmountResponse,
  VerseRuntimeErrorCrashGroupResponse,
} from '@creator-portal/common/types';

import { PROJECTS_URL } from '@/config/media/constants';
import { getAssignedProjectsUrl, getAssignProjectsUrl, getRemoveProjectsUrl } from '@/config/media/functionalConstants';

import { getMarketingFields } from '@/components/publishing/deploy-release-flow/stepper-actions';

import * as Xhr from '@/util/xhr';

const xhr = Xhr.getInstance();
interface RedirectObj {
  redirect: {
    permanent: boolean;
    destination: string;
  };
}

export namespace ProjectsUrl {
  export const getVerseRuntimeErrors = (projectId: string, teamId: string) =>
    `${PROJECTS_URL}/${encodeURIComponent(projectId)}/${encodeURIComponent(teamId)}/verse/runtime-errors/crash-groups` as const;
  export const getVerseRuntimeErrorsAmount = (projectId: string) =>
    `${PROJECTS_URL}/${encodeURIComponent(projectId)}/verse/runtime-errors/crash-groups-amount` as const;
}

export const getAllProjects = async (): Promise<ProjectSearchResult[]> => {
  const projectsResponse = await xhr.fetchJson<PagedResults<ProjectSearchResult>>(PROJECTS_URL, { method: 'GET' });
  Xhr.throwOnFailure(projectsResponse);

  return projectsResponse.data.results;
};

export const getAssignedProjects = async (mediaId: string): Promise<GetMediaProjectsResult> => {
  const url = getAssignedProjectsUrl(mediaId);

  const mediaResponse = await xhr.fetchJson<GetMediaProjectsResult>(url, { method: 'GET' });
  Xhr.throwOnFailure(mediaResponse);

  return mediaResponse.data;
};

export const assignProjects = async (mediaId: string, id: string[]): Promise<null> => {
  const lastAddedId: string = id[id.length - 1];
  const url = getAssignProjectsUrl(mediaId, lastAddedId);

  const mediaResponse = await xhr.fetchJson(url, { method: 'POST' });
  Xhr.throwOnFailure(mediaResponse);

  return null;
};

export const removeProjects = async (mediaId: string, id: string): Promise<null> => {
  const url = getRemoveProjectsUrl(mediaId, id);

  const mediaResponse = await xhr.fetchJson(url, { method: 'DELETE' });
  Xhr.throwOnFailure(mediaResponse);

  return null;
};

export const isProjectLinkDisabled = (project?: ProjectSearchResult | ProjectDetailResult): boolean => {
  const linkDisabled = project?.linkData?.isDisabled;
  return !!linkDisabled;
};

export const isProjectDisabled = (project?: ProjectSearchResult | ProjectDetailResult): boolean => {
  const killSwitchesSearch = (project as ProjectSearchResult)?.info?.sysMeta?.kill_switches;
  const killSwitchesProjectDetails = (project as ProjectDetailResult)?.sysMeta?.kill_switches;
  const activeKillSwitches = killSwitchesSearch || killSwitchesProjectDetails;
  const killSwitch = !!activeKillSwitches && activeKillSwitches.includes('RestrictAccess');

  return !!killSwitch;
};

export const handleStopEvent = (e: SyntheticEvent): void => {
  e.stopPropagation();
  e.preventDefault();

  return;
};

export const getProjectRedirect = (originalPath?: string) => {
  return {
    redirect: {
      permanent: false,
      destination: makeNavPath(`/${PERSONAL_TEAM_ID}/projects`, originalPath),
    },
  };
};

export const prepareRedirectForLockedProject = (
  projectResponse: Xhr.XhrResponse<ProjectDetailResult>,
  originalPath?: string,
): null | RedirectObj => {
  const { success, data } = projectResponse;

  const isProjectDisabledByContentService = !success && data.errorCode?.includes('project_locked');

  return isProjectDisabledByContentService ? getProjectRedirect(originalPath) : null;
};

export const isProjectMonetizationEnabled = (session: ClaimsToken | undefined): boolean =>
  canAccess(session, PERMISSION.PROJECT_MONETIZATION);

export const isProjectAnalyticsEnabled = (session: ClaimsToken | undefined): boolean => canAccess(session, PERMISSION.PROJECT_ANALYTICS);
export const isProjectTecnicalEnabled = (session: ClaimsToken | undefined): boolean => canAccess(session, PERMISSION.VERSE_RUNTIME_ERRORS);

export const isProjectSubNavEnabled = (session: ClaimsToken | undefined): boolean => {
  const isMediaPageAvailable = canAccess(session, PERMISSION.PROJECT_MEDIA);
  const isDashboardPageAvailable = canAccess(session, PERMISSION.PROJECT_DASHBOARD);
  const isMOTDAvailable = canAccess(session, PERMISSION.PROJECT_MESSAGE_OF_THE_DAY);
  const isMonetizationPageAvailable = isProjectMonetizationEnabled(session);

  return isMediaPageAvailable || isDashboardPageAvailable || isMOTDAvailable || isMonetizationPageAvailable;
};

export const isSessionLengthEnabled = (session: ClaimsToken | undefined): boolean => canAccess(session, PERMISSION.PROJECT_ANALYTICS_SL);
export const isClicksEnabled = (session: ClaimsToken | undefined): boolean => canAccess(session, PERMISSION.PROJECT_ANALYTICS_CL);
export const isAnalyticsTabsUIEnabled = (session: ClaimsToken | undefined): boolean => canAccess(session, PERMISSION.ANALYTICS_TABS_UI);
export const isAnalyticsPlayersTabEnabled = (session: ClaimsToken | undefined): boolean =>
  canAccess(session, PERMISSION.PROJECT_ANALYTICS_APL);
export const isAnalyticsPlaysChartsEnabled = (session: ClaimsToken | undefined): boolean =>
  canAccess(session, PERMISSION.PROJECT_ANALYTICS_PL);
export const isAnalyticsSatisfactionTabEnabled = (session: ClaimsToken | undefined): boolean =>
  canAccess(session, PERMISSION.PROJECT_ANALYTICS_ST);

export const updateMarketingSubscription = async (projectId: string, isOptIn: boolean): Promise<void> => {
  const url = `/api/vk/v1/projects/${encodeURIComponent(projectId)}/marketing`;
  const dto = getMarketingFields(isOptIn);

  const rsp = await xhr.fetchJson<MarketingOptIn>(url, { method: 'PUT', body: JSON.stringify(dto) });
  Xhr.throwOnFailure(rsp);
};

export const getVerseRuntimeErrors = async (projectId: string, teamId: string): Promise<VerseRuntimeErrorCrashGroupResponse> => {
  const url = ProjectsUrl.getVerseRuntimeErrors(projectId, teamId);

  const runtimeErrorsResponse = await xhr.fetchJson<VerseRuntimeErrorCrashGroupResponse>(url);
  Xhr.throwOnFailure(runtimeErrorsResponse);

  return runtimeErrorsResponse.data;
};

export const getVerseRuntimeErrorsAmount = async (projectId: string): Promise<VerseRuntimeErrorAmountResponse> => {
  const url = ProjectsUrl.getVerseRuntimeErrorsAmount(projectId);

  const runtimeErrorsResponse = await xhr.fetchJson<VerseRuntimeErrorAmountResponse>(url);
  Xhr.throwOnFailure(runtimeErrorsResponse);

  return runtimeErrorsResponse.data;
};
