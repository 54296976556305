import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import type { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorTitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    errorDetailsIcon: {
      color: theme.palette.commonRed,
    },
  }),
);

export default useStyles;
