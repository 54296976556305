import { createContext, useContext } from 'react';

import { ProjectDetailResult } from '@creator-portal/common/types';

export function useProject(): ProjectProps {
  return useContext(ProjectContext);
}

export interface ProjectProps {
  project: ProjectDetailResult;
}

export const ProjectContext = createContext<ProjectProps>({} as ProjectProps);
